import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { SurveyActions } from '../_actions';
import {
    Button,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    Text,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Divider,
    Slide,      // For Transition (sliding in full page modal dialog)
    Chip,
    FormControlLabel,
    Switch,
} from '@mui/material';
// formik stuff
import { Formik, Form, useField, useFormikContext } from 'formik';
// material icons!
import {
    Close as CloseIcon,
    Edit as EditIcon,
    Done as DoneIcon,
    Clear as ClearIcon,
    Height as HeightIcon,
    DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
/*import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Done';
*/
// slide in full screen dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

import {
    HDASS, HDArticle, HDSteelType, HDDept, HDBay, HDSeismic, HDBayAisle, hdGetMyQuestions, hdbtFindBayType, HDBayType,
} from '../_appdata';
// for colors!
import { hsl2rgb, rgb2hex } from '../_helpers';

import { KForm } from '../AdminSurveySettingPage/KForm';

import Image from 'mui-image';
import { ProtectedImage } from '../_components/ProtectedImage';

import { availableSurveySettings } from '../_helpers/AdminSurveySettingData';

import { DraggableList, DraggableListItem } from '../_components';


// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';
// APPDATA going away!! AdminSurveySettings coming to stay!
import { assActions } from '../_actions';

const randColor = () => {
    const h = Math.random() * 360,
        s = Math.random() * 0.5 + 0.5, l = Math.random() * 0.25 + 0.3;

    const [r, g, b] = hsl2rgb(h, s, l);
    const accentColor = rgb2hex(r, g, b);

    return accentColor;
}

const defaultSection = [
    {
        SID: 'col',
        sLabel: 'Column',
        sText: "\u25CF Is the cantilever column undamaged and straight? (Neither bent nor twisted).\n" +
            "\u25CF Is the column vertically plumb? (No more than 5/8\" lean in 12' of height, or 3/4\" lean in 16' of height).",
        tButton: [
            { id: 'SPIN-COLUMN', label: 'Spin the Column' },
            { id: 'COLUMN-TWISTED', label: 'Column is Twisted' },
            { id: 'ARMS-SPRUNG', label: 'Sprung Arm(s)' },
            { id: 'NOT-PLUMB', label: 'Not Plumb' },
        ],
        dropDown: [
            { id: 'CAN600000370', },
            { id: 'CAN600000379', },
            { id: 'CAN600000381', },
            { id: 'CAN600000364', },
            { id: 'CAN600000365', },
            { id: 'CAN600000366', },
        ],
    }, {
        SID: 'arm',
        sLabel: 'Arms',
        sText: "\u25CF Are cantilever arms straight? (Neither damaged nor bent / twisted? Variance of 2\" requires replacement. Measure vertically and horizontally to determine alignment).\n" +
            "\u25CF Are cross braces and horizontal braces in place and connected?",
        tButton: [
            { id: 'ARMS-DAMAGE', label: 'Arm(s) Damaged' },
            { id: 'BRACES-DAMAGE', label: 'Brace(s) Damaged' },
            //{id: '', label: ''},
        ],
        dropDown: [
            { id: 'CAN600000355', },
            { id: 'CAN600000363', },
            { id: 'CAN600000375', },
            { id: 'CAN600000371a', },
            { id: 'CAN600000371b', },
            { id: 'CAN600000371c', },
            { id: 'CAN600000383a', },
            { id: 'CAN600000383b', },
            { id: 'CAN600000383c', },
        ],
    }, {
        SID: 'bolt',
        sLabel: 'Bolts',
        sText: "\u25CF Are all bolts in bases, arms, columns and bracing installed and sufficiently torqued? (Use visual inspection and random bolt test. 3/4\" x 2\" grade 5 bolts torqued to 200 ft/lbs.)",
        tButton: [
            { id: 'BOLTS-BASE', label: 'Base Bolts' },
            { id: 'BOLTS-ARM', label: 'Arm Bolts' },
            { id: 'BOLTS-BRACE', label: 'Brace Bolts' },
            { id: 'BOLTS-TORQUE', label: 'Retorque Bolts' },
        ],
        dropDown: [
            { id: 'CAN600000359', },
            { id: 'CAN600000360', },
        ],
    }, {
        SID: 'base',
        sLabel: 'Base',
        sText: "\u25CF Are cantilever base legs straight and undamaged? Up to 3 dents are allowed in the cantilever base legs.\n" +
            "\u25CF If the base leg has been sheared/split by a forklift, then it must be replaced.\n" +
            "\u25CF For cantilever base leg applications, the pole (rod / chain) must be able to be inserted in to the hole & pocket of the base leg. If not, the base leg needs to be replaced.",
        tButton: [
            { id: 'BASE-SHEAR', label: 'Sheared' },
            { id: 'ROD-P-DAMAGE', label: 'Rod Pocket Damaged' },
            //{ id: '', label: '' },
        ],
        dropDown: [
            { id: 'CAN600000382', },
        ],
    }, {
        SID: 'anc',
        sLabel: 'Anchors',
        sText: "\u25CF Are cantilever columns & base legs anchored correctly to the floor, through holes punched in the components? (2 anchors, 1 in the upright and 1 in the base leg)?",
        tButton: [
            { id: 'ANCHOR-COLUMN', label: 'Column needs Anchor / Retorque' },
            { id: 'ANCHOR-BASE', label: 'Base Needs Anchor / Retorque' },
            { id: 'CONCRETE-DAMAGE', label: 'Concrete Repair and Reanchor' },
        ],
        dropDown: [
            { id: 'CAN600009809', },
        ],
    }, {
        SID: 'boot',
        sLabel: 'Boots',
        sText: "\u25CF Safety rod and chains installed? If rod and chain is more than 2\" out of plumb, either rod or boot needs replacement.",
        tButton: [
            { id: 'ROD-CHAIN-FIX', label: 'Reattach rod and chain' },
        ],
        dropDown: [
            { id: 'CAN600000358', },
            { id: 'CAN600000362', },
            { id: 'CAN600000369', },
            { id: 'CAN600000377', },
        ],
    },
];

// returns an object to add on to the section list survey.Data.Section ...
const defaultEmptySection = (SID, sLabel, sText) => {
    return {
        SID: SID,
        sLabel: sLabel,
        sText: sText,
        tButton: [],
        // dropDownYN: [],
        dropDown: [],
    };
};

const bgColor = (index) => {
    const bgColors = ["#ccc", "#ddd", "#eee", "#ddd"];
    const newIndex = Number(index) % bgColors.length;
    return bgColors[index % bgColors.length];
};

function SurveyBuilderHDC(props) {
    // redux things ..!!
    const auth = useSelector(state => state.authentication);
    const Surveys = useSelector(state => state.Surveys);
    const asurvset = useSelector(state => state.adminSurveySettings);
    const dispatch = useDispatch();

    // when we pop up an editor for the survey or question, 'SUR', 'SQU', etc.
    const [viewSetting, setViewSetting] = useState('');
    // the object containing the data 
    //const mySurveySetting = availableSurveySettings.find(ass => ass.sClass === viewSetting);
    // are we editing an existing survey / question? Or creating a new one?
    const [mySelected, setMySelected] = useState(null);
    const [initialV, setInitialV] = useState(false);    // initialV = we are supplying values to edit?
    // mySurveySetting = the data object for the adminSurveySetting we are editing when initialV=true
    const [mySurveySetting, setMySurveySetting] = useState(null);
    // Dialog for editing a qustion (or survey?)
    const [editorOpen, setEditorOpen] = React.useState(false);
    const handleEditorClose = (refreshMe = false) => {
        setEditorOpen(false);
        // auto refresh! when we close the editor, reload stuff!
        if (refreshMe) { // wait 300ms to prevent screwing up the form.
            setTimeout(() => { dispatch(assActions.getAll()); }, 300);
        }
    };

    function openTheEditor(assType, initialValues = null, options = null) {
        // find mySurveySetting
        const mySS = availableSurveySettings.find(ass => ass.sClass === assType);
        // proceed only if surveySetting is valid!
        if (mySS) {
            setViewSetting(assType); // i.e. 'SUR' or 'SQU' ...
            setMySurveySetting(mySS); // editor fields 
            if (initialValues && typeof (initialValues) === 'object') {
                // initialV boolean = ARE we using a set on initialValues for the editor popup? if false, adminSurveySettings defines defaults
                setInitialV(true);
                // mySelected = the initialValues we populate our form with
                setMySelected({ ...initialValues });
                console.log(`openTheEditor: opening "${assType}", with initialValues (edit).`);
                console.log(initialValues);
            } else {
                setInitialV(false);
                setMySelected(null);
                console.warn(`openTheEditor: opening "${assType}", no initialValues (add new).`);
            }
            // finally, open the editor with our initial values or blank default values.
            setEditorOpen(true);
        } else {
            console.warn(`openTheEditor: failed to find SSType "${assType}"!`)
        }
    }

    const [mySurvey, setMySurvey] = useState({
        SID: props.survey.SID,
        SType: props.survey.SType,
        Name: props.survey.Name,
        Version: props.survey.Version,
        Comment: props.survey.Comment,
        Data: (props?.survey?.Data?.Section?.length > 0) ? props.survey.Data : { Section: defaultSection },
    });

    const handleChangeSurvey = (survey) => {
        setMySurvey((oldSurvey) => ({ ...oldSurvey, ...survey })); // useState function to update state
    };

    // updates current survey with the provided section object
    const handleChangeSection = (oldSID, newSection) => {
        setMySurvey((oldSurvey) => ({
            ...oldSurvey,
            Data: {
                Section:
                    [...oldSurvey.Data.Section.map(ss => oldSID == ss.SID ? newSection : ss)]
            },
        }));
    };

    // begin: dragging sections to rearrange
    const sectionList = mySurvey.Data?.Section?.map(ss => ({ id: ss.SID, label: `${ss.sLabel} (SID: ${ss.SID})` })) || [];
    const handleSectionArrange = (newSec) => {
        setMySurvey((oldSurvey) => ({
            ...oldSurvey,
            Data: {
                Section:
                    [...newSec.map(ss => oldSurvey.Data.Section.find(os => os.SID == ss.id))]
            },
        }));
    };
    // end: dragging sections to rearrange

    const handleAddSection = (myItem) => {
        myItem.SID = myItem.SID.trim();
        myItem.sLabel = myItem.sLabel.trim();
        console.log("AddSection: ", myItem);
        // ensure SID and sLabel not blank!
        if (myItem.SID.length < 1) {
            alert(`Section ID cannot be empty!`);
            return;
        }
        if (myItem.sLabel.length < 1) {
            alert(`Section label cannot be empty!`);
            return;
        }
        // ensure the SID we are adding is not already in use!!
        const exists = mySurvey.Data.Section.find(s => s.SID == myItem.SID);
        if (exists) {
            alert(`Cannot add section, id: "${myItem.SID}" already exists!`);
            return;
        } else {
            // update mySurvey state wiht our new section added on!
            setMySurvey((oldSurvey) => (
                {
                    ...oldSurvey,
                    Data: { Section: [...oldSurvey.Data.Section, defaultEmptySection(myItem.SID, myItem.sLabel, "")] },
                })); // useState function to update state

            //mySurvey.Data.Section.
        }
    };

    // debug testing bgcolor
    /*
    let str = '';
    for (let i = 0; i < 10; i++) { str += bgColor(i) + ","; }
    console.log(str);
     */

    // editing a section's SID / sLabel?
    const [editingSectionID, setEditingSectionID] = useState(null);
    const handleShowSIDEdit = (editSID) => { setEditingSectionID(editSID); };
    const handleHideSIDEdit = () => { setEditingSectionID(null); };

    // prevent SIDS being changed to an already existing SID
    const mySIDS = mySurvey?.Data?.Section?.map(ss => (ss.SID));
    // returns TRUE is we are trying to change a SID to something that already exists elsewhere in the survey
    const checkSIDCollision = (SIDS, index, newSID) => {
        console.log("checkSIDCollision:", SIDS, index, newSID);
        const exists = SIDS.find((ss, i) => (index == i ? false : (ss == newSID)));
        console.log((exists == newSID))
        return (exists == newSID);
    }

    const sSubClass = mySurveySetting?.sSubClassProp; // the property in payload that maps to sSubClass

    const editorTitle = initialV ? `Editing ${mySurveySetting?.label}: ${mySelected?.Name} (id: ${mySelected?.id || 0})` : `Adding New: ${mySurveySetting?.label}`;
    const EditorAppBar = (props) => {
        let myPos = 'sticky', mySx = undefined;
        if (props?.bottom) { myPos = 'fixed', mySx = { top: 'auto', bottom: 0 }; }
        return (<>
            {props?.bottom && <div style={{ height: 90 }} />}
            <AppBar position={myPos} sx={mySx} /*sx={{ position: 'relative' }}*/ >
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {props?.title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => { handleEditorClose(); }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>)
    };

    // begin: add or edit a thing dialog
    const [aeDialog, setAEDialog] = useState({ show: false, });
    const handleOpenAEDialog = (options) => {
        if (options.itemType == 'tButton') {

            setAEDialog((current) => ({
                ...current, ...options, show: true,
                fields: ['id', 'label'],
                initialValues: { id: options?.itemID || '', label: options?.itemLabel || '' },
                onSubmit: (values) => { },
                onClose: handleCloseAEDialog,
            }));
        } else {
            console.log(`handleOpenAEDialog: ERR! invalid itemType (must be tButton). `, options);
        }
    };
    const handleCloseAEDialog = () => { setAEDialog((current) => ({ ...current, show: false, })); };
    // end: add or edit a thing dialog

    // call this to pop up an editor to edit or add an item (dropDown / tButton)
    // pass an object with props: sectionID, itemType (dropDown / tButton), itemID, itemLabel (to display to user)
    const dialogEditItem = (param) => {
        // sectionLabel will be obtained via SID.
        const mySLabel = mySurvey.Data.Section.find(ss => ss.SID == param.SID);
        if (mySLabel === undefined) {
            console.log(`ERR! dialogEditItem failed to open editor dialog for invalid SID "${param?.SID}", given parameters:`, param);
            return -1;
        }
        // if isEditing is falsy, we are adding a new thing
        const isEditing = mySurvey.Data.Section.find(ss => ss.SID == param.SID) ? true : false;
        //console.log('Add or edit an item: ', isEditing ? 'Edit: ' : 'Add: ', param);
        handleOpenAEDialog({ ...param, isEditing });
    }

    // Save / undo / redo buttons (put in a flexbox)
    const SaveButton = (<>
        <Button
            variant="outlined"  // contained
            color={'success'}
            disabled={asurvset?.isLoading}
            onClick={() => {
                console.log("Save survey clicked.");
                //dispatch(assActions.getAll(auth?.user));
                //dispatch(SurveyActions.downMissionPhotos(missionId, user));
                //setLoadedPhotos(1);
                openTheEditor('SUR', { ...mySurvey });
            }}
        >Save Survey</Button>
    </>
    );

    return (
        <div>
            <Box>
                <h3>
                    Home Depot Cantilever Run Survey

                    {/*
                    <Button
                        variant="contained"
                        color="primary"
                        //disabled={isSubmit && surveySetting.isAdding}
                        onClick={() => {
                            setMySurvey((prev) => ({ ...prev, Data: { Section: [...defaultSection] } }));
                        }}
                    >
                        Reset
                    </Button>
                     */}

                </h3>
            </Box>

            <Box
                sx={{ margin: '2px', py: '2px', px: 1, border: 2, borderColor: '#0a0', color: '#000', borderRadius: 1, }}
            >
                <Box component="div" sx={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1, py: 0, my: 1, }}>
                    Section Arrangement
                </Box>
                <Box component="div" sx={{ fontSize: 10, lineHeight: 1, my: 1, }}>
                    Drag and drop sections up and down to rearrange the survey!
                </Box>
                <DraggableList
                    listId={"sections"}
                    items={sectionList}
                    itemKey={(p) => (`${p.listId}_${p.item.id}`)}
                    itemComponent={DraggableListItem}
                    onChange={(newItem) => handleSectionArrange(newItem)}
                    allowDelete={true}
                />

                <AddSectionForm
                    onSubmit={handleAddSection}
                />

            </Box>

            <Box sx={{ margin: '2px', py: '2px', height: 16, }} />

            <Box sx={{ my: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >{SaveButton}</Box>

            <Box
                sx={{ margin: '2px', py: '2px', px: 1, border: 2, borderColor: '#00a', color: '#000', borderRadius: 1, }}
            >
                <Box component="div" sx={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1, py: 0, my: 1, }}>
                    Survey (This part repeats for every column)
                </Box>
                {mySurvey?.Data?.Section?.map((sec, index) => (
                    <HDCSection
                        //key={`hdcs_${sec.SID}_${index}`}
                        key={`hdcs_${sec.SID}`}  // do NOT put index in id or components will mount / unmount / control focus will break!
                        index={index}
                        section={sec}
                        onEditSID={() => { handleShowSIDEdit(sec.SID) }}
                        onCancel={handleHideSIDEdit}
                        onCheck={(newSID) => { return checkSIDCollision(mySIDS, index, newSID); }}
                        onChange={handleChangeSection}
                        onEditItem={dialogEditItem}
                        isEditingID={editingSectionID == sec.SID}
                    />
                ))}
            </Box>

            <Box sx={{ my: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >{SaveButton}</Box>

            {/*
                <pre>{JSON.stringify(mySurvey, null, ' ')}</pre>

                    <pre>{JSON.stringify(props, null, ' ')}</pre>
                <pre>{JSON.stringify(mySurvey, null, ' ')}</pre>
                {(mySurvey?.Data?.Section ? 'true' : 'false')}
                {(mySurvey?.Data?.Section?.length)}
                */}

            {/* pop up dialog to add / edit / delete a thing (i.e. toggle button) 
                required options: <bool> show, <string> title, <string> content, <array> button
                button <object> props: <string> label, autoFocus, <func> onClick,
            */}
            <AddOrEditDialog {...aeDialog} />

            {/* fullscreen popup to add / insert a new question (or survey?) */}
            <Dialog
                fullScreen
                open={editorOpen}
                onClose={handleEditorClose}
                TransitionComponent={Transition}
            >
                <EditorAppBar title={editorTitle} />
                <Box sx={{ height: 'auto', width: '100%', padding: 2, }}>
                    {/*mySurveySetting?.fields?.map((f,i) => (<p>{`${i}: ${JSON.stringify(f)}`}</p>))*/}
                    <KForm
                        initialValues={initialV ? mySelected : mySurveySetting?.addInitialValues}
                        validationSchema={mySurveySetting?.addValidation}
                        formFields={mySurveySetting?.addForm}
                        onSubmit={(values) => {
                            // object destructuring extracts our prop that maps to sSubClass,
                            // and the rest of the object copies to myPayload.
                            const { [sSubClass]: mySSubClass, ...myPayload } = values;
                            // and thus our form values get mapped to myAss, shaped to our database structure.
                            const myAss = {
                                "sClass": viewSetting,
                                "sSubClass": mySSubClass,
                                "payload": myPayload,
                            };
                            //alert(JSON.stringify(myAss, null, 2));
                            dispatch(assActions.create(myAss));
                        }}
                        onClose={handleEditorClose}
                    />
                    {/**<pre>{JSON.stringify(mySelected)}</pre>
                            <pre>{JSON.stringify(mySurveySetting)}</pre>*/}
                    {/**<EditorAppBar bottom />*/}
                </Box>
            </Dialog>
        </div>
    );
}

// editor for toggleButtons
function ToggleButtonEditor(props) {
    // begin: dragging section dropdown items to rearrange
    const sectionTBList = props?.section?.tButton?.map((dd, i) => ({ id: dd.id, label: dd.label })) || [];
    const handleSectionTBArrange = (newSec) => {
        console.log('!Arrange toggleButtons', newSec);
        //props.onChange([...newSec.map(ss => ss.label)]);  // return new array for DropDown!
        props.onChange(newSec);
    };
    const handleTBDelete = (dditems, id) => {
        const newDD = dditems.reduce((acc, obj) => (
            obj.id != id ? [...acc, obj.label] : acc // build array of labels, exclude the dropdown item with the id we want to delete 
        ), []);
        props.onChange(newDD);  // reduce is like array.filter + map simultaneously! 
    };
    const handleTBEdit = (dditems, id, edited) => {
        const newDD = dditems.reduce((acc, obj) => (
            obj.id != id ? [...acc, obj] : [...acc, edited] // build array of labels, exclude the dropdown item with the id we want to delete 
        ), []);
        props.onChange(newDD);  // reduce is like array.filter + map simultaneously! 
    };
    // end: dragging sections to rearrange

    function DraggableTBItem(props) {
        return (
            <Paper
                sx={{
                    p: 1, my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between", justifyItems: "center", alignItems: "center",
                    userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                }}
                ref={props.provided.innerRef} {...props.provided.draggableProps} {...props.provided.dragHandleProps}
            >
                <Box sx={{ verticalAlign: 'middle', minHeight: '100%', lineHeight: 1, }}>
                    {/*
                    <Box  sx={{ position: 'relative', top: 16, pr: 2, width: 24, height: 24, }} >
                    </Box>
                     */}
                    <Box component="span" sx={{ position: 'relative', top: 16, pr: 1, width: 24, height: 24, }} >
                        <HeightIcon />
                    </Box>



                    <TextField
                        //key={'k_'+f.field}
                        //fullWidth
                        name={`tb_l[${props.listId}]_i[${props.item.id}]`}
                        label={"Article (id)"}
                        value={props.item.id}
                        InputProps={{ readOnly: true, }}
                    //onChange={(e) => {handleDDEdit(props.items, props.item.id, e.target.value)}}
                    //onBlur={handleBlur}
                    //error={touched["SID"] && Boolean(errors["SID"])}
                    //helperText={props.helperText}
                    />{' '}

                    <TextField
                        name={`tb_l[${props.listId}]_i[${props.item.id}]_L`}
                        label={"Label"}
                        value={props.item.label}
                        InputProps={{ readOnly: true, }}
                    />
                </Box>
                {/*<Box component={"div"}>{props.item.label}</Box>*/}
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            // we are using listId={`sec_tb_${props.section.SID}`}, we slice off the prefix to get SID back
                            const param = {
                                SID: props.listId.slice(7),
                                itemType: 'tButton',
                                itemID: props.item.id,
                                itemLabel: props.item.label,
                                items: props.items,
                                onChange: handleTBEdit,         // we are definitely in callback hell here, this is absolutely twisted 
                            };
                            props.onEditItem(param);
                        }}
                        aria-label="edit"
                    >
                        <EditIcon />
                    </IconButton>{' '}
                    <IconButton
                        //edge="end"
                        aria-label="delete"
                        onClick={() => {
                            //alert(`hi! from "${props.item.label}" (${props.item.id})`);
                            let text = `Are you sure you want to delete the item \"${props.item.label}\"?`;
                            if (confirm(text) == true) {
                                console.log(`User confirmed: delete dropdown item: \"${props.item.label}\".`);
                                //props.onDelete(props.item.id);
                                handleTBDelete(props.items, props.item.id);
                            }
                        }}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
            </Paper>
        );
    }

    return (
        <DraggableList
            listId={`sec_tb_${props.section.SID}`}
            items={sectionTBList}
            itemKey={(p) => (`${p.listId}_${p.item.id}`)}
            itemComponent={DraggableTBItem}
            onChange={(newItem) => handleSectionTBArrange(newItem)}
            onEditItem={props.onEditItem}
            allowDelete={true}
        />

    );
}

// editor for material dropdown
function DropDownEditor(props) {

    // begin: dragging section dropdown items to rearrange
    const sectionDDList = props?.section?.dropDown?.map((dd, i) => ({ id: dd.id, yesNo: dd.yesNo })) || [];
    const handleSectionDDArrange = (newSec) => {
        props.onChange([...newSec.map(ss => ss.label)]);  // return new array for DropDown!
    };
    const handleDDDelete = (dditems, id) => {
        const newDD = dditems.reduce((acc, obj) => (
            obj.id != id ? [...acc, obj] : acc // build array of labels, exclude the dropdown item with the id we want to delete 
        ), []);
        props.onChange(newDD);  // reduce is like array.filter + map simultaneously! 
    };
    const handleDDEdit = (dditems, id, edited) => {
        const newDD = dditems.reduce((acc, obj) => (
            obj.id != id ? [...acc, obj] : [...acc, edited] // build array of labels, exclude the dropdown item with the id we want to delete 
        ), []);
        props.onChange(newDD);  // reduce is like array.filter + map simultaneously! 
    };
    // end: dragging sections to rearrange

    function DraggableDDItem(props) {
        return (
            <Paper
                sx={{
                    p: 1, my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between",
                    justifyItems: "center",
                    alignItems: "center",
                    userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                }}
                ref={props.provided.innerRef} {...props.provided.draggableProps} {...props.provided.dragHandleProps}
            >
                <Box>
                    <Box component="span" sx={{ position: 'relative', top: 16, pr: 1, width: 24, height: 24, }} >
                        <HeightIcon />
                    </Box>
                    <TextField
                        //key={'k_'+f.field}
                        //fullWidth
                        name={`dd_l[${props.listId}]_i[${props.item.id}]`}
                        label={"Article"}
                        value={props.item.id}
                        InputProps={{ readOnly: true, }}
                    //onChange={(e) => {handleDDEdit(props.items, props.item.id, e.target.value)}}
                    //onBlur={handleBlur}
                    //error={touched["SID"] && Boolean(errors["SID"])}
                    //helperText={props.helperText}
                    />
                </Box>
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            let text = `Enter a new article to replace \"${props.item.id}\".`;
                            let edited = prompt(text, props.item.id);
                            if (edited !== null) {
                                edited = edited.trim();
                                if (edited.length > 0) {
                                    console.log(`User edited dropdown item[${props.item.id}]: \"${props.item.id}\" => \"${edited}\".`);
                                    //props.onDelete(props.item.id);
                                    handleDDEdit(props.items, props.item.id, { id: edited, yesNo: props.item.yesNo });
                                }
                            }
                        }}
                        aria-label="edit"
                    >
                        <EditIcon />
                    </IconButton>{' '}
                    <IconButton
                        //edge="end"
                        aria-label="delete"
                        onClick={() => {
                            //alert(`hi! from "${props.item.label}" (${props.item.id})`);
                            let text = `Are you sure you want to delete the item \"${props.item.id}\"?`;
                            if (confirm(text) == true) {
                                console.log(`User confirmed: delete dropdown item: \"${props.item.id}\".`);
                                //props.onDelete(props.item.id);
                                handleDDDelete(props.items, props.item.id);
                            }
                        }}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
            </Paper>
        );
    }

    return (
        <DraggableList
            listId={props.section.SID}
            items={sectionDDList}
            itemKey={(p) => (`${p.listId}_${p.item.id}`)}
            itemComponent={DraggableDDItem}
            onChange={(newItem) => handleSectionDDArrange(newItem)}
            allowDelete={true}
        />

    );
}

// home depot cantilever section
function HDCSection(props) {
    const initialValues = { SID: props.section.SID || '', sLabel: props.section.sLabel || '', };

    //const sectionContents = (<pre>{JSON.stringify(props.section, null, ' ')}</pre>);

    const sectionContents = (
        <div>
            <TextField
                //key={'k_'+f.field}
                fullWidth
                multiline

                name={"sText"}
                label={`Section Text - ${props?.section?.sLabel || '?'}`}
                value={props?.section?.sText || ''}
                onChange={(e) => { props.onChange(props.section.SID, { ...props.section, sText: e.target.value }); }}
            //onBlur={handleBlur}
            //error={touched["SID"] && Boolean(errors["SID"])}
            //helperText={props.helperText}
            />

            {props?.section?.dropDown && (
                <Box sx={{ border: 1, borderColor: '#00000066', borderRadius: 1, p: 1, my: 1, }} >
                    <label>{`Drop Down Articles - ${props?.section?.sLabel || '?'}`}</label>
                    <Box >
                        <DropDownEditor
                            section={props.section}
                            onChange={(newDD) => { props.onChange(props.section.SID, { ...props.section, dropDown: newDD }); }}

                        />
                    </Box>
                </Box>
            )}

            {/* ToggleButtonEditor */}
            {props?.section?.tButton && (
                <Box sx={{ border: 1, borderColor: '#00000066', borderRadius: 1, p: 1, my: 1, }} >
                    <label>{`Toggle Buttons - ${props?.section?.sLabel || '?'}`}</label>
                    <Box >
                        <ToggleButtonEditor
                            section={props.section}
                            onChange={(newTB) => { props.onChange(props.section.SID, { ...props.section, tButton: newTB }); }}
                            onEditItem={props.onEditItem}

                        />
                    </Box>
                </Box>
            )}

            {/* <pre>{JSON.stringify(props.section, null, ' ')}</pre> */}

        </div>);

    if (props?.isEditingID) {
        return (
            <Formik
                //enableReinitialize  // listens if initialValues change
                initialValues={initialValues}
                validationSchema={props.validationSchema ? props.validationSchema : undefined}
                onSubmit={(values, actions) => {
                    const newSID = values.SID.trim(), newSLabel = values.sLabel.trim();
                    // ensure SID and sLabel not blank!
                    if (newSID.length < 1) {
                        alert(`Section ID cannot be empty!`);
                        return;
                    }
                    if (newSLabel.length < 1) {
                        alert(`Section label cannot be empty!`);
                        return;
                    }
                    // now, alter SID + sLabel IF it won't collide with another SID
                    if (props.onCheck(newSID)) {
                        alert(`Section ID already exists: "${newSID}"!`);
                        return;
                    }
                    props.onChange(props.section.SID, { ...props.section, SID: newSID, sLabel: newSLabel });
                    props.onCancel(); // we did the change but also cancel to close "edit mode"                    
                }}
            >
                {({ values, touched, errors, isSubmitting,
                    handleChange, handleBlur, submitForm, setSubmitting, }) => (
                    <Form>
                        <Paper
                            sx={{
                                // border: 2, borderColor: '#0a0', color: '#000', borderRadius: 1, 
                                backgroundColor: '#eee',
                                p: 1, my: 1,
                                //userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                            }}
                        >
                            <Box sx={{ my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
                                <Box
                                    component="span"
                                >
                                    Section {props.index + 1}: {props.section.sLabel}
                                </Box>

                                {/*formFields?.map((f, i) => (
                                <KFormField
                                    key={'k_' + f.field}
                                    field={f}
                                    values={values} // added so a field can check values of rest of the form  i.e. dropdown / branching question
                                    value={values?.[f.field]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                />
                            ))*/}

                                <TextField
                                    //key={'k_'+f.field}
                                    //fullWidth
                                    name={"SID"}
                                    label={"Section ID"}
                                    value={values.SID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched["SID"] && Boolean(errors["SID"])}
                                //helperText={props.helperText}
                                />
                                <TextField
                                    //key={'k_'+f.field}
                                    //fullWidth
                                    name={"sLabel"}
                                    label={"Label"}
                                    value={values.sLabel}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched["sLabel"] && Boolean(errors["sLabel"])}
                                //helperText={props.helperText}
                                />
                                <Button
                                    variant="outlined"
                                    color="error"
                                    //disabled={isSubmit && surveySetting.isAdding}
                                    startIcon={<ClearIcon />}
                                    onClick={props.onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    //disabled={isSubmit && surveySetting.isAdding}
                                    startIcon={<DoneIcon />}
                                    onClick={submitForm}
                                >
                                    Apply
                                </Button>

                            </Box>

                            {sectionContents}
                        </Paper>

                        {/*
                            <pre>{JSON.stringify(props.section, null, ' ')}</pre>
                            <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(surveySettingTrim, null, 2)}</Typography></pre>
                            <pre><Typography sx={{ fontSize:12 }}>{JSON.stringify(props?.formFields,null,2)}</Typography></pre>          
                            <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(values, null, 2)}</Typography></pre>
                        */}

                    </Form>
                )}
            </Formik>
        );
    } else {
        return (
            <Paper
                sx={{
                    // border: 2, borderColor: '#0a0', color: '#000', borderRadius: 1, 
                    backgroundColor: '#eee',
                    p: 1, my: 1, //display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                    //userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                }}
            >
                <Box sx={{
                    // border: 2, borderColor: '#0a0', color: '#000', borderRadius: 1, 
                    backgroundColor: '#eee',
                    p: 0, my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                    //userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                }}
                >

                    <Box component="div">
                        Section {props.index + 1}: {props.section.sLabel}
                        <Box component="span" sx={{ fontSize: 12, position: "relative", left: 32, bottom: 2, }}>(SID: {props.section.SID})</Box>

                    </Box>
                    <Box>
                        <Button
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={props?.onEditSID}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
                {sectionContents}
            </Paper>
        );
    }

}

function AddSectionForm(props) {
    // have we initiated submitting the form?
    const [isSubmit, setIsSubmit] = React.useState(false);

    const initialValues = { SID: '', sLabel: '', };

    return (
        <Formik
            enableReinitialize  // listens if initialValues change
            initialValues={initialValues}
            validationSchema={props.validationSchema ? props.validationSchema : undefined}
            onSubmit={(values, actions) => {
                setIsSubmit(true); // we have tried to submit the form so react to redux updates
                props.onSubmit(values, actions.setSubmitting);
            }}
        >
            {({ values, touched, errors, isSubmitting,
                handleChange, handleBlur, submitForm, setSubmitting, }) => (
                <Form>
                    <Paper
                        sx={{
                            border: 2, borderColor: '#0a0', color: '#000', borderRadius: 1, backgroundColor: '#fafffa',
                            p: 1, my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                            //userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
                        }}
                    >

                        {/*formFields?.map((f, i) => (
                        <KFormField
                            key={'k_' + f.field}
                            field={f}
                            values={values} // added so a field can check values of rest of the form  i.e. dropdown / branching question
                            value={values?.[f.field]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                        />
                    ))*/}
                        <Box sx={{ textAlign: "center", }} component="span">Add Section:</Box>
                        <Box sx={{ px: 1, }}>
                            <TextField
                                fullWidth
                                name={"SID"}
                                label={"Section ID"}
                                value={values.id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched["SID"] && Boolean(errors["SID"])}
                            //helperText={props.helperText}
                            />
                        </Box>
                        <Box sx={{ px: 1, }}>
                            <TextField
                                //key={'k_'+f.field}
                                fullWidth
                                name={"sLabel"}
                                label={"Label"}
                                value={values.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched["sLabel"] && Boolean(errors["sLabel"])}
                            //helperText={props.helperText}
                            />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            //disabled={isSubmit && surveySetting.isAdding}
                            onClick={submitForm}
                        >
                            Add
                        </Button>
                    </Paper>

                    {/*
              <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(surveySettingTrim, null, 2)}</Typography></pre>
              <pre><Typography sx={{ fontSize:12 }}>{JSON.stringify(props?.formFields,null,2)}</Typography></pre>          
                    <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(values, null, 2)}</Typography></pre>
            */}

                </Form>
            )}
        </Formik>
    );
}

// required props: 
// 1) show <bool>: whether dialog is visible or not
// 2) itemType <enum (string)> 'dropDown' / 'tButton'
// 3) onChange <function> : called back with 
// pass an object with props: sectionID, itemType (dropDown / tButton), itemID, itemLabel (to display to user)

// button <object> props: <string> label, autoFocus, <func> onClick,

function AddOrEditDialog(props) {
    // have we initiated submitting the form? only need this for async operations lol
    //const [isSubmit, setIsSubmit] = React.useState(false);
    //const initialValues = { SID: '', sLabel: '', };

    let myTitle = props?.isEditing ? 'Edit' : 'Add';
    if (props?.itemType == 'tButton') { myTitle += ' Toggle Button'; }

    // short circuit rendering if we haven't initialized values for our fields.
    if (!(props?.initialValues)) { return undefined; }

    return (
        <Formik
            enableReinitialize  // listens if initialValues change
            initialValues={props?.initialValues ? props?.initialValues : {}}
            validationSchema={props?.validationSchema ? props.validationSchema : undefined}
            onSubmit={(values, actions) => {
                //setIsSubmit(true); // we have tried to submit the form so react to redux updates
                console.log(`Dialog submit values: `, values);
                props.onChange(props.items, props.initialValues.id, values);  // this fires the callback handleTBEdit
            }}
        >
            {({ values, touched, errors, isSubmitting,
                handleChange, handleBlur, submitForm, resetForm, setSubmitting, }) => (
                <Form>
                    <Dialog
                        open={props?.show || false}
                        onClose={props.onClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {myTitle}
                        </DialogTitle>
                        <DialogContent>
                            {/* 
                                <DialogContentText id="alert-dialog-description">
                                </DialogContentText>
                            */}
                            {/*
                                {props?.content}
                                
                                formFields?.map((f, i) => (
                        <KFormField
                            key={'k_' + f.field}
                            field={f}
                            values={values} // added so a field can check values of rest of the form  i.e. dropdown / branching question
                            value={values?.[f.field]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                        />
                    ))*/}
                            <Box sx={{ textAlign: "left", }} component="div">
                                <pre sx={{ fontSize: 8 }}>{JSON.stringify(props, null, ' ')}</pre>
                            </Box>
                            <Box sx={{ p: 1, }}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    name={"id"}
                                    label={"ID"}
                                    value={values.id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched["id"] && Boolean(errors["id"])}
                                //helperText={props.helperText}
                                />
                            </Box>
                            <Box sx={{ p: 1, }}>
                                <TextField
                                    //key={'k_'+f.field}
                                    fullWidth
                                    name={"label"}
                                    label={"Label"}
                                    value={values.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched["label"] && Boolean(errors["label"])}
                                //helperText={props.helperText}
                                />
                            </Box>

                            {/*
                                <Button
                                    variant="contained"
                                    color="primary"
                                    //disabled={isSubmit && surveySetting.isAdding}
                                    onClick={submitForm}
                                >
                                    Add
                                </Button>

              <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(surveySettingTrim, null, 2)}</Typography></pre>
              <pre><Typography sx={{ fontSize:12 }}>{JSON.stringify(props?.formFields,null,2)}</Typography></pre>          
                    <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(values, null, 2)}</Typography></pre>
            */}

                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "space-between" }}>
                            <Button
                                onClick={props.onClose}
                            // autoFocus
                            >Cancel</Button>{' '}
                            <Button onClick={resetForm}>
                                Reset
                            </Button>
                            <Button onClick={submitForm}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>


                </Form>
            )}
        </Formik>
    );
}

export { SurveyBuilderHDC };