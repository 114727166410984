import * as React from 'react';
import {
  Button, LinearProgress, Typography, Box, Paper, TextField,
  Select, MenuItem, InputLabel, FormControl, FormGroup, FormLabel,
  FormHelperText,
  IconButton, Stack, Chip,
} from '@mui/material';
import Slider from '@mui/material/Slider';
// table stuff
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// appData (HD Day Dimensions - W, H, D)
import { HDBayDepths, HDBayHeights, HDBayWidths } from '../_appdata/survey';
// icons
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
// formik stuff
import { Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
// redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { questionTypes, surveyTypes } from '../_helpers/AdminSurveySettingData';

// table style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
// end table style

// Survey Type Picker
function KFieldSType(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  //const [field] = useField(props);

  return (
    <FormControl 
    fullWidth 
    variant='outlined'
    error={props.error}  // note the different way a mui picker handles an error, i.e. this line + FormHelperText below...
    >
      <InputLabel id={'lbl' + props.name}>{props.label}</InputLabel>
      <Select
        labelId={'lbl' + props.name}
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={(e) => {
          setFieldValue(props.name, e.target.value);
        }}
        onBlur={props.onBlur}
      >
        {surveyTypes.map((s, i) => (
          <MenuItem key={'sta_' + s.stid} value={s.stid}>{s.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

// Question Type Picker
function KFieldQType(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  //const [field] = useField(props);

  return (
    <FormControl fullWidth variant='outlined' error={props.error}>
      <InputLabel id={'lbl' + props.name}>{props.label}</InputLabel>
      <Select
        labelId={'lbl' + props.name}
        //id={'pck' + props.name}
        id={props.name}
        label={props.label}
        value={props.value}
        //onChange={props.onChange}
        onChange={(e) => {
          setFieldValue(props.name, e.target.value);
        }}
        onBlur={props.onBlur}
      >
        {questionTypes.map((q, i) => (
          <MenuItem key={'qta_' + q.qtid} value={q.qtid}>{q.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

// new: dropdown has different uses for different QTypes.
function KFieldDropDown(props) {
  //const f = props?.field || {};
  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  // myQuestionType: defines behavior of dropdown, 
  // QType 1 = home depot material dropdown
  // QType 4 = branching question options (button color, button label, questions to add)
  // Other = plain text field (unused)
  const myQType = props?.values?.QType || 0;
  // Dropdown items (dropdown value text split to array by pipe character)
  const ddItems = (props?.value || '').split('|');

  // delete an item from the dropdown
  function deleteItem(index) {
    console.log(`Delete dropDown item: [${index}].`);
    // this works but seems slow...?
    const newItems = ddItems.filter((v, i) => (i !== index));
    // trying this instead
    //const newItems = [...ddItems.slice(0, index), ...ddItems.slice(index + 1)];
    setFieldValue(props.name, newItems.join('|'));
  }

  // render dropdown for "standard question w/ dropdown (home depot)"
  if (myQType == 1) {
    // count the occurances of each item and highlight duplicates
    const counts = {};
    if (myQType == 1) {
      ddItems.map(num => { counts[num] = counts[num] ? counts[num] + 1 : 1; });
    }

    return (
      <Paper
        //key={'b_'+f.field} 
        sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
        variant="outlined"
      >
        <TextField
          //key={'k_'+f.field}
          fullWidth
          name={props.name}
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          error={props.error}
          helperText={props.helperText}
        />
        <Button
          sx={{ marginTop: 2 }}
          variant="outlined"
          color="primary"
        // onClick={submitForm}
        >
          Pick Articles
        </Button>
        {(props?.value?.length > 0) && (
          <TableContainer component={Paper} sx={{ marginTop: 2 }} >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell align="left">Article</StyledTableCell>
                  <StyledTableCell align="left">Description</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      sx={{ ml: 1, color: "#eee" }}
                      size="small"
                    // onClick={() => { if(typeof props?.onDelete == 'function') {props.onDelete(row.id, row.firstName, row.lastName);} }} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ddItems.map((q, i) => (
                  <StyledTableRow key={`tr_${i}_${q}`}>
                    <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                    <StyledTableCell sx={{
                      color: counts[q] == 1 ? '#000' : '#a00',
                      fontWeight: counts[q] == 1 ? '200' : '600',
                    }}>{q}</StyledTableCell>
                    <StyledTableCell sx={{
                      color: counts[q] == 1 ? '#000' : '#a00',
                      fontWeight: counts[q] == 1 ? '200' : '600',
                    }}>-</StyledTableCell>

                    <StyledTableCell align="center">
                      <IconButton
                        sx={{ ml: 1, color: "#000" }}
                        size="small"
                        onClick={() => { deleteItem(i); }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      </Paper>
    );
  }

  // render dropdown for "branching question (can add extra questions to survey)"
  if (myQType == 4) {
    const choiceButtons = [];
    ddItems.map((q, i) => {
      const cb = q.split(',').map(qq => (qq.trim())), cbLen = cb.length;
      // begin building object
      let cbTemp = { valid: false };
      if (cbLen > 2) {
        cbTemp.label = cb[0]; cbTemp.color = cb[1]; cbTemp.QIDS = cb.slice(2);
        cbTemp.valid = true;
      }
      // add choicebutan
      choiceButtons.push(cbTemp);
    });
    return (
      <Paper
        //key={'b_'+f.field} 
        sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
        variant="outlined"
      >
        <TextField
          //key={'k_'+f.field}
          fullWidth
          name={props.name}
          label={'Answer Buttons'}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          error={props.error}
          helperText={props.helperText}
        />
        <Button
          sx={{ marginTop: 2 }}
          variant="outlined"
          color="primary"
        // onClick={submitForm}
        >
          Add
        </Button>
        {(props?.value?.length > 0) && (
          <TableContainer component={Paper} sx={{ marginTop: 2 }} >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell align="left">Color</StyledTableCell>
                  <StyledTableCell align="left">Label</StyledTableCell>
                  <StyledTableCell align="left">Question IDs To Add To Survey</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      sx={{ ml: 1, color: "#eee" }}
                      size="small"
                    // onClick={() => { if(typeof props?.onDelete == 'function') {props.onDelete(row.id, row.firstName, row.lastName);} }} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {choiceButtons.map((q, i) => (
                  <StyledTableRow key={`tr_${i}_${q}`}>
                    <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                    <StyledTableCell >{q?.color}</StyledTableCell>
                    <StyledTableCell >{q?.label}</StyledTableCell>
                    <StyledTableCell >{q?.QIDS?.join(',')}</StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        sx={{ ml: 1, color: "#000" }}
                        size="small"
                        onClick={() => { deleteItem(i); }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </StyledTableCell>

                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      </Paper>
    );
  }

  // render dropdown as a basic text field for unknown / missing QType
  return (
    <Paper
      //key={'b_'+f.field} 
      sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
      variant="outlined"
    >
      <TextField
        //key={'k_'+f.field}
        fullWidth
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
      />
    </Paper>
  );
}

function KFieldQVis(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const myChips = [
    { id: 's_r', label: 'Seismic Regular Steel' },
    { id: 's_g', label: 'Seismic Galvanized' },
    { id: 's_c', label: 'Seismic Cantilever' },
    { id: 'n_r', label: 'Non-Seismic Regular Steel' },
    { id: 'n_g', label: 'Non-Seismic Galvanized' },
    { id: 'n_c', label: 'Non-Seismic Cantilever' },
  ];
  const chips = props?.value ? new Set(props.value.split('|')) : new Set();

  function chipToggle(myId) {
    if (chips.has(myId)) {
      chips.delete(myId);
    } else {
      chips.add(myId);
    }
    const val = [...chips].join('|');
    console.log(`Toggle chip: [${myId}], "${val}".`);
    setFieldValue(props.name, val);
  }

  return (
    <Paper
      //key={'b_'+f.field} 
      sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
      variant="outlined"
    >

      <Typography>Visibility</Typography>

      <Box sx={{ padding: 0, margin: 0, }}>
        {myChips.map((c, i) => (
          <Chip
            key={`c_${c.id}`}
            label={c.label}
            sx={{
              margin: 1,
              color: chips.has(c.id) ? "#060" : "#600",
              backgroundColor: chips.has(c.id) ? "#afa" : "#faa",
            }}
            variant={chips.has(c.id) ? "filled" : "outlined"}
            onClick={() => { chipToggle(c.id); }}
          />
        ))}

      </Box>

    </Paper>
  );
}

function KFieldHDSteelVis(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const myChips = [
    { id: 'nas', label: 'North American Steel' },
    { id: 'bon', label: 'Boni' },
    { id: 'arp', label: 'Arpac' },
  ];
  const chips = props?.value ? new Set(props.value.toLowerCase().split('|')) : new Set();

  function chipToggle(myId) {
    if (chips.has(myId)) {
      chips.delete(myId);
    } else {
      chips.add(myId);
    }
    const val = [...chips].join('|');
    console.log(`Toggle chip: [${myId}], "${val}".`);
    setFieldValue(props.name, val);
  }

  return (
    <Paper
      //key={'b_'+f.field} 
      sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
      variant="outlined"
    >

      <Typography>Steel Brand</Typography>

      <Box sx={{ padding: 0, margin: 0, }}>
        {myChips.map((c, i) => (
          <Chip
            key={`c_${c.id}`}
            label={c.label}
            sx={{
              margin: 1,
              color: chips.has(c.id) ? "#060" : "#600",
              backgroundColor: chips.has(c.id) ? "#afa" : "#faa",
            }}
            variant={chips.has(c.id) ? "filled" : "outlined"}
            onClick={() => { chipToggle(c.id); }}
          />
        ))}

      </Box>

    </Paper>
  );
}

function KFieldHDSteelColor(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const myChips = [
    { id: 'wht', label: 'White' },
    { id: 'org', label: 'Orange' },
    { id: 'gal', label: 'Galvanized' },
  ];
  const chips = props?.value ? new Set(props.value.toLowerCase().split('|')) : new Set();

  function chipToggle(myId) {
    if (chips.has(myId)) {
      chips.delete(myId);
    } else {
      chips.add(myId);
    }
    const val = [...chips].join('|');
    console.log(`Toggle chip: [${myId}], "${val}".`);
    setFieldValue(props.name, val);
  }

  return (
    <Paper
      //key={'b_'+f.field} 
      sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
      variant="outlined"
    >

      <Typography>Steel Color</Typography>

      <Box sx={{ padding: 0, margin: 0, }}>
        {myChips.map((c, i) => (
          <Chip
            key={`c_${c.id}`}
            label={c.label}
            sx={{
              margin: 1,
              color: chips.has(c.id) ? "#060" : "#600",
              backgroundColor: chips.has(c.id) ? "#afa" : "#faa",
            }}
            variant={chips.has(c.id) ? "filled" : "outlined"}
            onClick={() => { chipToggle(c.id); }}
          />
        ))}

      </Box>

    </Paper>
  );
}

function KFieldHDBayDim(props) {
  //const f = props?.field || {};

  // formik stuff to make changing value work
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  // These dimension values MUST match with the mobile app - TODO: move these into AdminSurveySettings!
  let myDims = [0, 1], myDimLabel = "undefined", myMin = 0, myMax = 1;
  if (props.name == 'W') {
    myDimLabel = "Width";
    myDims = [...HDBayWidths];
    myMax = myDims[myDims.length - 1];  // dimensions are expected to be in ascending order, therefore the last value is the max.
  }
  else if (props.name == 'H') {
    myDimLabel = "Height";
    myDims = [...HDBayHeights];
    myMax = myDims[myDims.length - 1];
  }
  else if (props.name == 'D') {
    myDimLabel = "Depth";
    myDims = [...HDBayDepths];
    myMax = myDims[myDims.length - 1];
  }

  const marks = myDims.map(d => ({ value: d, label: `${d}"` }));
  /*
    {
      value: 0,
      label: '0°C',
    },
    */

  function valuetext(value) {
    return `${marks[value]}"`;
  }
  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  function handleSliderChange(event, value) {
    console.log(`Dim Slider (${myDimLabel}): [${value}], .`);
    setFieldValue(props.name, value);
  }

  return (
    <Paper
      //key={'b_'+f.field} 
      sx={{ padding: 2, margin: 0, borderRadius: 1, borderColor: '#333', }}
      variant="outlined"
    >
      <Typography>Dimension {myDimLabel}: {typeof props.value === 'number' ? (props.value > 0 ? `${props.value}"` : '0 (Any)') : '(Invalid)'}</Typography>

      <Box sx={{ width: 'auto' }}>
        <Slider
          aria-label="Restricted values"
          defaultValue={myMin}
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valuetext}
          onChange={handleSliderChange}
          step={null}
          min={myMin}
          max={myMax}
          valueLabelDisplay="off"
          marks={marks}
          value={typeof props.value === 'number' ? props.value : 0}
        />
      </Box>
    </Paper>
  );
}

function KFormField(props) {
  const myType = props?.field?.type?.split('|') || [];
  const f = props?.field || {};

  switch (myType[0]) {

    case 'mtext':
      // multilinetext (mtext) can have a minRows parameter i.e. 'mtext|3', otherwise defaults to 2...
      // TODO: also add optional maxRows parameter i.e. 'mtext|6|9'. possibly other params? what could be useful?
      const myMinRows = myType.length >= 2 ? Number(myType[1]) : 2;
      return (
        <Box
          //key={'b_'+f.field} 
          sx={{ paddingTop: 1, paddingBottom: 1, }}
        >
          <TextField
            //key={'k_'+f.field}
            fullWidth
            multiline
            minRows={myMinRows}
            name={f.field}
            label={f.name}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.touched[f.field] && Boolean(props.errors[f.field])}
            helperText={props.touched[f.field] && props.errors[f.field]}
          />
        </Box>
      );

    case 'qtype':
      // questionType
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldQType
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );


    case 'stype':
      // surveyType
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldSType
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );

    case 'qvis':
      // questionType
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldQVis
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );

    case 'hdsbrand':
      // home depot steel brand
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldHDSteelVis
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );

    case 'hdscolor':
      // home depot steel color org (orange) / wht (white) / gal (galvanized)
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldHDSteelColor
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );

    case 'hddim':
      // home depot bay dimension w/h/d (in name prop)
      return (
        <>
          <Box
            //key={'b_'+f.field} 
            sx={{ paddingTop: 1, paddingBottom: 1, }}
          >
            <KFieldHDBayDim
              //key={'k_'+f.field}
              fullWidth
              name={f.field}
              label={f.name}
              value={Number(props.value)}
              onChange={props.onChange}
              onBlur={props.onBlur}
              error={props.touched[f.field] && Boolean(props.errors[f.field])}
              helperText={props.touched[f.field] && props.errors[f.field]}
            />
          </Box>
        </>
      );

    case 'hddd':
      // home depot drop down
      // new: drop down can be used to hold the data of our branching question! (QType == 4)
      return (
        <Box
          //key={'b_'+f.field} 
          sx={{ paddingTop: 1, paddingBottom: 1, }}
        >
          {/*<p>{JSON.stringify(props.values)}</p>*/}
          <KFieldDropDown
            //key={'k_'+f.field}
            fullWidth
            name={f.field}
            label={f.name}
            value={props.value}
            values={props.values}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.touched[f.field] && Boolean(props.errors[f.field])}
            helperText={props.touched[f.field] && props.errors[f.field]}
          />
        </Box>
      );

    case 'text':
      return (
        <Box
          //key={'b_'+f.field} 
          sx={{ paddingTop: 1, paddingBottom: 1, }}
        >
          <TextField
            //key={'k_'+f.field}
            fullWidth
            name={f.field}
            label={f.name}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.touched[f.field] && Boolean(props.errors[f.field])}
            helperText={props.touched[f.field] && props.errors[f.field]}
          />
        </Box>
      );

    default:
      // switch faled, no valid props.field.type?
      return (
        <Box
          //key={'b_'+f.field} 
          sx={{ paddingTop: 1, paddingBottom: 1, }}
        >
          <Typography

          >Invalid field. {JSON.stringify(f)}</Typography>
        </Box>

      );
  }
}

function KForm(props) {
  // have we initiated submitting the form?
  const [isSubmit, setIsSubmit] = React.useState(false);
  // subscribe to redux updates re: our submission result
  const surveySetting = useSelector(state => state?.adminSurveySettings);
  // clone survey setting WITHOUT some props...
  // const clone = (({ b, c, ...o }) => o)(obj) // remove b and c
  /*
  const surveySettingTrim = (({ 
    setting, HDStore, Survey, Question, HDArticle, HDBayAisle, HDDepartment, 
    ...o }) => o)(surveySetting) // remove b and c
  */

  // if we tried submitting and it worked, callback to request form (modal dialog) close.
  React.useEffect(() => {
    if (isSubmit && surveySetting.isAdded) {
      if (props?.onClose) {
        props.onClose(true); // return true to prompt auto refresh.
      }
    }
  }, [surveySetting.isAdded]);

  return (
    <Formik
      enableReinitialize  // listens if initialValues change
      initialValues={props.initialValues}
      validationSchema={props.validationSchema ? props.validationSchema : undefined}
      onSubmit={(values, actions) => {
        setIsSubmit(true); // we have tried to submit the form so react to redux updates
        props.onSubmit(values, actions.setSubmitting);
      }}
    >
      {({ values, touched, errors, isSubmitting,
        handleChange, handleBlur, submitForm, setSubmitting, }) => (
        <Form>
          {props?.formFields?.map((f, i) => (
            <KFormField
              key={'k_' + f.field}
              field={f}
              values={values} // added so a field can check values of rest of the form  i.e. dropdown / branching question
              value={values?.[f.field]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          ))}
          {isSubmit && surveySetting.isAdding && <LinearProgress />}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmit && surveySetting.isAdding}
            onClick={submitForm}
          >
            Save
          </Button>
          {isSubmit && surveySetting.addError && (<>
            <Typography sx={{ color: '#900', my: 2 }} display="block">{`FAILED: '${surveySetting.addError}'.`}</Typography>
            <Typography sx={{ color: '#900', my: 2 }} display="block">This means your changes did NOT save.</Typography>
          </>)}

          {/*
          <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(surveySettingTrim, null, 2)}</Typography></pre>
          <pre><Typography sx={{ fontSize:12 }}>{JSON.stringify(props?.formFields,null,2)}</Typography></pre>          
        */}
        <pre><Typography sx={{ fontSize: 12 }}>{JSON.stringify(values, null, 2)}</Typography></pre>

        </Form>
      )}
    </Formik>
  );
}

export { KForm };