import config from 'config';
import { authHeader } from '../_helpers';

export const assService = {
    create,
    getAll,
    getById,
    update,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // TODO: change this back to HandleResponse
    return fetch(`${config.apiUrl}/api/AdminSurveySettings`, requestOptions).then(handleResponseAndUnpack);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/AdminSurveySettings/${id}`, requestOptions).then(handleResponseAndUnpack);
}

// this function is added to original version of services ...
function create(adminSurveySetting) {
    // object destructuring to split payload off, as it's contents must be stringified to go into the DB.
    const { payload, ...myAss } = adminSurveySetting;
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        // payload must be converted from JS object to string...
        body: JSON.stringify({...myAss, payload: JSON.stringify(payload)})
    };

    // TODO: change this back to HandleResponse
    return fetch(`${config.apiUrl}/api/AdminSurveySettings`, requestOptions).then(handleResponseAndLog);
}

function update(id, adminSurveySetting) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(adminSurveySetting)
    };

    return fetch(`${config.apiUrl}/api/AdminSurveySettings/${id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/AdminSurveySettings/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseAndLog(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        console.log(data);
        return data;
    });
}

function handleResponseAndUnpack(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        // check if we got an array as per https://stackoverflow.com/questions/1058427/how-to-detect-if-a-variable-is-an-array
        if (Object.prototype.toString.call(data) === '[object Array]') {
            console.log("got array!")
            return data.map(d => Unpack(d));
        }

        console.log("got object!")

        console.log(data);
        return Unpack(data);
    });
}

const Unpack = (data) => {
    if (data?.payload?.length > 4)
    {
        try {
            return {...data, payload: JSON.parse(data?.payload)};
        } catch (e) {
            return {...data, payload: { error: e }};
        }
    }

    console.error(`Unpack passed invalid object or missing payload key: "${data}"`);
    return null;
}