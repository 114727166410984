// Administrator Survey Settings
export const adminSurvSetConstants = {

    GETALL_DOIT: 'ADMINSURVSET_GETALL_DOIT',
    GETALL_SUCC: 'ADMINSURVSET_GETALL_SUCC',
    GETALL_FAIL: 'ADMINSURVSET_GETALL_FAIL',

    GET_DOIT: 'ADMINSURVSET_GET_DOIT',
    GET_SUCC: 'ADMINSURVSET_GET_SUCC',
    GET_FAIL: 'ADMINSURVSET_GET_FAIL',

    ADD_DOIT: 'ADMINSURVSET_ADD_DOIT',
    ADD_SUCC: 'ADMINSURVSET_ADD_SUCC',
    ADD_FAIL: 'ADMINSURVSET_ADD_FAIL',

    // Update (modify existing DB record in-place)
    UPDATE_DOIT: 'ADMINSURVSET_UPDATE_DOIT',
    UPDATE_SUCC: 'ADMINSURVSET_UPDATE_SUCC',
    UPDATE_FAIL: 'ADMINSURVSET_UPDATE_FAIL',

    // Delete (deletions done simply by setting a bit flag on DB rows, not actual deletions, to keep history, like a 'recycle bin')
    DELETE_DOIT: 'ADMINSURVSET_DELETE_DOIT',
    DELETE_SUCC: 'ADMINSURVSET_DELETE_SUCC',
    DELETE_FAIL: 'ADMINSURVSET_DELETE_FAIL',
    // UnDelete (clear the deleted bit)
    UNDELETE_DOIT: 'ADMINSURVSET_UNDELETE_DOIT',
    UNDELETE_SUCC: 'ADMINSURVSET_UNDELETE_SUCC',
    UNDELETE_FAIL: 'ADMINSURVSET_UNDELETE_FAIL',

    // Activate (another flag bit on the DB row, like the 'deleted' flag)
    ACT_DOIT: 'ADMINSURVSET_ACT_DOIT',
    ACT_SUCC: 'ADMINSURVSET_ACT_SUCC',
    ACT_FAIL: 'ADMINSURVSET_ACT_FAIL',
    // Deactivate (only active survey settings should take effect)
    DEACT_DOIT: 'ADMINSURVSET_DEACT_DOIT',
    DEACT_SUCC: 'ADMINSURVSET_DEACT_SUCC',
    DEACT_FAIL: 'ADMINSURVSET_DEACT_FAIL',

};
