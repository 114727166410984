import { CallToActionOutlined } from '@mui/icons-material';
import { adminSurvSetConstants } from '../_constants';

const initialState = {
    // loading adminSurveySettings
    isLoaded: false,
    isLoading: false,
    setting: [],
    error: null,
    // adding an adminSurveySetting
    isAdded: false,
    isAdding: false,
    addError: null,
};

export function adminSurveySettings(state = {}, action) {
    switch (action.type) {
        case adminSurvSetConstants.GETALL_DOIT:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                //setting: [],
                error: null,    // error loading 
            };
        case adminSurvSetConstants.GETALL_SUCC:
            console.log(`adminSurvetSettings: getAll SUCCESS: ${action.data.length} items.`);
            //console.log(`adminSurvetSettings: ${JSON.stringify(action.data)}`);
            // TODO! add the rest of the adminSurveySettings here, surveys, questions, hd-articles, etc. etc.
            let setting = []; // unknown settings (for debugging mostly i guess, or if the backend adds things not yet implemented in the mobile app)
            let myHDStores = [];  // home depot store locations
            let mySurveys = [];  // surveys, i.e. home depot.
            let myHDArticles = [];  // home depot articles (CAN6000123, etc.)
            let myQuestions = [];  // survey questions
            let myHDBayAisles = [];  // survey questions
            let myHDDepartments = [];  // survey questions

            action.data.map(ass => {
                switch (ass.sClass) {
                    case 'HD-S': // hd (Home Depot) Stores
                        myHDStores.push({ 'Number': ass.sSubClass, ...ass.payload });  // Number is the sSubClassProp
                        break;
                    case 'SUR':
                        mySurveys.push({ 'SID': ass.sSubClass, ...ass.payload });  // SID (survey id) is the sSubClassProp
                        break;
                    case 'HD-A':
                        myHDArticles.push({ 'Article': ass.sSubClass, ...ass.payload });  // Article is the sSubClassProp
                        break;
                    case 'SQU':
                        myQuestions.push({ 'QID': ass.sSubClass, ...ass.payload });  // QID (question id) is the sSubClassProp
                        break;
                    case 'HD-B': // hd bay aisles
                        myHDBayAisles.push({ 'AisleID': ass.sSubClass, ...ass.payload });  // AisleID is the sSubClassProp
                        break;
                    case 'HD-D': // hd departments
                        myHDDepartments.push({ 'DeptNo': ass.sSubClass, ...ass.payload });  // Article is the sSubClassProp
                        break;

                    default:
                        setting.push(ass); // this means something is implemented backend but not mobile app
                }
            });

            console.log(`Parsed: Surveys (${mySurveys.length}), Questions (${myQuestions.length}), HD-Stores: (${myHDStores.length}), HD-Articles: (${myHDArticles.length}).`);
            if (setting.length > 0) { console.log(`!WARNING: Unknown settings (${myQuestions.length})...\n${JSON.stringify(setting.slice(-3), null, ' ')}`); }

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                // setting: setting, // IOS version
                setting: action.data, // Web version !! Revert this when AdminSurveySettings page updated accordingly
                // now to unpack the items, in the way our app expects (web app does not do this yet!)                
                HDStore: myHDStores,
                Survey: mySurveys,
                Question: myQuestions,
                HDArticle: myHDArticles,
                HDBayAisle: myHDBayAisles,
                HDDepartment: myHDDepartments,
                Other: setting, // Web version
                error: null,
            };
        case adminSurvSetConstants.GETALL_FAIL:
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                // setting: [],
                error: action.error,
            };

        case adminSurvSetConstants.ADD_DOIT:
            return {
                ...state,
                isAdded: false,
                isAdding: true,
                addError: null,    // error adding 
            };
        case adminSurvSetConstants.ADD_SUCC:
            console.log("added adminSurveySetting!");
            console.log(action.data);
            return {
                ...state,
                isAdded: true,
                isAdding: false,
                //setting: [],                
            };
        case adminSurvSetConstants.ADD_FAIL:
            return {
                ...state,
                isAdded: false,
                isAdding: false,
                addError: action.error,    // error adding 
            };

        default:
            return state
    }
}