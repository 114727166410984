//import React, { useEffect, useState } from 'react';
import React from 'react';
import { SurveyBuilderHDB } from '.';
import { SurveyBuilderHDC } from '.';

// props: survey <survey object>, surveyIndex: survey index in redux or < 0 for no selection

export function SurveyBuilderBase(props)
{
    // return no component (undefined) if props.survey is null (no selection)
    if (props.survey == null) { return undefined; }
    // return survey builder component by SType
    switch (props.survey?.SType) {
        case 'HDB':
            return (<SurveyBuilderHDB {...props} />);
        case 'HDC':
            return (<SurveyBuilderHDC {...props} />);
        default:
            break;
    }
    // default component (error message style)
    return (
        <React.Fragment>
            <p>SurveyBuilderBase: Invalid SType: '{props?.SType}'.</p>
            <pre style={{fontSize: 10}}>{JSON.stringify(props, null, " ")}</pre>
        </React.Fragment>
    );
}