// input: h as an angle in [0,360] and s,l in [0,1] 
// output: r,g,b in [0,1]
export function hsl2rgb(h,s,l) 
{
   let a=s*Math.min(l,1-l);
   let f= (n,k=(n+h/30)%12) => l - a*Math.max(Math.min(k-3,9-k,1),-1);
   return [f(0),f(8),f(4)];
}

// input: r,g,b in [0,1]
// output: h as an angle in [0,360] and s,l in [0,1] 
export function rgb2hsl(r,g,b) {
    let a=Math.max(r,g,b), n=a-Math.min(r,g,b), f=(1-Math.abs(a+a-n-1)); 
    let h= n && ((a==r) ? (g-b)/n : ((a==g) ? 2+(b-r)/n : 4+(r-g)/n)); 
    return [60*(h<0?h+6:h), f ? n/f : 0, (a+a-n)/2];
}

// r,g,b are in [0-1], result e.g. #0812fa.
export function rgb2hex(r,g,b) {
    return "#" + [r,g,b].map( x => Math.floor(x*256).toString(16).padStart(2,0) ).join('');
}