import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { MissionActions } from '../_actions';
import { SurveyActions } from '../_actions';
import { assActions } from '../_actions';
import {
    Button,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
} from '@mui/material';

// mission picker dialog
import { MissionPicker } from '../Dialogs/MissionPicker';

import Image from 'mui-image';
import { ProtectedImage } from '../_components/ProtectedImage';

// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';

function MissionPhotosPage() {
    const users = useSelector(state => state.users);
    const user = useSelector(state => state.authentication.user);
    const Surveys = useSelector(state => state.Surveys);
    const Mission = useSelector(state => state.Missions);
    const asurvset = useSelector(state => state.adminSurveySettings);
    const dispatch = useDispatch();

    // refresh redux things from api when this page is navigated to (mounted)
    useEffect(() => {
        dispatch(userActions.getAll());
        dispatch(MissionActions.getAll(user));
        dispatch(assActions.getAll());
    }, []);

    // Begin dialog code
    const [isMissionPick, setIsMissionPick] = React.useState('closed');
    //const [userEditorId, setUEID] = React.useState('');

    const handleOpenMissionPicker = () => {
        //console.log("setueid=" + idToEdit);
        //setUEID(idToEdit);
        setIsMissionPick('open');
    };

    const handleCloseMissionPicker = () => {
        setIsMissionPick('closing');
        setTimeout(() => {
            setIsMissionPick('closed');
            dispatch(userActions.getAll());
        }, 1000); // This allows dialog closing animation to happen.
    };
    // end dialog code

    const [missionId, setMissionId] = useState(1); // js warning for using null here, undefined was recommended.
    const [loadedPhotos, setLoadedPhotos] = useState(0);

    const myPhotos = (loadedPhotos > 0) ? Surveys?.photos?.slice(0, loadedPhotos) : [];

    return (
        <div>
            <h3>Mission Photos (All)</h3>
            {/* Combining react-router link and materialui button */}

            <TextField
                id="filled-multiline-static"
                label="Mission ID"
                // multiline
                // fullWidth
                // rows={8}
                variant="filled"
                value={missionId}
                onChange={(e) => { setMissionId(e.target.value); /*console.log(e.target.value);*/ }}
            />
            &nbsp;
            <Button
                variant="outlined"
                color={'success'}
                //disabled={isAdding}
                onClick={() => {
                    dispatch(SurveyActions.downMissionPhotos(missionId, user));
                    setLoadedPhotos(1);
                }}
            >Load</Button>
            &nbsp;
            &nbsp;
            <Button
                variant='outlined'
                color='primary'
                disabled={!(Mission?.isLoaded && asurvset.isLoaded)}
                onClick={() => {
                    //dispatch(SurveyActions.downMissionPhotos(missionId, user));
                    handleOpenMissionPicker();
                }}
            >Pick Mission</Button>
            &nbsp;

            <p>Photos ({myPhotos?.length}/{Surveys?.photos?.length})</p>
            <Box display="flex"
                sx={{ flex: 1, padding: 1, width: '100%', minWidth: '100%', height: 'auto', flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                {myPhotos.map((p, i) => (
                    <Box sx={{ width: 280, height: 320, margin: 1, }} key={p.missionId + '_b' + i}>
                        <ProtectedImage
                            sx={{ maxWidth: 280, maxHeight: 320, }}
                            key={p.missionId + '_p' + i}
                            token={user?.token}
                            onLoad={() => { setLoadedPhotos(loadedPhotos + 1); console.log(`setLoadedPhotos(${loadedPhotos + 1})`) }}
                            //showLoading
                            //shift="bottom" distance={16}
                            src={`${Config.apiUrl}/api/SurveyPhoto/DownloadFile/${p.missionId}/${p.name}`}
                        />
                    </Box>
                ))}
            </Box>

            <p>Stores: {asurvset?.HDStore?.length}</p>
            <p>{JSON.stringify(asurvset?.HDStore?.[0])}</p>

            {Mission?.isLoaded === true && (isMissionPick === 'open' || isMissionPick === 'closing') && (
                <MissionPicker
                    open={(isMissionPick === 'open')}
                    Mission={Mission}
                    // onReload={() => { dispatch(userActions.getAll()); }} 
                    // userID={userEditorId} 
                    //user={users?.items?.find(u => (u?.id === userEditorId))}
                    //appPermissions={reduxPerms}
                    onClose={handleCloseMissionPicker}
                    ariaLabelledBy="form-dialog-title"
                    onPick={(id) => { console.log(`Mission picker: (${id}).`)}}
                />
            )}
        </div>
    );
}

export { MissionPhotosPage };