import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { SurveyActions } from '../_actions';
import {
    Button,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    Text,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Divider,
    Slide,      // For Transition (sliding in full page modal dialog)
    Chip,
    FormControlLabel,
    Switch,
} from '@mui/material';
// material icons!
import CloseIcon from '@mui/icons-material/Close';
// slide in full screen dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

import {
    HDASS, HDArticle, HDSteelType, HDDept, HDBay, HDSeismic, HDBayAisle, hdGetMyQuestions, hdbtFindBayType, HDBayType,
} from '../_appdata';
// for colors!
import { hsl2rgb, rgb2hex } from '../_helpers';

import { KForm } from '../AdminSurveySettingPage/KForm';

import Image from 'mui-image';
import { ProtectedImage } from '../_components/ProtectedImage';

import { availableSurveySettings } from '../_helpers/AdminSurveySettingData';

// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';
// APPDATA going away!! AdminSurveySettings coming to stay!
import { assActions } from '../_actions';

function arrayMove(arr, fromIndex, toIndex) {
    let newArr = [...arr];
    var element = newArr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
}

// doCompare checks two arrays of strings, true if equivalent, false if not!
function doCompare(a, b) {
    const aLen = a?.length || -1, bLen = b?.length || -1;
    if (aLen !== bLen) { return false; }
    for (let i = 0; i < aLen; i++) {
        if (a[i] !== b[i]) { return false; }
    }
    return true; // yay!
}

const randColor = () => {
    const h = Math.random() * 360,
        s = Math.random() * 0.5 + 0.5, l = Math.random() * 0.25 + 0.3;

    const [r, g, b] = hsl2rgb(h, s, l);
    const accentColor = rgb2hex(r, g, b);

    return accentColor;
}

function DDMaterial(props) {
    // careful of capitalization for props etc...!
    // TODO! visibility check, viewAll, etc.
    const [myArticle] = useState(props.asurvset.HDArticle.find(a => a?.Article === props.article));
    // did we find the article in adminSurveySettings? let's do it!
    if (myArticle) {
        // visibility check
        const myColor = new Set(myArticle?.Color?.toLowerCase().split('|')),
            mySteel = new Set(myArticle?.Steel?.toLowerCase().split('|'));
        const myVisible = (myColor.has(props.visColor) || myColor.has('all')) &&
            (mySteel.has(props.visSteel) || mySteel.has('all'));
        // show outlined red if not visible but show hidden dropdown items!
        if (!myVisible) {
            if (props?.allDropdown) {
                return (<>
                    <Box
                        sx={{ margin: '2px', py: '2px', px: 1, border: 1, borderColor: '#900', color: '#900', borderRadius: 1, }}
                    >{myArticle.Article + ' - ' + myArticle.Description}<br />
                        {`[${props.visColor} ? ${[...myColor].join(', ')}], [${props.visSteel} ? ${[...mySteel].join(', ')}]`}
                    </Box>
                </>);
            }
            return null;  // for a react component to render nothing, must return null.            
        }
        // show normally if visible
        return (<>
            <Box
                sx={{ margin: '2px', py: '2px', px: 1, border: 1, borderColor: '#999', borderRadius: 1, }}
            >{myArticle.Article + ' - ' + myArticle.Description}</Box>
        </>);
    }

    // fallback - failed to find article (invalid string or missing CAN#)
    return (
        <Box >{props.article}</Box>
    );
}

function BQButton(props) {

    return (
        <Button
            variant={props.clicked ? 'contained' : 'outlined'}
            sx={props.sx}
            color={props.color == 'red' ? 'primary' : 'secondary'}
            //disabled={asurvset?.isLoading}
            onClick={() => {
                console.log(`Clicked branch [${props.qid}]: '${props.label}': ${props.QIDS}.`);
                props.onAnswer(props.qid, props.QIDS);
                //dispatch(assActions.getAll(auth?.user));
                //setInitialV(false);
                //setEditorOpen(true);
                //openTheEditor('SUR');
            }}
        >{props.label}</Button>
    );
}
/*
function SaveSurvey(values) {
    // object destructuring extracts our prop that maps to sSubClass,
    // and the rest of the object copies to myPayload.
    const { [sSubClass]: mySSubClass, ...myPayload } = values;
    // and thus our form values get mapped to myAss, shaped to our database structure.

    const myAss = {
        "sClass": viewSetting,
        "sSubClass": mySSubClass,
        "payload": myPayload,
    };
    //alert(JSON.stringify(myAss, null, 2));
    dispatch(assActions.create(myAss));
}
*/

function BrQuParse(data) {
    let retVal = [];
    data?.split('|').map(d => {
        const dItem = d.split(',');
        if (dItem.length >= 2) {
            retVal.push({ label: dItem[0], color: dItem[1], QIDS: dItem.slice(2) });
        }
    });
    return retVal;
}

function SQRoot(props) {
    // create a static style with a random sidebar color to assist scrolling
    const myInnerContainer = {
        margin: 0, padding: '8px', borderStyle: 'solid', borderColor: props.color,
        borderLeftWidth: '8px', borderRightWidth: '0px',
        borderTopWidth: '0px', borderBottomWidth: '0px',
    };

    if (props?.visibility !== 'v') return null; // for a react component to render nothing, must return null.

    //const myTopBorder = props?.isInsertButton ? 1 : (props.index == 0 ? 1 : 0);

    // Controls (appearing below question text, info, etc.)
    // In a standard question, (Qtype==1), this will be the materials dropdown.
    // In a branching q, (QType == 4), this will be the buttons for the question branch...
    let myControls = null;
    switch (props?.question?.QType) {
        case 1:
            myControls =
                <Box>
                    <Button
                        onClick={() => props.onToggleDD(props?.qid)}
                    >Dropdown</Button>
                    {props?.isDropDown && (<>
                        <br />
                        {props?.question?.DropDown.split('|').map((dd, i) => (
                            <DDMaterial
                                key={`dd_${dd}_${i}`}
                                visSteel={props.visSteel}
                                visColor={props.visColor}
                                visDims={props.visDims}
                                allDropdown={props.allDropdown}    // show dropdown items even when they fail visibility check
                                index={i}
                                article={dd}
                                asurvset={props.asurvset}

                            />
                        )) || '???'}
                    </>)}
                </Box>;
            break;
        case 4:
            // parse branching question buttons
            const myButtons = BrQuParse(props?.question?.DropDown);
            myControls =
                <Box
                    sx={{ py: 1, marginTop: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', }}
                >
                    {/*<Button
                        onClick={() => props.onToggleDD(props?.qid)}
                        >Dropdown</Button>*/}
                    {/* <p>Branching Question</p> */}
                    {/* Buttons for branches of the BQ */}
                    {/* <p key={`pbtn_${dd}_${i}`}>{`[${i}]: "${JSON.stringify(dd)}"`}</p> */}
                    {myButtons.map((dd, i) => {
                        const isClicked = doCompare(dd.QIDS, props.answer);
                        return (
                            <BQButton
                                key={`btn_${dd}_${i}`}
                                //sx={{ px: 1, mx: 1 }}
                                index={i}
                                qid={props.qid}
                                clicked={isClicked}
                                answer={props.answer}
                                onAnswer={props.onAnswer}
                                {...dd} // spread in dd props, i.e. label, color, QIDS
                            />
                        );
                    })}
                </Box>;
            break;
    }

    return (
        <>
            {props?.isInsertButton && <Box sx={{ my: 1 }}>
                <Button
                    disabled={props.qidIndex < 0} // invalid action for generated QID i.e. branching q!
                    onClick={() => props.onInsert(props.index)}
                    variant="outlined"
                >Insert Question Here</Button>
            </Box>}

            <Box sx={{
                border: 1, padding: 0, margin: 0, //borderTop: myTopBorder
                backgroundColor: props.index % 2 ? '#eaeaea' : '#fafafa',
            }}>
                <Box sx={myInnerContainer}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            {props?.qid || '???'} ({props?.question?.QType})
                        </Box>
                        <Box>
                            <Button
                                onClick={() => props.onEdit()}
                                color='success'
                            >Edit</Button>
                        </Box>
                        <Box>
                            <Button
                                disabled={props.qidIndex < 0} // invalid action for generated QID i.e. branching q!
                                onClick={() => props.onDel()}
                                color='error'
                            >Delete</Button>
                        </Box>
                        <Box>
                            <Button
                                disabled={props.qidIndex < 1}
                                onClick={() => props.onUp()}
                            >Up</Button>
                            <Button
                                disabled={props.qidIndex < 0 || props.qidIndex + 1 >= props.length}
                                onClick={() => props.onDn()}
                            >Down</Button>
                        </Box>
                    </Box>
                    <Box sx={{ fontWeight: 'bold', marginBottom: 1, borderBottom: 1, borderBottomColor: '#aaa' }}>
                        {props?.question?.Category || '???'}
                    </Box>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                        {props?.question?.QText || '???'}
                    </Box>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                        {props?.question?.QInfo || ''}
                    </Box>
                    {/* Materials DropDown */}
                    {myControls}
                </Box>

            </Box>
        </>
    );
}

function SQRootPick(props) {
    return (
        <>
            <Box sx={{
                border: 1, padding: 1, margin: 0,
                backgroundColor: props.index % 2 ? '#eaeaea' : '#fafafa',
            }}>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        disabled={props.inSurvey >= 0}
                        onClick={() => props.onInsert()}
                    >
                        {props?.question?.QID || '???'}
                    </Button>
                    {props.inSurvey >= 0 && <span style={{ color: '#900', fontWeight: 'bold' }}>This question is already in the survey. [{(props.inSurvey + 1)}]</span>}
                </Box>
                <Box sx={{ fontWeight: 'bold', marginBottom: 1, borderBottom: 1, borderBottomColor: '#aaa' }}>
                    {props?.question?.Category || '???'}
                </Box>
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                    {props?.question?.QText || '???'}
                </Box>
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                    {props?.question?.QInfo || '???'}
                </Box>
            </Box>
        </>
    );
}

function SurveyBuilderHDB(props) {
    //const users = useSelector(state => state.users);
    const auth = useSelector(state => state.authentication);
    const Surveys = useSelector(state => state.Surveys);
    const asurvset = useSelector(state => state.adminSurveySettings);
    const dispatch = useDispatch();

    // visibility: seismic 's' or non-seismic 'n' 
    const [viewSeismic, setViewSeismic] = useState('n');
    // visibility: brand 'nas', 'bon' or 'arp'
    const [viewBrand, setViewBrand] = useState('nas');
    // visibility bayType: regular steel orange 'ro', regular steel white 'rw', galvanized 'g', or cantilever run 'c'
    const [viewBayType, setViewBayType] = useState('ro');

    const [myQIDS, setMyQIDS] = useState(props.survey?.Data?.QIDS?.split('|')
        || props.survey?.Data?.split('|')
        || []);

    const [myVisQIDS, setMyVisQIDS] = useState([]);
    const [myQuestions, setMyQuestions] = useState({});
    const [myQuestionC, setMyQuestionC] = useState({}); // myqCOLOR, object with question id as key and hex color string as value
    const [myQuestionV, setMyQuestionV] = useState({}); // myqVISBILITY, object with question id as key and hex color string as value
    const [myQuestionI, setMyQuestionI] = useState({}); // myqINDEX, object with question id as key and source index in myQIDS (used to back-reference from myVisQIDS)
    const [myQuestionDD, setMyQuestionDD] = useState({}); // myqVISBILITY, object with question id as key and hex color string as value

    const [myAnswers, setMyAnswers] = useState({});
    const [missedAnswers, setMissedAnswers] = useState([]);

    // when we pop up an editor for the survey or question, 'SUR', 'SQU', etc.
    const [viewSetting, setViewSetting] = useState('');
    // the object containing the data 
    //const mySurveySetting = availableSurveySettings.find(ass => ass.sClass === viewSetting);
    // are we editing an existing survey / question? Or creating a new one?
    const [mySelected, setMySelected] = useState(null);
    const [initialV, setInitialV] = useState(false);    // initialV = we are supplying values to edit?
    // mySurveySetting = the data object for the adminSurveySetting we are editing when initialV=true
    const [mySurveySetting, setMySurveySetting] = useState(null);
    // Dialog for editing a qustion (or survey?)
    const [editorOpen, setEditorOpen] = React.useState(false);
    const handleEditorClose = (refreshMe = false) => {
        setEditorOpen(false);
        // auto refresh! when we close the editor, reload stuff!
        if (refreshMe) { // wait 300ms to prevent screwing up the form.
            setTimeout(() => { dispatch(assActions.getAll()); }, 300);
        }
    };

    // Dialog to add a question from the questions!
    const [qpOpen, setQPOpen] = React.useState(false);
    const [qpIndex, setQPIndex] = React.useState(-1);
    const handleQuestionPickerOpen = (index) => {
        setQPIndex(index);
        setQPOpen(true);
    };
    const handleQuestionPickerClose = () => {
        setQPOpen(false);
    };

    const [missionId, setMissionId] = useState(1); // js warning for using null here, undefined was recommended.
    const [loadedPhotos, setLoadedPhotos] = useState(0);
    const [isQButtons, setIsQButtons] = useState(false);
    const [isAllQuestions, setIsAllQuestions] = useState(false);
    const [isAllDropDowns, setIsAllDropDowns] = useState(false);

    //const mySurvey = { ...props.survey, QIDS: myQIDS.join('|') };

    function handleSetAnswer(setQID, answer) {
        setMyAnswers((curAnswers) => {
            console.log(`set answer: [${setQID}]: "${answer}".`);
            return { ...curAnswers, [setQID]: answer };
        });
    }

    function openTheEditor(assType, initialValues = null, options = null) {
        // find mySurveySetting
        const mySS = availableSurveySettings.find(ass => ass.sClass === assType);
        // proceed only if surveySetting is valid!
        if (mySS) {
            setViewSetting(assType); // i.e. 'SUR' or 'SQU' ...
            setMySurveySetting(mySS); // editor fields 
            if (initialValues && typeof (initialValues) === 'object') {
                // initialV boolean = ARE we using a set on initialValues for the editor popup? if false, adminSurveySettings defines defaults
                setInitialV(true);
                // mySelected = the initialValues we populate our form with
                setMySelected({ ...initialValues });
                console.log(`openTheEditor: opening "${assType}", with initialValues (edit).`);
                console.log(initialValues);
            } else {
                setInitialV(false);
                setMySelected(null);
                console.warn(`openTheEditor: opening "${assType}", no initialValues (add new).`);
            }
            // finally, open the editor with our initial values or blank default values.
            setEditorOpen(true);
        } else {
            console.warn(`openTheEditor: failed to find SSType "${assType}"!`)
        }
    }
    function qVisTest(myVis, qVis) {
        const qqVis = new Set(qVis.split('|'));
        // NON-SEISMIC regular steel (orange or white)
        if (myVis == 'n_ro' || myVis == 'n_rw') {
            if (qqVis.has('n_r')) return 'v';
            return 'x';
        }
        // galvanized
        if (myVis == 'n_g') {
            if (qqVis.has('n_g')) return 'v';
            return 'x';
        }
        // cantilever
        if (myVis == 'n_c') {
            if (qqVis.has('n_c')) return 'v';
            return 'x';
        }
        // SEISMIC regular steel (orange or white)
        if (myVis == 's_ro' || myVis == 's_rw') {
            if (qqVis.has('s_r')) return 'v';
            return 'x';
        }
        // galvanized
        if (myVis == 's_g') {
            if (qqVis.has('s_g')) return 'v';
            return 'x';
        }
        // cantilever
        if (myVis == 's_c') {
            if (qqVis.has('s_c')) return 'v';
            return 'x';
        }
        console.log(`qVisTest Error! myVis="${myVis}", qVis="${qVis}"!`);
        return 'e'; // error
    }
    function doQuestions() {
        // myVisibility (whether survey is seismic or non, and baytype)
        const myVis = `${viewSeismic}_${viewBayType}`;

        console.log(`doQuestions: myVis="${myVis}".`);
        let newQIDS = [], newQIDSVis = [], errorQIDS = [],
            qObj = {}, qDrop = myQuestionDD, qVis = {}, qIndex = {},
            qColor = myQuestionC; // keep random colors for questions (prevents colors changing)
        //console.log('qColor before:', qColor);

        // recursive fun to add questions inside BQ
        function addQue(qid) {
            let myQ = asurvset?.Question?.find(q => q.QID === qid);
            if (myQ) {
                // if qid is already in qIndex we have the same question twice! uh oh!
                if (qid in qIndex) {
                    alert(`hit same question twice: '${qid}'!`)
                } else {
                    //newQIDS.push(qid); // found the q! add it to our QID list.
                    qObj[qid] = myQ; // build the object of questions! key: id, value: question-object as per asurvset.Question
                    // test the visibilty of our question by bay type, etc.                    
                    /*
                    let myQVIS = isAllQuestions
                        ? 'v'   // forced visible!
                        : qVisTest(myVis, myQ.QVis); // build the object of question VISIBILITY!
                     */
                    let myQVIS = 'v'; // in branchine questions we force visible regardless, this may be optional later
                    qVis[qid] = myQVIS;
                    if (myQVIS == 'v') { newQIDSVis.push(qid); } // myVisQIDS is the same as myQIDS but ONLY contains the visible QIDS, and ALSO adds QIDS from branching Q's
                    if (!(qid in qDrop)) { qDrop[qid] = true; } // drop down open or closed?
                    if (!(qid in qColor)) { qColor[qid] = randColor(); } // console.log(!"key" in obj);   // Do not do this! It is equivalent to "false in obj"                
                    // NEW! stage 2! Branching questions (also why newQIDSVis had to be added)
                    if (myQ.QType == 4) {
                        const injectQIDS = myAnswers?.[qid] || null;
                        console.log(`Branching Question [${qid}], inject QIDS: [${injectQIDS}] (length: ${injectQIDS?.length}).`);
                        if (injectQIDS) { // this if guards against type error
                            injectQIDS.map(iQID => {
                                addQue(iQID); // branching q inject QIDS
                            });
                        }
                    }
                    qIndex[qid] = -1; // index to reference myVisQIDS back to myQIDS always -1 in BQ (invalid)
                }
            } else {
                errorQIDS.push(qid) // no q! add it to our error QID list.
                console.log(`FAILED: find question [${qid}].`);
            }
        }
        // stage 1: generate myQIDS and myVisQIDS.
        // branching questions add to myVisQIDS but do NOT alter myQIDS.
        // if questions appear more than once, note the error.
        myQIDS.map((qid, index) => {
            let myQ = asurvset?.Question?.find(q => q.QID === qid);
            if (myQ) {
                // if qid is already in qIndex we have the same question twice! uh oh!
                if (qid in qIndex) {
                    alert(`hit same question twice: '${qid}'!`)
                } else {
                    newQIDS.push(qid); // found the q! add it to our QID list.
                    qObj[qid] = myQ; // build the object of questions! key: id, value: question-object as per asurvset.Question
                    // test the visibilty of our question by bay type, etc.
                    let myQVIS = isAllQuestions
                        ? 'v'   // forced visible!
                        : qVisTest(myVis, myQ.QVis); // build the object of question VISIBILITY!
                    qVis[qid] = myQVIS;
                    if (myQVIS == 'v') { newQIDSVis.push(qid); } // myVisQIDS is the same as myQIDS but ONLY contains the visible QIDS, and ALSO adds QIDS from branching Q's
                    if (!(qid in qDrop)) { qDrop[qid] = true; } // drop down open or closed?
                    if (!(qid in qColor)) { qColor[qid] = randColor(); } // console.log(!"key" in obj);   // Do not do this! It is equivalent to "false in obj"                
                    // NEW! stage 2! Branching questions (also why newQIDSVis had to be added)
                    if (myQ.QType == 4) {
                        const injectQIDS = myAnswers?.[qid] || null;
                        console.log(`Branching Question [${qid}], inject QIDS: [${injectQIDS}] (length: ${injectQIDS?.length}).`);
                        if (injectQIDS) { // this if guards against type error
                            injectQIDS.map(iQID => {
                                //newQIDSVis.push(iQID); // branching q inject QIDS
                                addQue(iQID);
                            });
                        }
                    }
                    qIndex[qid] = index; // store index to reference myVisQIDS back to myQIDS
                }
            } else {
                errorQIDS.push(qid) // no q! add it to our error QID list.
                console.log(`FAILED: find question [${qid}].`);
            }
        });

        if (!doCompare(myQIDS, newQIDS)) {
            console.log('doCompare: myQIDS / newQIDS mismatch! (missing / invalid question?!)', 'myQIDS:', myQIDS, 'newQIDS:', newQIDS);
            setMyQIDS(newQIDS);
        } else {
            //console.log('doCompare: myQIDS / newQIDS match!');
        }
        setMyVisQIDS(newQIDSVis);
        //console.log('qOBJ!');
        //console.log(qObj);
        setMyQuestions(qObj);
        setMyQuestionV(qVis);
        setMyQuestionC(qColor);
        setMyQuestionDD(qDrop);
        setMyQuestionI(qIndex);
        //console.log('qColor after:', qColor);
    }

    function questionInsert(atIndex, QID) {
        let newQIDS = [...myQIDS];
        console.log(`qids before: "${newQIDS}".`);
        if (atIndex >= 0) {
            console.log(`Insert question "${QID}" at [${atIndex}].`);
            // array.splice has many uses, it can insert an item into an array at an index!
            newQIDS.splice(atIndex, 0, QID);
        } else {
            console.log(`Append question "${QID}".`);
            newQIDS.push(QID);
        }
        console.log(`qids after: "${newQIDS}".`);
        // after changes, cannot simply set QIDS, must call doQuestions to refresh...
        setMyQIDS(newQIDS); // <- WRONG!
        //doQuestions(newQIDS);
    }

    function handleToggleDD(id) {
        const myResult = myQuestionDD?.[id] ? false : true; // a boolean cast but inverted!
        console.log(`Toggle dropDown[${id}]: ${myResult}.`);
        setMyQuestionDD({ ...myQuestionDD, [id]: myResult });
    }

    function setAllDD(isOpen) {
        const setVal = isOpen ? true : false; // a boolean cast 
        const newDD = { ...myQuestionDD };
        myQIDS.forEach((myQID) => {
            newDD[myQID] = setVal;
        });
        console.log('NewDropDowns: ', newDD)
        setMyQuestionDD(newDD);
    }

    // recalculate myQuestions if needed: TODO add visibility setting to this etc.
    useEffect(() => {
        /*
        if (myQIDS.length < 1 && !(props.survey?.Data?.QIDS?.length < 1)) {
            const butt = props.survey?.Data?.QIDS?.split('|')
                || props.survey?.Data?.split('|')
                || [];
            doQuestions(butt);
        } else {
            doQuestions(myQIDS);
        }
        */
        doQuestions(myQIDS);
    }, [myQIDS, viewSeismic, viewBayType, isAllQuestions, myAnswers]);

    /*
    useEffect(() => {
        if (props.survey?.Data) {
            doQuestions(props.survey.Data.split('|'));
        } else {
            doQuestions([]);
        }
    }, [props.survey, viewSeismic, viewBayType, isAllQuestions]);
*/

    const myQIDSLen = myQIDS?.length || 0;

    // undo / redo stuff!
    const [isChanges, setIsChanges] = React.useState(false); // set true when you do a thing. this means we made changes to SAVE.
    const [myUndo, setMyUndo] = React.useState([]); // push onto this when you do a thing
    const [myRedo, setMyRedo] = React.useState([]); // push onto this when you UNDO a thing
    function resetUndo() {
        setMyUndo([]);
        setMyRedo([]);
    }
    function addUndo(myState) {  // call this and save state before you do a thing!
        console.log(`Add undo: (${myUndo.length}).`);
        //console.log(myUndo);
        setMyUndo([...myUndo, myState]);
        setMyRedo([]); // doing a thing blanks out redo (because we changed the timeline)
    }
    function doUndo(myState) {
        const newState = myUndo.slice(-1)[0];
        setMyRedo([...myRedo, myState]);
        setMyUndo([...myUndo].slice(0, -1));
        return newState; // use this like: if (myUndo.length > 0) { theState = doUndo(); }
    }
    function doRedo(myState) {
        const newState = myRedo.slice(-1)[0];
        setMyUndo([...myUndo, myState]);
        setMyRedo([...myRedo].slice(0, -1));
        return newState; // use this like: if (myRedo.length > 0) { theState = doRedo(); }
    }

    // Save / undo / redo buttons (put in a flexbox)
    const SaveButton = (<>
        <Button
            variant="outlined"  // contained
            color={'success'}
            disabled={asurvset?.isLoading}
            onClick={() => {
                console.log("Save survey clicked.");
                //dispatch(assActions.getAll(auth?.user));
                //dispatch(SurveyActions.downMissionPhotos(missionId, user));
                //setLoadedPhotos(1);
                openTheEditor('SUR', { ...props.survey, Data: { QIDS: myQIDS.join('|') } }); // { ...props.survey, QIDS: myQIDS.join('|') }
            }}
        >Save Survey</Button>
        <Box>
            <Button
                sx={{ mx: 2 }}
                variant="outlined"  // contained
                color={'primary'}
                disabled={!(myUndo.length > 0)}
                onClick={() => {
                    //console.log(`Do Undo (${myUndo.length}), QIDS="${myQIDS}".`);
                    //doQuestions(doUndo(myQIDS));
                    setMyQIDS(doUndo(myQIDS));
                }}
            >Undo</Button>
            <Button
                variant="outlined"  // contained
                color={'primary'}
                disabled={!(myRedo.length > 0)}
                onClick={() => {
                    //console.log(`Do Redo (${myRedo.length}), QIDS="${myQIDS}".`);
                    setMyQIDS(doRedo(myQIDS));
                }}
            >Redo</Button>
        </Box>
    </>
    );

    const sSubClass = mySurveySetting?.sSubClassProp; // the property in payload that maps to sSubClass

    const editorTitle = initialV ? `Editing ${mySurveySetting?.label}: ${mySelected?.Name} (id: ${mySelected?.id || 0})` : `Adding New: ${mySurveySetting?.label}`;
    const EditorAppBar = (props) => {
        let myPos = 'sticky', mySx = undefined;
        if (props?.bottom) { myPos = 'fixed', mySx = { top: 'auto', bottom: 0 }; }
        return (<>
            {props?.bottom && <div style={{ height: 90 }} />}
            <AppBar position={myPos} sx={mySx} /*sx={{ position: 'relative' }}*/ >
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {props?.title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleEditorClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>)
    };

    // get steel color for dropdowns: org, why, gal, idk
    const viewColor = viewBayType == 'ro' ? 'org'
        : (viewBayType == 'rw' ? 'wht' : (viewBayType == 'g' ? 'gal' : 'idk'));

    // dropdown filter: steel (nas, bon arp), color (org, wht, gal), w (width), h (height), d (depth)
    let myDDFilter = {};

    return (
        <div>
            {/* <pre>{JSON.stringify(props, null, ' ')}</pre> */}

            {/* survey body */}
            {props.surveyIndex !== '' && <>
                <Box><h3>{props.survey?.Name}</h3></Box>

                <Box sx={{ border: 1, borderRadius: 1, borderColor: '#999', px: 1, my: 1, }}>
                    <p>Steel Brand:{' '}
                        <Button
                            color='secondary'
                            variant={viewBrand == 'nas' ? 'contained' : 'outlined'}
                            onClick={() => { setViewBrand('nas'); setViewSeismic('n'); }}
                        >NAS</Button>{' '}
                        <Button
                            color='secondary'
                            variant={viewBrand == 'bon' ? 'contained' : 'outlined'}
                            onClick={() => { setViewBrand('bon'); setViewSeismic('n'); }}
                        >Boni</Button>{' '}
                        <Button
                            color='secondary'
                            variant={viewBrand == 'arp' ? 'contained' : 'outlined'}
                            onClick={() => { setViewBrand('arp'); setViewSeismic('s'); }}
                        >Arpac</Button>
                        <span style={{ paddingLeft: 32 }}>{viewSeismic == 's' ? 'Seismic' : 'Non-Seismic'}</span>
                    </p>
                    <p>Bay Type:{' '}
                        <Button
                            //color={}
                            variant={viewBayType == 'ro' ? 'contained' : 'outlined'}
                            onClick={() => { console.log(`Set bayType: Regular Orange.`); setViewBayType('ro'); }}
                        >Regular Steel Orange</Button>{' '}
                        <Button
                            //color={}
                            variant={viewBayType == 'rw' ? 'contained' : 'outlined'}
                            onClick={() => { console.log(`Set bayType: Regular White.`); setViewBayType('rw'); }}
                        >Regular Steel White</Button>{' '}
                        <Button
                            //color={}
                            variant={viewBayType == 'g' ? 'contained' : 'outlined'}
                            onClick={() => { console.log(`Set bayType: Galvanized.`); setViewBayType('g'); }}
                        >Galvanized</Button>{' '}
                        <Button
                            //color={}
                            variant={viewBayType == 'c' ? 'contained' : 'outlined'}
                            onClick={() => { console.log(`Set bayType: Cantilever.`); setViewBayType('c'); }}
                        >Cantilever</Button>
                    </p>
                    <Box>
                        <FormControlLabel
                            control={<Switch
                                checked={isQButtons} inputProps={{ 'aria-label': 'controlled' }}
                                onChange={() => { setIsQButtons(!isQButtons) }}
                            />}
                            label="Insert Question Buttons"
                        />

                    </Box>
                    <Box>
                        <FormControlLabel
                            control={<Switch
                                checked={isAllQuestions} inputProps={{ 'aria-label': 'controlled' }}
                                onChange={() => { setIsAllQuestions(!isAllQuestions) }}
                            />}
                            label="Show Hidden Questions"
                        />

                    </Box>
                    <Box>
                        <FormControlLabel
                            control={<Switch
                                checked={isAllDropDowns} inputProps={{ 'aria-label': 'controlled' }}
                                onChange={() => { setIsAllDropDowns(!isAllDropDowns) }}
                            />}
                            label="Show Hidden Drop Down Items"
                        />

                    </Box>
                    <p>Dropdowns:{' '}
                        <Button
                            //color={}
                            variant={'contained'}
                            onClick={() => {
                                //console.log(`Dropdowns: Open all.`); 
                                setAllDD(true);
                            }}
                        >Open All</Button>{' '}
                        <Button
                            //color={}
                            variant={'contained'}
                            onClick={() => {
                                //console.log(`Dropdowns: Close all.`); 
                                setAllDD(false);
                            }}
                        >Close All</Button>{' '}
                    </p>

                </Box>

                <Box sx={{ border: 1, borderRadius: 1, borderColor: '#999', px: 1, my: 1, }}>
                    <p>Overview (Shows filtered questions)</p>
                    {myQIDS.map((q, i) =>
                        <Chip
                            key={`c_${q}_${i}`}
                            //qid={q} index={i} length={myQIDSLen}
                            //question={myQuestions?.[q]}
                            variant={myQuestionV?.[q] == 'v' ? 'filled' : 'outlined'}
                            sx={{ backgroundColor: myQuestionV?.[q] == 'v' ? myQuestionC?.[q] : undefined, mr: 1, mb: 1 }}
                            label={q}
                            title={`What up: ${q}`}
                        />)}
                </Box>

                <Box sx={{ border: 1, borderRadius: 1, borderColor: '#999', px: 1, my: 1, }}>
                    <p>Visible Survey ({myVisQIDS.length} questions)</p>
                    {myVisQIDS.map((q, i) =>
                        <Chip
                            key={`c_${q}_${i}`}
                            //qid={q} index={i} length={myQIDSLen}
                            //question={myQuestions?.[q]}
                            variant={myQuestionV?.[q] == 'v' ? 'filled' : 'outlined'}
                            sx={{ backgroundColor: myQuestionV?.[q] == 'v' ? myQuestionC?.[q] : undefined, mr: 1, mb: 1 }}
                            label={q}
                            title={`What up: ${q}`}
                        />)}
                </Box>

                <Box><h3>(HDB) Home Depot Bay Survey</h3></Box>
                <p>Undo and redo only affect changes to the survey, not edits to the questions!</p>
                <p>Warning! If you click the refresh button OR edit a question, your survey changes will be LOST! This is a bug I will fix soon and this message will be gone.</p>
                <Box sx={{ my: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >{SaveButton}</Box>

                {/*
                <pre style={{ fontSize: 12, color: '#f00' }}>{JSON.stringify(myUndo.length)}</pre>
                <pre style={{ fontSize: 12, color: '#0f0' }}>{JSON.stringify(myRedo.length)}</pre>
                */}

                {/*{myQIDS.map((q, i) => {*/}
                {myVisQIDS.map((q, i) => {
                    //console.log(`key={'sqr_${q}_${i}'}`);
                    const ii = myQuestionI?.[q] >= 0 ? myQuestionI?.[q] : -1;
                    return (<SQRoot
                        key={`sqr_${q}_${i}`}
                        qid={q} index={i} length={myQIDSLen}
                        question={myQuestions?.[q]}
                        answer={myAnswers?.[q]}         // answer to THIS question (remember to handle undefined as a value)
                        answers={myAnswers}             // answer to ALL questions in case this question needs to know other answers
                        color={myQuestionC?.[q]}        // accent color
                        qidIndex={ii}                   // reference to source index in myQIDS (for moving questions up and down in the survey) (is less than 0 if not valid i.e. branch question!)
                        visibility={myQuestionV?.[q]}   // question visibility i.e. 'v'
                        visSteel={viewBrand}            // steel brand 'arp', 'bon, 'nas'
                        visColor={viewColor}
                        visDims={{ w: 0, h: 0, d: 0 }}
                        allDropdown={isAllDropDowns}    // show dropdown items even when they fail visibility check
                        isDropDown={myQuestionDD?.[q]}  // is dropdown open?
                        ddFilter={myDDFilter}
                        asurvset={asurvset}
                        isInsertButton={isQButtons}
                        onEdit={() => {
                            console.log(`edit question "${q}".`);
                            console.log(myQuestions?.[q]);
                            openTheEditor('SQU', myQuestions[q]);  // survey!! with no initial values, hence, create a new thing.                            
                        }}
                        onUp={() => {
                            addUndo(myQIDS); // add question state to undo buffer
                            setMyQIDS(arrayMove(myQIDS, ii, Math.max(ii - 1, 0)));
                        }}
                        onDn={() => {
                            addUndo(myQIDS);
                            setMyQIDS(arrayMove(myQIDS, ii, Math.min(ii + 1, myQIDSLen - 1)));
                        }}
                        onDel={() => {
                            addUndo(myQIDS);
                            let newQIDS = [...myQIDS]; newQIDS.splice(ii, 1); setMyQIDS(newQIDS);
                        }}
                        onInsert={() => {
                            addUndo(myQIDS);
                            console.log(`Insert question at index [${ii}].`);
                            handleQuestionPickerOpen(ii);
                        }}
                        onToggleDD={handleToggleDD}
                        onAnswer={handleSetAnswer}
                    />);
                })}

                <Box sx={{ my: 1 }}>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            console.log(`Add question at the end.`);
                            handleQuestionPickerOpen(-1); // index -1 = add at the end (like how .slice works)
                        }}
                    >Add Question</Button>
                </Box>

                <Box sx={{ my: 2, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >{SaveButton}</Box>

                <pre style={{ fontSize: 8 }}>{JSON.stringify()}</pre>
            </>}
            {/* show all settings
            <pre>{JSON.stringify(asurvset, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.Survey, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.Question, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.HDArticle, null, ' ')}</pre>
            */}

            {/* fullscreen popup to add / insert a new question (or survey?) */}
            <Dialog
                fullScreen
                open={editorOpen}
                onClose={handleEditorClose}
                TransitionComponent={Transition}
            >
                <EditorAppBar title={editorTitle} />
                <Box sx={{ height: 'auto', width: '100%', padding: 2, }}>
                    {/*mySurveySetting?.fields?.map((f,i) => (<p>{`${i}: ${JSON.stringify(f)}`}</p>))*/}
                    <KForm
                        initialValues={initialV ? mySelected : mySurveySetting?.addInitialValues}
                        validationSchema={mySurveySetting?.addValidation}
                        formFields={mySurveySetting?.addForm}
                        onSubmit={(values) => {
                            // object destructuring extracts our prop that maps to sSubClass,
                            // and the rest of the object copies to myPayload.
                            const { [sSubClass]: mySSubClass, ...myPayload } = values;
                            // and thus our form values get mapped to myAss, shaped to our database structure.
                            const myAss = {
                                "sClass": viewSetting,
                                "sSubClass": mySSubClass,
                                "payload": myPayload,
                            };
                            //alert(JSON.stringify(myAss, null, 2));
                            dispatch(assActions.create(myAss));
                        }}
                        onClose={handleEditorClose}
                    />
                    {/**<pre>{JSON.stringify(mySelected)}</pre>
                            <pre>{JSON.stringify(mySurveySetting)}</pre>*/}
                    {/**<EditorAppBar bottom />*/}
                </Box>
            </Dialog>

            {/* fullscreen popup to add / insert a new question */}
            {asurvset?.Question &&
                <Dialog
                    fullScreen
                    open={qpOpen}
                    onClose={handleQuestionPickerClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'sticky' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {qpIndex < 0 ? 'Add A Question' : `Insert Question [${qpIndex}]`}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleQuestionPickerClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    {asurvset.Question.map((q, i) =>
                        <SQRootPick
                            key={`pck_${q.QID}_${i}`}
                            index={i}
                            question={q}
                            inSurvey={myQIDS.findIndex(qq => qq === q.QID)}
                            //color={myQuestionC?.[q]}
                            asurvset={asurvset}
                            onInsert={() => {
                                questionInsert(qpIndex, q.QID);
                                handleQuestionPickerClose();
                            }}
                        />)}
                </Dialog>
            }
        </div>

    );
}


export { SurveyBuilderHDB };