export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    CREATE_DOIT: 'USERS_CREATE_DOIT',
    CREATE_SUCC: 'USERS_CREATE_SUCC',
    CREATE_FAIL: 'USERS_CREATE_FAIL',

    UPDATE_DOIT: 'USERS_UPDATE_DOIT',
    UPDATE_SUCC: 'USERS_UPDATE_SUCC',
    UPDATE_FAIL: 'USERS_UPDATE_FAIL',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',    

    SETPASS_REQUEST: 'USERS_SETPASS_REQUEST',
    SETPASS_SUCCESS: 'USERS_SETPASS_SUCCESS',
    SETPASS_FAILURE: 'USERS_SETPASS_FAILURE',
};
