import config from 'config';
import { authHeader } from '../_helpers';

export const appPermissionService = {
    getAll,
    getUserPerm,
    setUserPerm
};

// array in local storage for registered users (from fake backend)
//let users = JSON.parse(localStorage.getItem('users')) || [];

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/api/Permission`, requestOptions).then(handleResponse);

    // If we FAIL to get AppPermission array of objects from session storage, try to fetch from API
    if (sessionStorage.getItem(myKey) === null) {
        let AppPerms = fetch(`${config.apiUrl}/api/Permission`, requestOptions).then(handleResponse);
        // at this point we should have gotten our permissions, store in session as json.
        sessionStorage.setItem(myKey, JSON.stringify(AppPerms));
        // return the array of objects, our permissions!!
        return AppPerms;
    }
    // If it worked, return stored (cached) array, otherwise fetch from API and store for next call.
    return JSON.parse(sessionStorage.getItem(myKey));
}

function getUserPerm(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/Permission/${userId}`, requestOptions).then(handleResponse);
}

function setUserPerm(userId) {
    const requestOptions = {
        method: 'SET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/Permission/${userId}`, requestOptions).then(handleResponse);
}

// removed automatic logout if auth fails. not sure if it should be implemented here.
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

