export const HDStoreBAD = [
  {"Number":"0001","Name":"0001 - Testing And Training","City":"NOWHERESVILLE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7001","Name":"7001 - Ellesmere","City":"ELLESMERE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7002","Name":"7002 - Woodbridge","City":"WOODBRIDGE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7003","Name":"7003 - Oakville-Dundas","City":"OAKVILLE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7004","Name":"7004 - Markham West","City":"MARKHAM","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7005","Name":"7005 - Whitby","City":"WHITBY","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7006","Name":"7006 - Brampton South","City":"BRAMPTON","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7007","Name":"7007 - Ancaster","City":"ANCASTER","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7008","Name":"7008 - Hamilton","City":"HAMILTON","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7009","Name":"7009 - London North","City":"NORTH LONDON","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7010","Name":"7010 - Toronto District Office","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7011","Name":"7011 - Etobicoke South","City":"ETOBICOKE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7012","Name":"7012 - Curity","City":"CURITY","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7013","Name":"7013 - Stockyards","City":"STOCKYARDS","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7017","Name":"7017 - Kitchener","City":"KITCHENER","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7019","Name":"7019 - Mississauga Lumber DC","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7020","Name":"7020 - Ontario District Office","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7021","Name":"7021 - Burlington","City":"BURLINGTON","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7022","Name":"7022 - Sudbury","City":"SUDBURY","SteelType":"2","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7023","Name":"7023 - St Catherines","City":"ST. CATHARINES","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7024","Name":"7024 - Barrie","City":"BARRIE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7025","Name":"7025 - Gloucestor","City":"GLOUCESTER","SteelType":"2","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7026","Name":"7026 - Nepean","City":"NEPEAN","SteelType":"2","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7027","Name":"7027 - Morningside","City":"MORNINGSIDE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7029","Name":"7029 - Toronto Imports DC","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7030","Name":"7030 - Newmarket","City":"NEWMARKET","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7032","Name":"7032 - Kelowna","City":"KELOWNA","SteelType":"3","Seismic":"2","Market Name":"BC Interior "},
  {"Number":"7033","Name":"7033 - London","City":"LONDON S","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7034","Name":"7034 - Sault Ste Marie","City":"SAULT STE. MARIE","SteelType":"1","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7035","Name":"7035 - Park Royal","City":"PARK ROYAL","SteelType":"3","Seismic":"2","Market Name":"West Vancouver"},
  {"Number":"7036","Name":"7036 - Medicine Hat","City":"MEDICINE HAT","SteelType":"2","Seismic":"1","Market Name":"Red Deer"},
  {"Number":"7037","Name":"7037 - Calgary North Hills","City":"CALGARY NORTH HILLS","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7038","Name":"7038 - Brandon","City":"BRANDON","SteelType":"2","Seismic":"1","Market Name":"Manitoba South"},
  {"Number":"7040","Name":"7040 - Nanaimo","City":"NANAIMO","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7041","Name":"7041 - Langley","City":"LANGLEY","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7042","Name":"7042 - Vancouver","City":"TERMINAL","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7043","Name":"7043 - Richmond","City":"RICHMOND","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7044","Name":"7044 - South Surrey","City":"SOUTH SURREY","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7045","Name":"7045 - Coquitlam","City":"COQUITLAM","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7046","Name":"7046 - North Surrey","City":"NORTH SURREY","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7047","Name":"7047 - Burnaby","City":"BURNABY","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7050","Name":"7050 - Spruce Grove","City":"SPRUCE GROVE","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7051","Name":"7051 - Saskatoon","City":"SASKATOON","SteelType":"2","Seismic":"1","Market Name":"Saskatchewan"},
  {"Number":"7052","Name":"7052 - Regina","City":"REGINA","SteelType":"2","Seismic":"1","Market Name":"Saskatchewan"},
  {"Number":"7053","Name":"7053 - Squamish","City":"SQUAMISH","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7055","Name":"7055 - Saanich","City":"VICTORIA (SANICH)","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7056","Name":"7056 - Crossroads","City":"CROSSROADS","SteelType":"2","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"7057","Name":"7057 - Polo Park","City":"POLO PARK","SteelType":"2","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"7058","Name":"7058 - St Vital","City":"ST. VITAL","SteelType":"2","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"7061","Name":"7061 - Marlborough","City":"MARLBOROUGH","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7062","Name":"7062 - Clareview","City":"CLAIRVIEW","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7063","Name":"7063 - Chinook","City":"CALGARY CHINOOK","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7064","Name":"7064 - West End","City":"WEST END","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7065","Name":"7065 - South Common","City":"SOUTH COMMON","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7067","Name":"7067 - Shawnessey","City":"SHAWNESSY","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7068","Name":"7068 - Edmonton Lumber DC","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7069","Name":"7069 - Montreal Lumber DC","City":"","SteelType":"1","Seismic":"1","Market Name":""},
  {"Number":"7070","Name":"7070 - Laval District Office","City":"","SteelType":"1","Seismic":"1","Market Name":""},
  {"Number":"7072","Name":"7072 - Vancouver Lumber DC","City":"","SteelType":"3","Seismic":"2","Market Name":""},
  {"Number":"7073","Name":"7073 - Leaside","City":"LEASIDE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7074","Name":"7074 - Victoria","City":"VICTORIA","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7075","Name":"7075 - Cornwall","City":"CORNWALL","SteelType":"1","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7076","Name":"7076 - Beacon Hill","City":"CALGARY - BEACON HILL","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7077","Name":"7077 - St Johns","City":"ST. JOHNS, NL","SteelType":"1","Seismic":"1","Market Name":"NewFoundland "},
  {"Number":"7078","Name":"7078 - Dufferin","City":"DUFFERIN & STEELES","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7079","Name":"7079 - Barrhaven","City":"BARRHAVEN","SteelType":"1","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7080","Name":"7080 - Gerrard Square","City":"GERRARD SQUARE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7081","Name":"7081 - Kingston","City":"KINGSTON","SteelType":"2","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7082","Name":"7082 - Calgary SE-Mackenzie","City":"CALGARY SOUTHEAST","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7083","Name":"7083 - Langley Imports DC","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7084","Name":"7084 - Vernon","City":"VERNON","SteelType":"3","Seismic":"2","Market Name":"BC Interior "},
  {"Number":"7085","Name":"7085 - Brockville","City":"BROCKVILLE","SteelType":"1","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7086","Name":"7086 - Winnipeg Southwest","City":"WINNIPEG S.W.","SteelType":"2","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"7087","Name":"7087 - Victoriaville","City":"VICTORIAVILLE","SteelType":"1","Seismic":"1","Market Name":"Quebec City"},
  {"Number":"7088","Name":"7088 - St Albert","City":"ST. ALBERT","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7089","Name":"7089 - Sherbrooke","City":"SHERBROOKE","SteelType":"1","Seismic":"1","Market Name":"Quebec City"},
  {"Number":"7090","Name":"7090 - Prairie District Office","City":"","SteelType":"","Seismic":"1","Market Name":""},
  {"Number":"7091","Name":"7091 - BC District Office","City":"","SteelType":"","Seismic":"2","Market Name":""},
  {"Number":"7095","Name":"7095 - Supply Store Supp Ctr","City":"","SteelType":"","Seismic":"1","Market Name":""},
  {"Number":"7102","Name":"7102 - Thunder Bay","City":"THUNDER BAY","SteelType":"1","Seismic":"1","Market Name":"Thunder Bay  "},
  {"Number":"7103","Name":"7103 - Saint John","City":"ST. JOHN  ","SteelType":"1","Seismic":"1","Market Name":"New Brunswick"},
  {"Number":"7105","Name":"7105 - Waterloo","City":"WATERLOO","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7106","Name":"7106 - Richmond Hill","City":"RICHMOND HILL","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7107","Name":"7107 - Eglinton East","City":"SCARBOROUGH SOUTH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7108","Name":"7108 - Kanata","City":"KANATA","SteelType":"2","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7109","Name":"7109 - Ajax","City":"AJAX","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7110","Name":"7110 - Brampton North","City":"BRAMPTON NORTH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7111","Name":"7111 - Country Hills","City":"CALGARY COUNTRY HILLS","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7112","Name":"7112 - Mississauga South","City":"MISSISSAUGA SOUTH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7114","Name":"7114 - Rexdale","City":"ETOBICOKE NORTH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7115","Name":"7115 - Oakville-Burloak","City":"OAKVILLE (Burloak)","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7116","Name":"7116 - Peterborough","City":"PETERBOROUGH","SteelType":"2","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7117","Name":"7117 - Strathcona","City":"EDMONTON STRATHCONA","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7118","Name":"7118 - Orleans","City":"ORLEANS","SteelType":"2","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7119","Name":"7119 - Skyview","City":"SKYVIEW","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7122","Name":"7122 - Surrey-Whiterock","City":"SURREY","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7123","Name":"7123 - Oshawa","City":"OSHAWA","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7124","Name":"7124 - Laval","City":"LAVAL","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7126","Name":"7126 - Halifax","City":"HALIFAX","SteelType":"2","Seismic":"1","Market Name":"Nova Scotia"},
  {"Number":"7128","Name":"7128 - St Henri","City":"ST-HENRI","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7129","Name":"7129 - Yorkdale","City":"YORKDALE","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7130","Name":"7130 - Mississauga West","City":"MISSISSAUGA WEST","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7131","Name":"7131 - Red Deer","City":"RED DEER","SteelType":"2","Seismic":"1","Market Name":"Red Deer"},
  {"Number":"7132","Name":"7132 - Mississauga North","City":"MISSISSAUGA NORTH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7133","Name":"7133 - Belleville","City":"BELLEVILLE","SteelType":"2","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7134","Name":"7134 - Caledonia-Castlefield","City":"CASTLEFIELD","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7135","Name":"7135 - Aurora","City":"AURORA","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7136","Name":"7136 - Markham East","City":"MARKHAM EAST","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7137","Name":"7137 - Orillia","City":"ORILLIA","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7138","Name":"7138 - Brantford","City":"BRANTFORD","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7139","Name":"7139 - Lachenaie","City":"LACHENAIE","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7140","Name":"7140 - Gatineau","City":"GATINEAU","SteelType":"1","Seismic":"1","Market Name":"Quebec West "},
  {"Number":"7141","Name":"7141 - Abbottsford","City":"ABBOTSFORD","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7142","Name":"7142 - Guelph","City":"GUELPH","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7144","Name":"7144 - Kamloops","City":"KAMLOOPS","SteelType":"3","Seismic":"2","Market Name":"BC Interior "},
  {"Number":"7145","Name":"7145 - Port Coquitlam","City":"PORT COQUITLAM","SteelType":"3","Seismic":"2","Market Name":"British Columbia "},
  {"Number":"7146","Name":"7146 - LAcadie","City":"LACADIE","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7147","Name":"7147 - St Jerome","City":"ST. JEROME","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7148","Name":"7148 - Moncton","City":"MONCTON","SteelType":"1","Seismic":"1","Market Name":"New Brunswick"},
  {"Number":"7149","Name":"7149 - Beaubien Ouest","City":"BEAUBIEN","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7150","Name":"7150 - St Bruno","City":"ST-BRUNO","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7151","Name":"7151 - Sydney","City":"SYNDNEY, NS","SteelType":"1","Seismic":"1","Market Name":"Cape Breton  "},
  {"Number":"7152","Name":"7152 - Greenfield Park","City":"GREENFIELD PARK","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7153","Name":"7153 - Sarnia","City":"SARNIA","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7154","Name":"7154 - Owen Sound","City":"OWEN SOUND","SteelType":"2","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7156","Name":"7156 - Anjou","City":"ANJOU","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7157","Name":"7157 - Oakville-Trafalgar","City":"TRAFALGAR","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7158","Name":"7158 - South Keys","City":"OTTAWA CENTRE","SteelType":"2","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7159","Name":"7159 - Boisbriand","City":"BOISBRIAND","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7160","Name":"7160 - North Bay","City":"NORTH BAY","SteelType":"1","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7161","Name":"7161 - Richmond Hill North","City":"RICHMOND HILL N","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7162","Name":"7162 - Lebourgneuf","City":"LEBOURGNEUF","SteelType":"1","Seismic":"1","Market Name":"Quebec City"},
  {"Number":"7163","Name":"7163 - Ste Foy","City":"STE-FOY","SteelType":"1","Seismic":"1","Market Name":"Quebec City "},
  {"Number":"7164","Name":"7164 - Bracebridge","City":"BRACEBRIDGE","SteelType":"1","Seismic":"1","Market Name":"Ontario North"},
  {"Number":"7166","Name":"7166 - Cambridge","City":"CAMBRIDGE","SteelType":"1","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7167","Name":"7167 - Orangeville","City":"ORANGEVILLE","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7169","Name":"7169 - Trois Rivieres","City":"TROIS RIVIERES ","SteelType":"1","Seismic":"1","Market Name":"Maurice Bois Fran"},
  {"Number":"7170","Name":"7170 - Lethbridge","City":"LETHBRIDGE","SteelType":"2","Seismic":"1","Market Name":"Red Deer"},
  {"Number":"7171","Name":"7171 - Prince George","City":"PRINCE GEORGE","SteelType":"3","Seismic":"2","Market Name":"Prince George"},
  {"Number":"7172","Name":"7172 - Sherwood Park","City":"SHERWOOD PARK","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7173","Name":"7173 - Charlottetown","City":"CHARLOTTETOWN","SteelType":"1","Seismic":"1","Market Name":"P.E.I "},
  {"Number":"7174","Name":"7174 - Niagara Falls","City":"NIAGRA FALLS","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7175","Name":"7175 - Grande Prairie","City":"GRAND PRAIRIE","SteelType":"2","Seismic":"1","Market Name":"Grand Prairie"},
  {"Number":"7177","Name":"7177 - Courtenay","City":"COURTENAY","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7178","Name":"7178 - Chatham","City":"CHATHAM","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7179","Name":"7179 - Kitchener West","City":"KITCHENER WEST","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7180","Name":"7180 - Winnipeg North","City":"WINNIPEG NORTH","SteelType":"1","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"8180","Name":"7180 - Winnipeg North (DO Mail)","City":"WINNIPEG NORTH","SteelType":"1","Seismic":"1","Market Name":"Winnipeg"},
  {"Number":"7182","Name":"7182 - Woodstock","City":"WOODSTOCK","SteelType":"1","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7184","Name":"7184 - Windsor East","City":"WINDSOR","SteelType":"1","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7185","Name":"7185 - St Constant","City":"ST.CONSTANT","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7187","Name":"7187 - Vaudrieul","City":"VAUDREUIL","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7188","Name":"7188 - Granby","City":"GRANBY","SteelType":"1","Seismic":"1","Market Name":"Quebec City "},
  {"Number":"7189","Name":"7189 - St Romuald","City":"ST. ROMUALD","SteelType":"1","Seismic":"1","Market Name":"Quebec City "},
  {"Number":"7193","Name":"7193 - Innovation Center","City":"Toronto","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7198","Name":"7198 - Laval DC","City":"","SteelType":"1","Seismic":"1","Market Name":""},
  {"Number":"7200","Name":"7200 - Supply Warehouse","City":"","SteelType":"2","Seismic":"1","Market Name":""},
  {"Number":"7221","Name":"7221 - Campbell River","City":"CAMPBELL RIVER","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7222","Name":"7222 - Fort Saskatchewan","City":"FORT SASKATCHEWAN","SteelType":"1","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7223","Name":"7223 - Okotoks","City":"OKOTOKS","SteelType":"2","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7224","Name":"7224 - St Jean sur Richelieu","City":"ST. JEAN SUR RICHELIEU","SteelType":"1","Seismic":"1","Market Name":"Maurice Bois Fran"},
  {"Number":"7226","Name":"7226 - Parry Sound","City":"PARRY SOUND","SteelType":"2","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7227","Name":"7227 - Edmonton Westmount","City":"EDMONTON WESTMOUNT","SteelType":"1","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7228","Name":"7228 - Windsor North","City":"WINDSOR","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7233","Name":"7233 - Fredericton","City":"FREDERICTON","SteelType":"1","Seismic":"1","Market Name":"New Brunswick"},
  {"Number":"7234","Name":"7234 - Collingwood","City":"COLLINGWOOD","SteelType":"1","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7236","Name":"7236 - Timmins","City":"TIMMINS","SteelType":"1","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7237","Name":"7237 - London East","City":"LONDON EAST","SteelType":"2","Seismic":"1","Market Name":"West Toronto"},
  {"Number":"7238","Name":"7238 - Pickering","City":"PICKERING","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7239","Name":"7239 - Brampton East","City":"BRAMPTON EAST","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7240","Name":"7240 - Bowmanville","City":"BOWMANVILLE","SteelType":"2","Seismic":"1","Market Name":"East Toronto"},
  {"Number":"7241","Name":"7241 - Milton","City":"MILTON","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7242","Name":"7242 - Pembroke","City":"PEMBROKE","SteelType":"1","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7244","Name":"7244 - Huntsville","City":"HUNTSVILLE","SteelType":"2","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7245","Name":"7245 - Regina Northwest","City":"REGINA NORTH WEST","SteelType":"2","Seismic":"1","Market Name":"Saskatchewan"},
  {"Number":"7247","Name":"7247 - Midland","City":"MIDLAND","SteelType":"1","Seismic":"1","Market Name":"Ontario N"},
  {"Number":"7248","Name":"7248 - Whitemud","City":"EDMONTON (WHITEMUD)","SteelType":"2","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7249","Name":"7249 - Whitby North","City":"WHITBY","SteelType":"2","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7250","Name":"7250 - Calgary Tuscany","City":"CALGARY (TUSCANY)","SteelType":"1","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7251","Name":"7251 - Cobourg","City":"COBOURG","SteelType":"1","Seismic":"1","Market Name":"East Toronto "},
  {"Number":"7252","Name":"7252 - West Bank","City":"WESTBANK","SteelType":"3","Seismic":"2","Market Name":"BC Interior "},
  {"Number":"7253","Name":"7253 - Vaughan","City":"VAUGHAN","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7254","Name":"7254 - Airdrie","City":"AIRDRIE","SteelType":"1","Seismic":"1","Market Name":"Alberta"},
  {"Number":"7255","Name":"7255 - Cranbrook","City":"CRANBROOK","SteelType":"3","Seismic":"2","Market Name":"BC Interior "},
  {"Number":"7256","Name":"7256 - Hamilton Mountain","City":"HAMILTON MOUNTAIN","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7257","Name":"7257 - Dartmouth Crossings","City":"DARTMOUTH","SteelType":"1","Seismic":"1","Market Name":"Nova Scotia"},
  {"Number":"7258","Name":"7258 - Lloydminster","City":"LLOYDMINSTER","SteelType":"1","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7259","Name":"7259 - Vancouver-Cambie","City":"CAMBIE *","SteelType":"3","Seismic":"2","Market Name":"British Columbia"},
  {"Number":"7261","Name":"7261 - New Minas","City":"NEW MINAS","SteelType":"1","Seismic":"1","Market Name":"Nova Scotia"},
  {"Number":"7262","Name":"7262 - Markham","City":"MARKHAM NORTH","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7263","Name":"7263 - Carleton Place","City":"CARELTON PLACE (OTTAWA)","SteelType":"1","Seismic":"1","Market Name":"Ottawa"},
  {"Number":"7264","Name":"7264 - Bradford","City":"BRADFORD","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7265","Name":"7265 - Saskatoon South","City":"SASKATOON SOUTH","SteelType":"1","Seismic":"1","Market Name":"Saskatchewan"},
  {"Number":"7266","Name":"7266 - Point Claire","City":"POINTE CLAIRE","SteelType":"1","Seismic":"1","Market Name":"Quebec"},
  {"Number":"7268","Name":"7268 - Edmonton Windermere","City":"EDMONTON WINDERMERE","SteelType":"1","Seismic":"1","Market Name":"Edmonton"},
  {"Number":"7269","Name":"7269 - Bolton","City":"BOLTON","SteelType":"1","Seismic":"1","Market Name":"Toronto"},
  {"Number":"7272","Name":"7272 - Duncan","City":"DUNCAN","SteelType":"3","Seismic":"2","Market Name":"Vancouver Island"},
  {"Number":"7273","Name":"7273 - Chilliwack","City":"CHILLIWACK","SteelType":"3","Seismic":"2","Market Name":"British Columbia"},
  {"Number":"7275","Name":"7275 - Vaughan","City":"VAUGHAN","SteelType":"2","Seismic":"1","Market Name":"Ontario"},
  {"Number":"7301","Name":"7301 - Brampton West","City":"BRAMPTON WEST","SteelType":"1","Seismic":"1","Market Name":"Ontario"},
];

export const HDSteelType = [
  {"id":"1", "Name":"BON"},
  {"id":"2", "Name":"NAS"},
  {"id":"3", "Name":"ARP"},
];

export const HDSeismic = [
  {"id":"1", "Name":"No"},
  {"id":"2", "Name":"Yes"},
];

export const HDDept = [
  {"deptNo":"21","deptName":"Lumber"},
  {"deptNo":"22","deptName":"Building Materials"},
  {"deptNo":"23","deptName":"Floor Coverings"},
  {"deptNo":"24","deptName":"Paint"},
  {"deptNo":"25","deptName":"Hardware & Tools"},
  {"deptNo":"26","deptName":"Plumbing"},
  {"deptNo":"27","deptName":"Electrical / Lighting / Fans"},
  {"deptNo":"28","deptName":"Lawn & Garden"},
  {"deptNo":"29","deptName":"Kitchen / Bath / Storage / Appliances"},
  {"deptNo":"30","deptName":"Millwork & Mouldings"},
  {"deptNo":"59","deptName":"Decor / Window Treatments"},
  {"deptNo":"93","deptName":"OL - Outdoor Lumber"},
  {"deptNo":"94","deptName":"SS - Seasonal Swing"},
  {"deptNo":"95","deptName":"SA - Swing Area"},
  {"deptNo":"96","deptName":"TR - Tool Rental"},
  {"deptNo":"97","deptName":"FE - Front End"},
  {"deptNo":"98","deptName":"RV - Receiving"},
  {"deptNo":"99","deptName":"EC - End Cap"},
];

export const HDBayType = [
  {"paramID":1, "bayTypeID":"RS_O", "bayTypeLabel":"Reg Steel Orange", 'color':'#FFA500', 'colorName':'org' },
  {"paramID":2, "bayTypeID":"RS_W", "bayTypeLabel":"Reg Steel White", 'color':'#FFFFFF', 'colorName':'wht' },
  {"paramID":3, "bayTypeID":"GALV", "bayTypeLabel":"Galvanized", 'color':'#999999', 'colorName':'gal' },
  {"paramID":4, "bayTypeID":"CANT", "bayTypeLabel":"Cantilever", 'color':'#444444', 'colorName':'org' },
];

export const hdbtFromParam = (bayParam) => {
  const bt = HDBayType.find(b => b.paramID === bayParam);
  if (bt && bt.bayTypeID) { return bt.bayTypeID; } 
  return null;
}
export const hdbtFromBayType = (bayTypeId) => {
  const bt = HDBayType.find(b => b.bayTypeID === bayTypeId);
  if (bt && bt.paramID) { return bt.paramID; } 
  return 0;
}
export const hdGetMyQuestions = (bayTypeId, isSeismic) => {
  if (isSeismic)
  {
    if (bayTypeId === 'RS_O' || bayTypeId === 'RS_W')
      return ['f01','b03','b10','b13','b01','b02','b04','b05','b06','b08','b12','b14','c01','c06','c02','c07','c03','c04','e02','e03','e12','e04','e05','e06','e14','e16','e08','e09','e10','e11','e13'];
    if (bayTypeId === 'GALV')
      return ['f01','b03','b10','b13','b01','b02','b04','b05','b06','b08','b12','b14','c01','c06','c02','c07','c03','c04','d01','d02','d03','d04','d05','e02','e03','e12','e04','e05','e06','e14','e16','e08','e09','e10','e13','e15'];
    if (bayTypeId === 'CANT')
      return ['f01','a01','a02','a03','a04','a05','a06','a07','a08','a09'];
  } else {
    if (bayTypeId === 'RS_O' || bayTypeId === 'RS_W')
      return ['f01','b01','b02','b04','b05','b06','b08','b09','b12','b14','c01','c06','c02','c07','c03','c04','e02','e03','e12','e04','e05','e06','e14','e16','e08','e09','e10','e11','e13'];
    if (bayTypeId === 'GALV')
      return ['f01','b01','b02','b04','b05','b06','b08','b09','b12','b14','c01','c06','c02','c07','c03','c04','d01','d02','d03','d04','d05','e02','e03','e12','e04','e05','e06','e14','e16','e08','e09','e10','e13','e15'];
    if (bayTypeId === 'CANT')
      return ['f01','a01','a02','a03','a04','a05','a06','a07','a08','a09'];
  }  
  return [];
}

export const HDBayAisle = [
  {"aisleID":"99","aisleName":"Aisle 99","legacyID":"99"},
  {"aisleID":"98","aisleName":"Aisle 98","legacyID":"98"},
  {"aisleID":"97","aisleName":"Aisle 97","legacyID":"97"},
  {"aisleID":"96","aisleName":"Aisle 96","legacyID":"96"},
  {"aisleID":"95","aisleName":"Aisle 95","legacyID":"95"},
  {"aisleID":"94","aisleName":"Aisle 94","legacyID":"94"},
  {"aisleID":"93","aisleName":"Aisle 93","legacyID":"93"},
  {"aisleID":"92","aisleName":"Aisle 92","legacyID":"92"},
  {"aisleID":"91","aisleName":"Aisle 91","legacyID":"91"},
  {"aisleID":"90","aisleName":"Aisle 90","legacyID":"90"},
  {"aisleID":"89","aisleName":"Aisle 89","legacyID":"89"},
  {"aisleID":"88","aisleName":"Aisle 88","legacyID":"88"},
  {"aisleID":"87","aisleName":"Aisle 87","legacyID":"87"},
  {"aisleID":"86","aisleName":"Aisle 86","legacyID":"86"},
  {"aisleID":"85","aisleName":"Aisle 85","legacyID":"85"},
  {"aisleID":"84","aisleName":"Aisle 84","legacyID":"84"},
  {"aisleID":"83","aisleName":"Aisle 83","legacyID":"83"},
  {"aisleID":"82","aisleName":"Aisle 82","legacyID":"82"},
  {"aisleID":"81","aisleName":"Aisle 81","legacyID":"81"},
  {"aisleID":"80","aisleName":"Aisle 80","legacyID":"80"},
  {"aisleID":"79","aisleName":"Aisle 79","legacyID":"79"},
  {"aisleID":"78","aisleName":"Aisle 78","legacyID":"78"},
  {"aisleID":"77","aisleName":"Aisle 77","legacyID":"77"},
  {"aisleID":"76","aisleName":"Aisle 76","legacyID":"76"},
  {"aisleID":"75","aisleName":"Aisle 75","legacyID":"75"},
  {"aisleID":"74","aisleName":"Aisle 74","legacyID":"74"},
  {"aisleID":"73","aisleName":"Aisle 73","legacyID":"73"},
  {"aisleID":"72","aisleName":"Aisle 72","legacyID":"72"},
  {"aisleID":"71","aisleName":"Aisle 71","legacyID":"71"},
  {"aisleID":"70","aisleName":"Aisle 70","legacyID":"70"},
  {"aisleID":"69","aisleName":"Aisle 69","legacyID":"69"},
  {"aisleID":"68","aisleName":"Aisle 68","legacyID":"68"},
  {"aisleID":"67","aisleName":"Aisle 67","legacyID":"67"},
  {"aisleID":"66","aisleName":"Aisle 66","legacyID":"66"},
  {"aisleID":"65","aisleName":"Aisle 65","legacyID":"65"},
  {"aisleID":"64","aisleName":"Aisle 64","legacyID":"64"},
  {"aisleID":"63","aisleName":"Aisle 63","legacyID":"63"},
  {"aisleID":"62","aisleName":"Aisle 62","legacyID":"62"},
  {"aisleID":"61","aisleName":"Aisle 61","legacyID":"61"},
  {"aisleID":"60","aisleName":"Aisle 60","legacyID":"60"},
  {"aisleID":"59","aisleName":"Aisle 59","legacyID":"59"},
  {"aisleID":"58","aisleName":"Aisle 58","legacyID":"58"},
  {"aisleID":"57","aisleName":"Aisle 57","legacyID":"57"},
  {"aisleID":"56","aisleName":"Aisle 56","legacyID":"56"},
  {"aisleID":"55","aisleName":"Aisle 55","legacyID":"55"},
  {"aisleID":"54","aisleName":"Aisle 54","legacyID":"54"},
  {"aisleID":"53","aisleName":"Aisle 53","legacyID":"53"},
  {"aisleID":"52","aisleName":"Aisle 52","legacyID":"52"},
  {"aisleID":"51","aisleName":"Aisle 51","legacyID":"51"},
  {"aisleID":"50","aisleName":"Aisle 50","legacyID":"50"},
  {"aisleID":"49","aisleName":"Aisle 49","legacyID":"49"},
  {"aisleID":"48","aisleName":"Aisle 48","legacyID":"48"},
  {"aisleID":"47","aisleName":"Aisle 47","legacyID":"47"},
  {"aisleID":"46","aisleName":"Aisle 46","legacyID":"46"},
  {"aisleID":"45","aisleName":"Aisle 45","legacyID":"45"},
  {"aisleID":"44","aisleName":"Aisle 44","legacyID":"44"},
  {"aisleID":"43","aisleName":"Aisle 43","legacyID":"43"},
  {"aisleID":"42","aisleName":"Aisle 42","legacyID":"42"},
  {"aisleID":"41","aisleName":"Aisle 41","legacyID":"41"},
  {"aisleID":"40","aisleName":"Aisle 40","legacyID":"40"},
  {"aisleID":"39","aisleName":"Aisle 39","legacyID":"39"},
  {"aisleID":"38","aisleName":"Aisle 38","legacyID":"38"},
  {"aisleID":"37","aisleName":"Aisle 37","legacyID":"37"},
  {"aisleID":"36","aisleName":"Aisle 36","legacyID":"36"},
  {"aisleID":"35","aisleName":"Aisle 35","legacyID":"35"},
  {"aisleID":"34","aisleName":"Aisle 34","legacyID":"34"},
  {"aisleID":"33","aisleName":"Aisle 33","legacyID":"33"},
  {"aisleID":"32","aisleName":"Aisle 32","legacyID":"32"},
  {"aisleID":"31","aisleName":"Aisle 31","legacyID":"31"},
  {"aisleID":"30","aisleName":"Aisle 30","legacyID":"30"},
  {"aisleID":"29","aisleName":"Aisle 29","legacyID":"29"},
  {"aisleID":"28","aisleName":"Aisle 28","legacyID":"28"},
  {"aisleID":"27","aisleName":"Aisle 27","legacyID":"27"},
  {"aisleID":"26","aisleName":"Aisle 26","legacyID":"26"},
  {"aisleID":"25","aisleName":"Aisle 25","legacyID":"25"},
  {"aisleID":"24","aisleName":"Aisle 24","legacyID":"24"},
  {"aisleID":"23","aisleName":"Aisle 23","legacyID":"23"},
  {"aisleID":"22","aisleName":"Aisle 22","legacyID":"22"},
  {"aisleID":"21","aisleName":"Aisle 21","legacyID":"21"},
  {"aisleID":"20","aisleName":"Aisle 20","legacyID":"20"},
  {"aisleID":"19","aisleName":"Aisle 19","legacyID":"19"},
  {"aisleID":"18","aisleName":"Aisle 18","legacyID":"18"},
  {"aisleID":"17","aisleName":"Aisle 17","legacyID":"17"},
  {"aisleID":"16","aisleName":"Aisle 16","legacyID":"16"},
  {"aisleID":"15","aisleName":"Aisle 15","legacyID":"15"},
  {"aisleID":"14","aisleName":"Aisle 14","legacyID":"14"},
  {"aisleID":"13","aisleName":"Aisle 13","legacyID":"13"},
  {"aisleID":"12","aisleName":"Aisle 12","legacyID":"12"},
  {"aisleID":"11","aisleName":"Aisle 11","legacyID":"11"},
  {"aisleID":"10","aisleName":"Aisle 10","legacyID":"10"},
  {"aisleID":"09","aisleName":"Aisle 9","legacyID":"9"},
  {"aisleID":"08","aisleName":"Aisle 8","legacyID":"8"},
  {"aisleID":"07","aisleName":"Aisle 7","legacyID":"7"},
  {"aisleID":"06","aisleName":"Aisle 6","legacyID":"6"},
  {"aisleID":"05","aisleName":"Aisle 5","legacyID":"5"},
  {"aisleID":"04","aisleName":"Aisle 4","legacyID":"4"},
  {"aisleID":"03","aisleName":"Aisle 3","legacyID":"3"},
  {"aisleID":"02","aisleName":"Aisle 2","legacyID":"2"},
  {"aisleID":"01","aisleName":"Aisle 1","legacyID":"1"},
  // {"aisleID":"0","aisleName":"Aisle 0","legacyID":"0"},
  {"aisleID":"AS","aisleName":"Appliance Showroom","legacyID":"-10021"},
  {"aisleID":"BO","aisleName":"Bopis","legacyID":"-10022"},
  {"aisleID":"BW","aisleName":"Back Wall","legacyID":"-10003"},
  {"aisleID":"DS","aisleName":"Decor Showroom","legacyID":"-10020"},
  {"aisleID":"EC","aisleName":"End Cap","legacyID":"-10001"},
  {"aisleID":"FE","aisleName":"Front End","legacyID":"-10007"},
  {"aisleID":"FS","aisleName":"Flooring Showroom","legacyID":"-10017"},
  {"aisleID":"KS","aisleName":"Kitchen Showroom","legacyID":"-10018"},
  {"aisleID":"MS","aisleName":"Millwork Showroom","legacyID":"-10019"},
  {"aisleID":"OB","aisleName":"Outdoor Back Wall","legacyID":"-10023"},
  {"aisleID":"OG","aisleName":"Outdoor Garden","legacyID":"-10009"},
  {"aisleID":"OL","aisleName":"Outdoor Lumber","legacyID":"-10010"},
  {"aisleID":"PD","aisleName":"Paint Desk","legacyID":"-10013"},
  {"aisleID":"PL","aisleName":"Parking Lot","legacyID":"-10008"},
  {"aisleID":"PR","aisleName":"PRO Desk","legacyID":"-10005"},
  {"aisleID":"RC","aisleName":"Register Caps","legacyID":"-10006"},
  {"aisleID":"RV","aisleName":"Receiving","legacyID":"-10014"},
  {"aisleID":"SC","aisleName":"Side Cap","legacyID":"-10002"},
  {"aisleID":"SL","aisleName":"Seasonal Laydown","legacyID":"-10016"},
  {"aisleID":"SS","aisleName":"Seasonal Swing Area","legacyID":"-10015"},
  {"aisleID":"TR","aisleName":"Tool Rental","legacyID":"-10004"},
  {"aisleID":"UB","aisleName":"Upper Back Wall","legacyID":"-10011"},
  {"aisleID":"UF","aisleName":"Upper Store Front","legacyID":"-10012"},
  {"aisleID":"VS","aisleName":"Vestibule","legacyID":"-10023"},
];