import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { SurveyActions } from '../_actions';
import {
    Button,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    Text,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Divider,
    Slide,      // For Transition (sliding in full page modal dialog)
    Chip,
    FormControlLabel,
    Switch,
} from '@mui/material';
// material icons!
import CloseIcon from '@mui/icons-material/Close';
// slide in full screen dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

import {
    HDASS, HDArticle, HDSteelType, HDDept, HDBay, HDSeismic, HDBayAisle, hdGetMyQuestions, hdbtFindBayType, HDBayType,
} from '../_appdata';
// for colors!
import { hsl2rgb, rgb2hex } from '../_helpers';

import { KForm } from '../AdminSurveySettingPage/KForm';

import Image from 'mui-image';
import { ProtectedImage } from '../_components/ProtectedImage';
import { SurveyBuilderBase } from '.';

import { availableSurveySettings } from '../_helpers/AdminSurveySettingData';

// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';
// APPDATA going away!! AdminSurveySettings coming to stay!
import { assActions } from '../_actions';

function SurveyBuilderPage() {
    //const users = useSelector(state => state.users);
    const auth = useSelector(state => state.authentication);
    const Surveys = useSelector(state => state.Surveys);
    const asurvset = useSelector(state => state.adminSurveySettings);
    const dispatch = useDispatch();

    // the ID of the survey we are working on
    const [viewSurvey, setViewSurvey] = useState(''); // Number() index of redux or '' for nothing

    // warning dialog if we're gonna lose unsaved changes
    const [isChanges, setIsChanges] = useState(false);
    const [isWarningDialog, setIsWarningDialog] = useState(false);
    const handleWarningClose = () => { setIsWarningDialog(false) };
    const handleWarningOpen = () => { setIsWarningDialog(true) };

    /*
    React.useLayoutEffect(() => {
      setMyQuestions(hdGetMyQuestions(Surveys.currentSurvey.SteelType, amSeismic));
    },
      [Surveys.currentSurvey.SteelType, amSeismic]
    );
  */
    // one time useEffect to load stuff (adminSurveySettings)
    useEffect(() => {
        // load users 
        dispatch(userActions.getAll());
        // load AdminSurveySettings
        dispatch(assActions.getAll(auth?.user));
    }, []);

    // mySurvey = the survey selected in the dropdown, js obj: { id (database), QID, QType, QVis, etc... }
    // null value means no selection, undefined means selection not found in list (should not happen! critical error)
    const mySurvey = asurvset?.isLoaded ? (
        (viewSurvey !== '' ? asurvset.Survey[viewSurvey] : null))
        : null;

    //const sSubClass = mySurveySetting?.sSubClassProp; // the property in payload that maps to sSubClass

    return (
        <div>
            <Box>
                <h3>Survey Builder</h3>
            </Box>
            {/* Combining react-router link and materialui button 
            <TextField
                id="filled-multiline-static"
                label="Mission ID"
                // multiline
                // fullWidth
                // rows={8}
                variant="filled"
                value={missionId}
                onChange={(e) => { setMissionId(e.target.value); //console.log(e.target.value); }}
            />
            */}

            <Box sx={{ my: 2, width: '100%', height: 40, minHeight: 40, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Button
                        variant="outlined"
                        color={'success'}
                        disabled={asurvset?.isLoading}
                        onClick={() => {
                            console.log("Manual admin survey settings refresh.");
                            dispatch(assActions.getAll(auth?.user));
                            //dispatch(SurveyActions.downMissionPhotos(missionId, user));
                            //setLoadedPhotos(1);
                        }}
                    >Refresh</Button>
                </Box>
                <Box>
                    {asurvset.isLoaded && <Box component="span" sx={{ margin: 3 }}>Surveys: {asurvset?.Survey?.length}, Questions: {asurvset?.Question?.length}</Box>}
                    {asurvset.isLoading && <Box component="span" sx={{ margin: 3 }}><CircularProgress /></Box>}
                    {asurvset.error && <Box component="span" sx={{ margin: 3, color: 'error' }}>{asurvset.error}</Box>}
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color={'success'}
                        disabled={asurvset?.isLoading}
                        onClick={() => {
                            console.log("Clicked Add New Survey.");
                            //dispatch(assActions.getAll(auth?.user));
                            //setInitialV(false);
                            //setEditorOpen(true);
                            //openTheEditor('SUR');
                        }}
                    >Add New Survey</Button>
                </Box>
            </Box>
            {/* <p>{viewSurvey}</p> */}
            <Box sx={{ my: 2 }}>
                <FormControl fullWidth variant='outlined'>
                    <InputLabel id="pickerlabel-view-setting">Choose Survey</InputLabel>
                    <Select
                        labelId="pickerlabel-view-setting"
                        id="picker-view-setting"
                        label='Choose Survey'
                        value={viewSurvey}
                        onChange={e => {
                            const pickedID = e.target.value;
                            setViewSurvey(pickedID);
                            console.log(`Picked Survey[${pickedID}] => `);
                            console.log(asurvset.Survey[pickedID]);
                            //setIsAdding(false);  // if we change what we're viewing with additem open, close it
                        }}
                    >
                        {asurvset?.Survey?.map((s, i) => (
                            <MenuItem
                                key={'a_' + s.SID + i}
                                value={i}
                            >{`${s.Name} (Version: ${s.Version})`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <SurveyBuilderBase
                survey={mySurvey}
                surveyIndex={viewSurvey}
            />
            {/* show all settings
            <pre>{JSON.stringify(asurvset, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.Survey, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.Question, null, ' ')}</pre>
            <pre>{JSON.stringify(asurvset?.HDArticle, null, ' ')}</pre>
            */}

            {/* popup warning if changing survey - could lose changes */}
            <Dialog
                fullScreen
                open={isWarningDialog}
                onClose={handleWarningClose}
                TransitionComponent={Transition}
            >

            </Dialog>
        </div>
    );
}


export { SurveyBuilderPage };