import { MissionConstants } from '../_constants';

const initialState = {
    isLoaded: false, isLoading: false,
    isCreated: false, isCreating: false,
    isUpdating: false,
    mission: null, myMission: null,
    error: null,    // mission loading error
    mcError: null,  // mission creation error
    upError: null,  // mission updating error
};

export function Missions(state = initialState, action) {
    switch (action.type) {
        // Retrieving Missions from the API
        case MissionConstants.GETALL_DOIT:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                mission: null,
                error: null,
            };
        case MissionConstants.GETALL_SUCC:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                mission: action.data,
                error: null,
            };
        case MissionConstants.GETALL_FAIL:
            return {
                ...state,
                isCreated: false,
                isCreating: false,
                mission: null,
                error: action.error,
            };       
        // Creating & Posting a Mission to the API
        case MissionConstants.CREATE_DOIT:
            return {
                ...state,
                isCreated: false,
                isCreating: true,
                myMission: null,          // myMission the mission I am currently on
                mcError: null,          // mission creation error
            };
        case MissionConstants.CREATE_SUCC:
            return {
                ...state,
                isCreated: true,
                isCreating: false,
                myMission: action.data,
                mcError: null,
            };
        case MissionConstants.CREATE_FAIL:
            return {
                ...state,
                isLoaded: false,
                isCreating: false,
                myMission: null,
                mcError: action.error,
            };

        case MissionConstants.OPEN_DOIT:
            return {
                ...state,
                isUpdating: true,
                myMission: null,          // myMission the mission I am currently on
                mcError: null,          // mission creation error
            };
        case MissionConstants.OPEN_SUCC:
            return {
                ...state,
                isUpdating: false,
                myMission: action.data,
                mcError: null,
            };
        case MissionConstants.OPEN_FAIL:
            return {
                ...state,
                isUpdating: false,
                myMission: null,
                mcError: action.error,
            };

        case MissionConstants.CLOSE_DOIT:
            console.log("CLOSE_DOIT");
            return {
                ...state,
                isUpdating: true,
                //myMission: null,          // myMission the mission I am currently on
                mcError: null,          // mission creation error
            };
        case MissionConstants.CLOSE_SUCC:
            console.log("CLOSE_SUCC");
            return {
                ...state,
                isUpdating: false,
                myMission: null,
                mcError: null,
            };
        case MissionConstants.CLOSE_FAIL:
            console.log("CLOSE_FAIL");

            return {
                ...state,
                isUpdating: false,
                // myMission: null,        // this should not be done. it was an emergency. api fucken up...
                mcError: action.error,
            };
                
        default:
            return state;
    }
}