import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Formik, Field, Form } from 'formik';
// materialUI stuff
import {
  Box, Button,
  TextField, FormControlLabel, Switch, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
  CircularProgress, Grid, Container, Typography, InputAdornment,
} from '@mui/material';
import { Close as CloseIcon, Visibility, VisibilityOff } from '@mui/icons-material';
// redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';

import { appPermissionActions } from '../_actions';

const AdminResetUserPassword = (props) => {
  // redux : to dispatch actions
  const dispatch = useDispatch();
  const users = useSelector(state => state?.users);
  
  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [autoClose, setAutoClose] = useState(false);

  // initial form values (also what we reset back to if reset is clicked)
  const initialValues = {
    nupassword: ''
  };

  // if we sent a request and then it succeeded, close ourself.
  useEffect(() => {
    if (autoClose && users?.settingPassword == 's') { props.onClose(); }
  });

  return ( 
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize // Required for initialValues to take effect  :O 
        validate={(values) => {
            /* this form doesn't use Yup but instead manual validation. left in as an example */
            let errors = {};
            if (!values.nupassword) {
                errors.nupassword = 'Required';
            } else if ( values.nupassword.length < 8 ) {
                errors.nupassword = 'Too short, minimum 8 characters.'
            } else if ( values.nupassword.length > 64 ) {
                errors.nupassword = 'Too long, maximum 64 characters.'
            } else {
                let tmp = '';
                if (!/\d/.test(values.nupassword)) tmp += 'Needs a number (0-9). ';
                if (!/[$-/:-?{-~!"^_`\[\]@#]/.test(values.nupassword)) tmp += 'Needs a symbol (!, @, #, etc.). ';
                if (!/[a-z]/.test(values.nupassword)) tmp += 'Needs a lowercase letter. ';
                if (!/[A-Z]/.test(values.nupassword)) tmp += 'Needs an UPPERCASE letter. ';
                if (tmp.length > 0) { errors.nupassword = tmp; }
            }
            return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true);   
          dispatch(userActions.setPassword(props.user.id, values.nupassword));
          setAutoClose(true);
        }}
      >
        {({ values, touched, errors, isSubmitting, 
          setFieldValue, handleChange, handleBlur, handleReset, submitForm, }) => (
          <Form sx={{ minWidth: 640, width: '80%', flexGrow: 1 }} autoComplete="off" >   { /* 100% - Without this, the form is like 30% width  */}
            {/* We _CAN_ get state from formik here */}
            <Dialog 
              open={props.open} 
              onClose={props.handleClose} 
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >

            <DialogTitle sx={{ flexGrow: 1, display: 'flex', justifyContent: 'spaceBetween', alignItems: 'center', }}>
              Password: {props?.user?.firstName} {props?.user?.lastName} 
              <IconButton onClick={props.onClose} sx={{ marginLeft: 'auto', marginRight: 0 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
              {/*<Typography sx={{ fontSize:8 }}>{props?.user?.id}</Typography>*/}              
              <Box margin={1}>
                <TextField
                  name="nupassword"
                  type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  label="New Password"
                  variant="outlined"
                  autoComplete="new-password"
                  fullWidth
                  value={values['nuemail']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nupassword && Boolean(errors.nupassword)}
                  helperText={touched.nupassword && errors.nupassword}
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              {/* Formik MaterialUI values: {values['rememberMe'] ? 'on' : 'off'} */}
              {/* JSON.stringify(values) */}
            </DialogContent>
            <DialogActions sx={{ flex: 'flex-grow', justifyContent: 'space-between' }}>
              <Button variant='outlined' color='error' onClick={props.onClose}>Cancel</Button>
              <Button variant='contained' color='primary' onClick={submitForm}>Set Password</Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>

    </>
  );
};

export { AdminResetUserPassword };