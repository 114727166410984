import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
//import { PrivateRoute } from '../_components';

import Container from '@mui/material/Container';
import NavBar from './NavBarTop';
import FooterBar from './FooterBar';

import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
//import { RegisterPage } from '../RegisterPage';
import { AdminUsersPage } from '../AdminUsersPage';
import { AdminSurveySettingPage, AdminSurveySettingBulkDataPage } from '../AdminSurveySettingPage';
import { MissionViewerPage } from '../MissionViewerPage';
import { MissionPhotosPage } from '../MissionPhotosPage';
import { SurveyBuilderPage } from '../SurveyBuilderPage';
import { TestFeaturePage } from '../TestFeaturePage';
import { TestFeature2Page } from '../TestFeaturePage';

function Layout() 
{
    return (
        <React.Fragment>
            <NavBar />
            {/* sx pb = PaddingBottom so page doesn't "slip under" bottom appbar, i.e. you can't scroll down far enough to see bottom of content!  */}
            <Container maxWidth={false} sx={{ pb: '82px' }}>
                {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
                <Outlet />
            </Container>
            <FooterBar />
        </React.Fragment>
    );
}

function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((param) => {
            // clear alert on location change
            dispatch(alertActions.clear());
            //console.log(`Navigated: '${location}', '${action}'.`);
            console.log(`Navigated: (action="${param.action}"): `, param.location);
        });
    }, []);

    return (

        <Router history={history}>

            <Routes >
                <Route path="/" element={<Layout />} >
                    <Route index element={<HomePage />} />
                    <Route path="/adminusers" element={<AdminUsersPage />} />
                    <Route path="/adminsurveysetting" element={<AdminSurveySettingPage />} />
                    <Route path="/adminsurveysettingbulkdata" element={<AdminSurveySettingBulkDataPage />} />
                    <Route path="/missionviewer" element={<MissionViewerPage />} />
                    <Route path="/missionphotos" element={<MissionPhotosPage />} />
                    <Route path="/surveybuilder" element={<SurveyBuilderPage />} />
                    <Route path="/testfeature" element={<TestFeaturePage />} />
                    <Route path="/testfeature2" element={<TestFeature2Page />} />
                    <Route path="/login" element={<LoginPage />} />
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Route>
            </Routes >
        </Router>
    );
}

export { App };