import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { SurveyActions } from '../_actions';
import {
    Button,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    Text,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Switch,
    CircularProgress,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Input, OutlinedInput,
    List,
    ListItem,
    ListItemText,
    Divider,
    Slide,
} from '@mui/material';
// material icons!
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
} from '@mui/icons-material';
// slide in full screen dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

import {
    HDASS, HDArticle, HDSteelType, HDDept, HDBay, HDSeismic, HDBayAisle, hdGetMyQuestions, hdbtFindBayType, HDBayType,
} from '../_appdata';
// for colors!
import { hsl2rgb, rgb2hex } from '../_helpers';

import Image from 'mui-image';
import { ProtectedImage } from '../_components/ProtectedImage';

// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';
// APPDATA going away!! AdminSurveySettings coming to stay!
import { assActions } from '../_actions';

const randColor = () => {
    const h = Math.random() * 360,
        s = Math.random() * 0.5 + 0.5, l = Math.random() * 0.25 + 0.3;

    const [r, g, b] = hsl2rgb(h, s, l);
    const accentColor = rgb2hex(r, g, b);

    return accentColor;
}

const MIN_QTY = 0, MAX_QTY = 99;

function TotalsTable(props) {
    const numCols = props?.cols || 1;
    let columnHeader = [], myTable = [];
    // column header numbers
    for (let i = 0; i < numCols; i++) { columnHeader.push(<th key={`tth_${i}`}>{i + 1}</th>); }
    // table rows
    props.data.map((row, index) => {
        let columnCount = [];
        for (let i = 0; i < numCols; i++) { columnCount.push(<td key={`ttd_${index}_${i}`}>{row?.col?.[i] || '-'}</td>); }
        myTable.push(
            <tr key={`ttr_${index}_a`}>
                <td key={`ttd_${index}_a`}>{row?.sec || '???'}</td>
                <td key={`ttd_${index}_b`}>{row?.mat || '???'}</td>
                <td key={`ttd_${index}_c`}>{row?.qty || '???'}</td>
                {columnCount}
            </tr>
        );
    });

    return (
        <table>
            <tr>
                <th colSpan={3} ></th>
                <th colSpan={numCols}>Column</th>
            </tr>
            <tr>
                <th>Section</th>
                <th>Material</th>
                <th>Total</th>
                {columnHeader}
            </tr>
            {myTable}
        </table>
    );
}

function DropDownItem(props) {
    const [myArticle, setMyArticle] = useState(undefined);
    //const [qtyEdit, setQtyEdit] = useState(false);  // qty editor pop up control
    // get the label of our article in the dropDown
    if (props?.item && props.item !== myArticle?.Article) {
        // try find our article
        let article = props.asurvset.HDArticle?.find(a => a.Article == props.item);
        // can't find? set dummy values :(
        if (!(article)) {
            article = {
                Article: props.item,
                Description: '???',
            };
        }
        setMyArticle(article);
    }
    // change my qty (relative, delta added on to previous value)
    const changeQty = (delta) => {
        let newQty = (Number(props.qty) || 0) + delta; // i.e. 1, -1, or whatev
        if (newQty < MIN_QTY) { newQty = null };
        if (newQty > MAX_QTY) { newQty = MAX_QTY }; // clamp qty value
        props.onChange(props.item, newQty);
    }
    // change my qty (absolute, sets qty to the value given)
    const changeQtyTo = (value) => {
        //let newQty = Math.min(Math.max(Number(value), MIN_QTY), MAX_QTY); // clamp qty value
        //if (!(isNaN(newQty))) { props.onChange(props.item, newQty); }
        let newQty = (Number(value) || 0); // i.e. 1, -1, or whatev
        if (newQty < MIN_QTY) { newQty = null };
        if (newQty > MAX_QTY) { newQty = MAX_QTY }; // clamp qty value
        props.onChange(props.item, newQty);
    }
    // short circuit if no valid (or dummy) article
    if (!myArticle) return null;
    // dropdown item
    return (<>
        <Box
            sx={{
                borderBottom: 1, borderColor: '#aaa', py: '2px',
                display: 'flex', justifyContent: 'space-between',
                alignItems: 'center', width: '100%',
            }}
        >
            <Box>{props?.item}</Box>
            <Box display='inline-block'>{myArticle?.Description}</Box>
            <Box sx={{ display: 'inline' }}>
                <IconButton
                    //edge="start"
                    onClick={() => { changeQty(-1); }}
                >
                    <RemoveIcon htmlColor="#000" />
                </IconButton>
                <Box
                    display='inline-block'
                    sx={{
                        width: "64px", minWidth: "64px", padding: 0,
                        textAlign: 'center', verticalAlign: 'middle',
                    }}>
                    <OutlinedInput
                        //autoFocus // set focus to this input when it appears!
                        value={props.qty || ''}
                        size='small'
                        //error={props.qty !== '' && isNaN(props.qty)}
                        placeholder="QTY" //inputProps={ariaLabel} 
                        sx={{ width: 64, input: { paddingHorizontal: 0, textAlign: "center" } }}
                        onChange={(e) => { changeQtyTo(e.target.value); }}
                    //onBlur={() => { setQtyEdit(false) }}
                    />

                </Box>
                <IconButton
                    //edge="start"
                    onClick={() => { changeQty(1); }}
                >
                    <AddIcon htmlColor="#000" />
                </IconButton>

            </Box>
        </Box>
    </>);
}

function CantSectionDropDown(props) {
    // not sure if should be null?
    const myLen = props?.dropDown?.length || 0;

    const qtyChange = (id, newQty) => {
        console.log(`Section [${props.SID}]: Set Qty[${id}] => ${newQty}`);
        props.onChange({ [props.dataID]: { ...props.data, [id]: newQty } });
    }

    return (<>
        <Box sx={{
            margin: 0, px: 1, borderRadius: 2, width: '100%', backgroundColor: '#fff',
        }}>
            {props?.dropDown?.map((d, i) => {
                const myQty = props?.data?.[d.id] == 0 ? '0' : (isNaN(props?.data?.[d.id]) ? null : props.data[d.id]);
                return (
                    <DropDownItem
                        key={`ddi_${d.id}_${i}`}
                        onChange={qtyChange}
                        item={d.id}
                        qty={myQty}
                        index={i}
                        asurvset={props.asurvset}
                    />
                );
            })}
        </Box>
    </>);
}

function CantSectionButtons(props) {
    // not sure if should be null?
    const mySet = new Set(props?.data?.split('|') || null);

    function btnToggle(id) {
        let state = true;
        if (mySet.has(id)) { state = false; mySet.delete(id); } else { mySet.add(id); }
        const newBtns = Array.from(mySet).join('|');
        console.log(`Section [${props.dataID}]: Toggle button[${id}] => ${state}`, newBtns);
        props?.onChange({ [props.dataID]: newBtns });
    }

    return (<>
        <Box sx={{
            margin: 0, padding: 1, display: 'flex', justifyContent: 'space-around', width: '100%',
        }}>
            {props?.tButton?.map((b, i) => {
                return (
                    <Button
                        key={`but_${b.id}_${i}`}
                        //sx={{ my: 1 }}
                        //color='success'
                        variant={mySet.has(b.id) ? 'contained' : 'outlined'}
                        //variant='contained'
                        onClick={() => { btnToggle(b.id) }}
                    >{b?.label}</Button>
                )
            })}
        </Box>
    </>);
}

function CantSection(props) {
    return (<>
        {/*<Box>{`[${props.index}]: "${JSON.stringify(props.info)}"`}</Box>*/}
        <Box sx={{
            backgroundColor: props.info?.bgColor || '#eee',
            border: 1, borderColor: '#999', borderRadius: 1, my: 2,
        }}>
            <Box sx={{
                backgroundColor: props.bgColor || '#eee', borderRadius: 1,
                display: 'inline-block', border: 1, borderColor: '#666',
            }}>
                <Typography component='span' sx={{ color: '#fff', p: 1 }}>
                    {props.info.sLabel} - (Column {props.colIndex + 1})
                </Typography>
            </Box>
            <Box sx={{ p: 1, whiteSpace: "pre-wrap" }}>{props.info.sText}</Box>
            <CantSectionButtons
                tButton={props.info?.tButton}
                dataID={props.dataID + '_btn'} // our data object key name
                data={props.dataBtns}  // our question "answer"
                onChange={props.onChange} // passing down func to answer question
            />
            {props?.asurvset?.isLoaded &&
                <CantSectionDropDown
                    dropDown={props.info?.dropDown}
                    dataID={props.dataID} // our data object key name
                    data={props.dataDrop}  // our question "answer"
                    onChange={props.onChange} // passing down func to answer question
                    asurvset={props.asurvset}
                />
            }
        </Box>
    </>);
}

function CantColumn(props) {
    // rainbowy color of the column header for graphical niceness
    const [bgColor] = useState(randColor());
    // open or collapse ?
    const isOpen = props?.open || false;
    const
        isSpinnable = props?.data?.spin || false,
        note = props?.data?.n || '';
    //base = props?.data?.b || '',
    //column = props?.data?.c || '',
    //arm = props?.data?.a || '',
    //crossbrace = props?.data?.cb || '';

    return (
        <Box sx={{ border: 1, borderColor: '#999', borderRadius: 1, my: 1, }}>
            <Box sx={{
                backgroundColor: bgColor, borderBottom: 2, borderColor: '#666', margin: 0, padding: 1, overflow: 'hidden',
                display: 'flex', justifyContent: 'space-between', width: '100%',
            }}>
                <Button
                    sx={{ color: '#fff' }}
                    onClick={() => { props?.onToggle(); }}
                >
                    Column {props.index + 1}
                </Button>
                <IconButton
                    edge="start"
                    onClick={() => { props?.onToggle(); }}
                    aria-label="close"
                >
                    {isOpen
                        ? <KeyboardArrowUpIcon htmlColor="#fff" />
                        : <KeyboardArrowDownIcon htmlColor="#fff" />}
                </IconButton>
            </Box>

            {isOpen && (<Box sx={{ mx: 1, my: 1 }}>
                {/*
                <Box sx={{ mx: 1, my: 1 }}>
                    <FormControlLabel
                        control={<Switch
                            checked={isSpinnable}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={() => { props.onChange({ spin: !isSpinnable }); }}
                        />}
                        label={`Column can be spun? ${isSpinnable ? 'Yes.' : 'No.'}`}
                    />
                </Box>
                */}

                {props.section.map((s, i) => {
                    return (
                        <CantSection
                            key={`cs_${s.SID}_${i}`}
                            info={s}
                            dataID={s.SID} // the object key to alter
                            dataDrop={props?.data?.[s.SID] || null} // "Data" is our question answers. This was a bad thing to call it LOL
                            dataBtns={props?.data?.[s.SID + '_btn'] || null} // "Data" is our question answers. This was a bad thing to call it LOL
                            index={i}
                            colIndex={props.index}
                            onChange={props.onChange} // passing down func to answer question
                            bgColor={bgColor}
                            asurvset={props.asurvset}
                        />
                    );
                })}

                <TextField
                    sx={{ my: 1 }}
                    //error
                    id="note"
                    label={`Note (Column ${props.index + 1})`}
                    fullWidth
                    //defaultValue="Hello World"
                    //helperText="Incorrect entry."
                    value={props?.note}
                    onChange={(e) => { props.onChange({ note: e.target.value }); }}
                />
            </Box>)}
        </Box>
    );
}

function reportCantileverRun(cantData, section) {
    let lineItem = [];

    function addLineItem(section, material, column, qty) {
        //console.log(`addLineItem: [${section}, ${material}]: [${column}]: ${qty}.`)
        // find if a lineitem exists already
        const newLineItem = lineItem.find(t => t.sec == section && t.mat == material);
        if (newLineItem) {
            // found, add to existing line item
            let curQty = newLineItem?.qty || 0;
            let colQtys = newLineItem?.col || [];
            let colQty = colQtys?.[column] || 0;
            newLineItem.qty = curQty + qty; // add to total qty
            colQtys[column] = colQty + qty; // add to column qty count
            newLineItem.col = colQtys;
        } else {
            let colQtys = [];
            colQtys[column] = qty; // set column qty count
            lineItem.push({ sec: section, mat: material, col: colQtys, qty: qty }); // add to lineItem
        }
    }

    // total up all the things - loop thru column answers
    cantData.map((cc, i) => {
        // loop thru sections
        section.map((ss, ii) => {
            // buttons
            const myButts = cc?.[`${ss.SID}_btn`]?.split('|') || []; // split apart the buttons
            myButts.map((bt, iii) => {
                addLineItem(ss.sLabel, bt, i, 1);  // changed ss.SID to sLabel
            });
            // dropDown
            const myDrop = cc?.[ss.SID] || {}; // split apart the buttons
            for (const [dd, qty] of Object.entries(myDrop)) {
                //console.log(`${dd}: ${qty}`);
                if (qty) { addLineItem(ss.sLabel, dd, i, qty); }  // changed ss.SID to sLabel
            }
        });
    });

    // now we should have our totals object! sort it now.
    lineItem.sort((a, b) => {
        if (a.sec > b.sec) return 1;
        if (a.sec < b.sec) return -1;
        if (a.mat > b.mat) return 1;
        if (a.mat < b.mat) return -1;
        return 0;        
    })

    return lineItem;
}

function TestFeaturePage(props) {
    //const users = useSelector(state => state.users);
    const auth = useSelector(state => state.authentication);
    const Surveys = useSelector(state => state.Surveys);
    const asurvset = useSelector(state => state.adminSurveySettings);
    const dispatch = useDispatch();

    // the ID of the survey we are working on
    const [viewSurvey, setViewSurvey] = useState('');
    const [ccError, setCcError] = useState(false);
    //const [columnCount, setColumnCount] = useState(1);
    const [columnLocation, setColumnLocation] = useState('01-001');
    const [columnData, setColumnData] = useState([undefined]); // array of 1 empty object
    const [columnOpen, setColumnOpen] = useState([false]); // array of boolean object
    const columnCount = columnData?.length || 0;
    const [runDesc, setRunDesc] = useState('');

    // Load our adminSurveySettings
    useEffect(() => {
        dispatch(assActions.getAll());
    }, []);

    const mySurvey = asurvset?.isLoaded ? asurvset?.Survey?.find(ss => ss.SType == "HDC") : null; // find cantilever survey
    const mySection = mySurvey?.Data?.Section || [];

    // this should be called only from tryUpdateCCount. 
    // it will NOT warn of data loss, but simply do the thing.
    function doUpdateCCount(count) {
        const newCount = Math.round(Number(count));
        const countChange = newCount - columnCount;
        if (newCount >= 1 && newCount <= 999) {
            setCcError(false); // clear error message if visible
            console.log(`Update columns[${columnCount}] to length ${newCount}. Change = ${countChange}.`);
            let newColumnData = columnData.slice(0, newCount);
            let newColumnOpen = columnOpen.slice(0, newCount);
            // determine how many elements need to be added to the array 
            const pushCount = newCount - newColumnData.length;
            console.log(`newColumnData[${newColumnData.length}] add on ${pushCount} elements.`)
            for (let i = 0; i < pushCount; i++) {
                newColumnData.push(undefined);
                newColumnOpen.push(false); // TODO: let us choose default?
            }
            // set our new arrays Data and Open            
            setColumnData(newColumnData);
            setColumnOpen(newColumnOpen);
            //console.log(newColumnData);
            //setColumnCount(newCount);
        } else {
            setCcError(true); // enable error message that column count is wrong
        }
    }

    // this is what should be called, it will validate the count is a number,
    // and pop up a warning prompt if data loss will happen.
    function tryUpdateCCount(count) {
        const newCount = Math.round(Number(count));
        const countChange = newCount - columnCount;
        if (newCount >= 1 && newCount <= 999) {
            setCcError(false); // clear error message if visible
            console.log(`Update columns[${columnCount}] to length ${newCount}. Change = ${countChange}.`);
            let newColumnData = columnData.slice(0, newCount);
            // determine how many elements need to be added to the array 
            const pushCount = newCount - newColumnData.length;
            console.log(`newColumnData[${newColumnData.length}] add on ${pushCount} elements.`)
            for (let i = 0; i < pushCount; i++) {
                newColumnData.push(undefined);
            }

            setColumnData(newColumnData);
            console.log(newColumnData);
            //setColumnCount(newCount);
        } else {
            console.log(`Update columns: Invalid argument: "${count}".`);
            setCcError(true); // enable error message that column count is wrong
        }
    }

    function updateColumn(index, data) {
        console.log(`Updating column[${index}]: "${JSON.stringify(data)}".`);
        setColumnData((currentColumnData) => {
            let newColumnData = [...currentColumnData];
            newColumnData[index] = { ...currentColumnData[index], ...data };
            return newColumnData;
        });
    }

    function toggleColumn(index) {
        setColumnOpen((currentColumnOpen) => {
            let isOpen = false;
            isOpen = currentColumnOpen?.[index] ? true : false;
            let newColumnOpen = [...currentColumnOpen];
            newColumnOpen[index] = !isOpen;
            console.log(`Toggle column[${index}]: "${isOpen}".`);
            return newColumnOpen;
        });
    }

    //const answer = {};

    return (
        <div>
            {/*<pre>{JSON.stringify(asurvset)}</pre>*/}
            <Box>
                <h3>Cantilever Survey</h3>
            </Box>
            <Box sx={{ pb: 1 }}>
                <TextField
                    //error
                    id="location"
                    label="Location (Bay Number)"
                    //defaultValue="Hello World"
                    //helperText="Incorrect entry."
                    value={columnLocation}
                    onChange={(e) => { setColumnLocation(e.target.value); }}
                />{' '}
                <TextField
                    id="columnCount"
                    label="Columns"
                    type="number"
                    //InputLabelProps={{ shrink: true, }}
                    value={columnCount}
                    onChange={(e) => { tryUpdateCCount(e.target.value); }}

                />
                {ccError &&
                    <Typography component={'span'} sx={{ fontWeight: 'bold', color: 'red', height: '100%', px: 1, verticalAlign: 'center', }}>
                        Column count must be a number between 1 and 999.
                    </Typography>
                }
            </Box>
            <Box sx={{ borderBottom: 2, borderColor: '#666', my: 1, pb: 1 }}>
                <TextField
                    //error
                    id="description"
                    label="Description"
                    fullWidth
                    //defaultValue="Hello World"
                    //helperText="Incorrect entry."
                    value={runDesc}
                    onChange={(e) => { setRunDesc(e.target.value); }}
                />
            </Box>
            <Box>
                <h3>Columns</h3>
            </Box>
            {columnData.map((cc, i) => (
                <CantColumn
                    key={`k_${i}`}
                    index={i}
                    data={cc}
                    open={columnOpen[i]}
                    onChange={(data) => { updateColumn(i, data); }}
                    onToggle={() => { toggleColumn(i); }}
                    asurvset={asurvset}
                    section={mySection}
                />
            ))}

            <Box sx={{ borderTop: 2, borderColor: '#666', my: 1, pb: 1 }}>
                <Button
                    sx={{ my: 1 }}
                    color='success'
                    //variant={viewBayType == 'c' ? 'contained' : 'outlined'}
                    variant='contained'
                    onClick={() => { console.log(`Saving not yet implemented.`); }}
                >Save Cantilever Run Survey</Button>
            </Box>

            <Box sx={{ borderTop: 2, borderColor: '#666', my: 1, pb: 1 }}>
                {/*<Typography variant='h3'>Totals</Typography>*/}
                <Typography variant='h6'>Totals</Typography>
                {/*<pre style={{ fontSize: 12 }}>{JSON.stringify(reportCantileverRun(columnData, mySection), null, ' ')}</pre>*/}
                <TotalsTable
                    data={reportCantileverRun(columnData, mySection)}
                    cols={columnCount}
                />
            </Box>

            <Box sx={{ borderTop: 2, borderColor: '#666', my: 1, pb: 1 }}>
                <Typography variant='h6'>Data</Typography>
                {/*<pre style={{ fontSize: 12 }}>{JSON.stringify(reportCantileverRun(columnData, mySection), null, ' ')}</pre>*/}
                <pre style={{ fontSize: 8 }}>{JSON.stringify(columnData, null, ' ')}</pre>
            </Box>

            {/*
            <Box sx={{ borderTop: 2, borderColor: '#666', my: 1, pb: 1 }}>
                <pre style={{ fontSize: 8 }}>{JSON.stringify(columnData, null, ' ')}</pre>
                <pre style={{ fontSize: 8, color: '#800' }}>{JSON.stringify(asurvset?.HDArticle?.[0], null, ' ')}</pre>
                <p>Articles: {asurvset?.HDArticle?.length}</p>
            </Box>
            */}
        </div>
    );
}

export { TestFeaturePage };