import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { appPermissions } from './apppermission.reducer';
import { adminSurveySettings } from './adminsurveysettings.reducer';
import { Missions } from './mission.reducer';
import { Surveys } from './survey.reducer';
import { webMissionView } from './webmissionview.reducer';

// combined reducers!
const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    appPermissions,
    adminSurveySettings,
    Missions,
    Surveys,
    webMissionView,
});
export default rootReducer;
