// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';

export const MissionService = {
    getAllMissions,
    open,
    doclose,
    create,
};

// return authorization header with jwt token

function authHeader(user) {
    return ((user && user.token) ? { 'Authorization': 'Bearer ' + user.token } : {});
}


function getAllMissions(user) {

    
    const requestOptions = {
        method: 'GET',
        headers: authHeader(user)
    };
    return fetch(`${Config.apiUrl}/api/Mission`, requestOptions).then(handleResponse); 
}

function open(Id, user) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(user)
    };
    return fetch(`${Config.apiUrl}/api/Mission/${Id}`, requestOptions).then(handleResponse);

}

function doclose(missionInfo, user) {

    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(user), 'Content-Type': 'application/json' },
        body: JSON.stringify(missionInfo),
    };
    return fetch(`${Config.apiUrl}/api/Mission/${missionInfo.id}`, requestOptions).then(handleResponse);
    
}

// Create new mission to begin surveying.
function create(missionInfo, user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(user), 'Content-Type': 'application/json' },
        body: JSON.stringify(missionInfo)
    };

    // TODO: change this back to HandleResponse
    return fetch(`${Config.apiUrl}/api/Mission`, requestOptions).then(handleResponse); 
}


// removed automatic logout if auth fails. not sure if it should be implemented here.
function handleResponse(response) {
    return response.text().then(text => {
        // const data = text;// && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            //console.log("Response: ERR! " + JSON.stringify(response));
            return Promise.reject(text);
        }
        
        if (response.ok) {
            if (response.status === 204) {
                // 204 == OK, BUT NO BODY RETURNED
                return Promise.resolve("");
            }
        }


        let data = JSON.parse(text);
        //console.log("Response: OK! " + JSON.stringify(data));
        return Promise.resolve(data);
    });
}
