import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// materialui
import { Button, Accordion,
  AccordionSummary, AccordionDetails, Typography, Box, TextField, IconButton, Icon, } from '@mui/material';
import { ExpandMore, Sync } from '@mui/icons-material';

// redux
import { userActions } from '../_actions';
import { appPermissionActions } from '../_actions';

// dialog things

// forms - formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";
import { TestForm } from './TestForm';
import { AdminCreateUser } from './AdminCreateUser';
import { AdminEditUser } from './AdminEditUser2';
import { appPermissions } from '../_reducers/apppermission.reducer';

import { history } from '../_helpers';
import AdminUsersTable from './AdminUsersTable';

function UserEditPermissions(UserId) {

}

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  passWord: Yup.string().required("Required")
});

function AdminUsersPage() {
    // get what we need from the redux store
    const users = useSelector(state => state.users);
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getAll());
    }, []);

    function handleDeleteUser(id) {
        dispatch(userActions.delete(id));
    }

    function handleCreateUser(newUser) {
        dispatch(userActions.create(newUser));
    }

    // begin ensure user permissions loaded
    const reduxPerms = useSelector(state => state.appPermissions);
    if(reduxPerms?.isLoaded !== true) {
      // If we have not loaded our app permissions, and we haven't tried to load them, get it going.
      // ALSO: don't try if we attempted to load perms and they failed! Infinite loop of fail danger!
        if (!reduxPerms?.isLoading && !Boolean(reduxPerms?.error)) {        
          dispatch(appPermissionActions.getAll());
        }
      }
    // end ensure user permissions loaded

    // Begin dialog code
    const [isUserEditor, setUserEditor] = React.useState('closed');
    const [userEditorId, setUEID] = React.useState('');

    const handleOpenUserEditor = (idToEdit) => {
      console.log("setueid=" + idToEdit);
      setUEID(idToEdit);
      setUserEditor('open');
    };
  
    const handleCloseUserEditor = () => {
      setUserEditor('closing');
      setTimeout(() => { 
        setUserEditor('closed'); 
        dispatch(userActions.getAll());
      }, 1000); // This allows dialog closing animation to happen.
    };
    // end dialog code

    return (
        <div>
            <h3>App User Management</h3>
            <Accordion>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >Add User</Typography>
              </AccordionSummary>
              <AccordionDetails>            
                <AdminCreateUser 
                  onReload={() => { dispatch(userActions.getAll()); }} 
                />
              </AccordionDetails>
            </Accordion>
            {/**
            <h3>Users
              <IconButton 
                size="small"
                onClick={() => { dispatch(userActions.getAll()); }} 
                sx={{ marginLeft: 32, marginRight: 0 }}>
                <Sync />
              </IconButton>
            </h3>             
            */}
            <br />
            {users.loading && <em>Loading users...</em>}
            {reduxPerms?.isLoading  && <em>Loading permissions...</em>}
            {users.error && <span className="text-danger">ERROR: {users.error}</span>}
            {users.items &&
              <AdminUsersTable 
                title="Users"
                user={users.items}
                onDelete={(id, firstName, lastName) => { 
                  if(confirm(`Are you sure you wish to delete ${firstName} ${lastName}?`)) 
                  { handleDeleteUser(id); }
                }}
                onDeleteMulti={(ids) => { 
                  if(typeof ids !== 'object') { 
                    console.error('User admin page: onDeleteMulti passed bad value!')
                  } else {
                    if(confirm(`Are you sure you wish to delete ${ids.length} user${(ids.length !== 1 ? 's' : '')}?`)) // \n${ids.map(i => i)}
                    { ids.map(id => { handleDeleteUser(id); }); return true; } // return true IF we deleted, otherwise false.
                  }
                  return false;
                }}
                onEdit={(id) => { handleOpenUserEditor(id); }}
              />
            /*
                <ul>
                    {users.items.map((user, index) =>
                        <li key={user.id}>
                            {user.firstName + ' ' + user.lastName}
                            {
                                user.deleting ? <em> - Deleting...</em>
                                : user.deleteError ? <span className="text-danger"> - ERROR: {user.deleteError}</span>
                                : <span> - <a onClick={() => handleDeleteUser(user.id)} className="text-primary">Delete</a></span>
                            }
                            <span> - <a 
                              onClick={() => handleOpenUserEditor(user.id)} 
                              className="text-primary"
                              disabled={Boolean(!reduxPerms?.isLoaded)}
                              >Edit</a></span>
                        </li>
                    )}
                </ul>
                */
            }
            <Box margin={1}>
{/* 
              <Button color="primary" variant="contained" onClick={() => { userActions.logout(); history("/login"); }}>Logout</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenUserEditor(666)}
              >
                Popup
              </Button>
*/}
          </Box>

{/* 

      <Dialog open={isUserEditorOpen} onClose={handleCloseUserEditor} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit User </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sample Text
          </DialogContentText>
*/}

{(isUserEditor === 'open' || isUserEditor === 'closing') && (
  <AdminEditUser 
    open={(isUserEditor === 'open')} 
    // onReload={() => { dispatch(userActions.getAll()); }} 
    // userID={userEditorId} 
    user={users?.items?.find(u => (u?.id === userEditorId))}
    appPermissions={reduxPerms}
    onClose={handleCloseUserEditor} 
    ariaLabelledBy="form-dialog-title" 
  />
)}
{/* 

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserEditor} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseUserEditor} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
*/}

        </div>
    );
}



export { AdminUsersPage };