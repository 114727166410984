import config from 'config';
import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
    setPassword,
    register,
    create,
    getAll,
    getById,
    update,
    delete: _delete,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "userName":username, "password":password })
    };

    //console.log(`${config.apiUrl}/api/Auth/Login`);
    return fetch(`${config.apiUrl}/api/Auth/Login`, requestOptions)
        .then(handleResponseAndLog)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function setPassword(userid, password) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ "password": password })
    };

    //console.log(`${config.apiUrl}/api/Auth/Login`);
    return fetch(`${config.apiUrl}/api/ApplicationUser/SetPassword/${userid}`, requestOptions)
        .then(handleResponseAndLog);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    return true;
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    // TODO: change this back to HandleResponse
    return fetch(`${config.apiUrl}/api/ApplicationUser`, requestOptions).then(handleResponseAndLog);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/ApplicationUser/${id}`, requestOptions).then(handleResponseAndLog);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/api/ApplicationUser`, requestOptions).then(handleResponse); 
}

// this function is added to original version of services ...
function create(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    // TODO: change this back to HandleResponse
    return fetch(`${config.apiUrl}/api/ApplicationUser`, requestOptions).then(handleResponseAndLog);
}

function update(id, user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/api/ApplicationUser/${id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/ApplicationUser/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
function handleResponseAndLog(response) {
    return response.text().then(text => {
        console.log('!API:');
        console.log(response);
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data?.message) || response.statusText;
            console.log(`ERR: ${error}`);
            return Promise.reject(error);
        }

        const succ = (data?.message) || response.statusText;
        console.log(`SUCC: ${data}`);
        return data;
    });
}