import React, { useState } from "react";
import { Stack, Box, Paper, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InboxIcon from "@mui/icons-material/Inbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// Begin draggable things
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
// End draggable things

function DraggableList(props) {
    //const [quotes, setQuotes] = useState([1, 2, 3, 4, 5, 6, 7]);

    function onDragStart() {
        // Add a little vibration if the browser supports it.
        // Add's a nice little physical feedback
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    }

    function onDragEnd(result) {
        // combining item
        if (result.combine) {
            // super simple: just removing the dragging item
            const newItem = [...props.items];
            newItem.splice(result.source.index, 1);
            props.onChange(newItem);
            return;
        }

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        // index didn't change
        if (result.destination.index === result.source.index) {
            return;
        }

        // moved from source to destination index
        const newItem = reorder(
            props.items,
            result.source.index,
            result.destination.index,
        );

        props.onChange(newItem);
    }

    // simply does the delete
    function onDelete(id) {
        const newItem = [...props.items];
        props.onChange(newItem.filter(item => item.id !== id));
    }

    // begin: are you sure prompt
    /*
    const [areYouSure, setAreYouSure] = useState({ show: false, });
    const handleOpenDialog = (options) => { setAreYouSure({ ...options, show: true, }); }
    const handleCloseDialog = () => { setAreYouSure({ show: false, }); };
    */
    // end: are you sure prompt

    return (
        <React.Fragment>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId={props.listId}>
                    {(provided) => (
                        <Stack className={props.listId} {...provided.droppableProps} ref={provided.innerRef}>
                            {props.items.map((item, index) => {
                                const myDragId = props.itemKey({listId: props.listId, items: props.items, index: index, item: item, });
                                return (
                                    <Draggable
                                        //key={itm[props.itemIdProp]}
                                        key={myDragId}
                                        draggableId={myDragId}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <props.itemComponent
                                                {...props}
                                                provided={provided}
                                                //listId={props.listId}
                                                //items={props.items}
                                                item={item} 
                                                index={index}
                                                //allowDelete={props.allowDelete}
                                                //onDelete={onDelete}
                                            />
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>

            {/*
                    <Dialog
                    open={areYouSure?.show}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Confirm delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={handleCloseDialog} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                    */}
        </React.Fragment>
    );
}

function DraggableListItem(props) {
    return (
        <Paper
            sx={{
                p: 1, my: 0.5, display: 'flex', flexDirection: "row", justifyContent: "space-between", justifyItems: "center", alignItems: "center",
                userSelect: 'none', // prevent selecting text in drag+drop things (ugly blue highlighting, etc.)
            }}
            ref={props.provided.innerRef} {...props.provided.draggableProps} {...props.provided.dragHandleProps}
        >
            <Box component={"div"}>{props.item.label}</Box>
            <Box>
                {props.allowDelete &&
                    <DeleteOutlineIcon onClick={() => {
                        //alert(`hi! from "${props.item.label}" (${props.item.id})`);
                        let text = `Are you sure you want to delete the section \"${props.item.label}\"?\nAll data contained in that section (dropdowns etc.) will be lost!`;
                        if (confirm(text) == true) {
                            console.log(`User confirmed: delete HDC section: \"${props.item.label}\".`);
                            props.onDelete(props.item.id);
                        }
                    }} />
                }
            </Box>
        </Paper>
    );
}

export { DraggableList, DraggableListItem };
