// VERSION 1.1 - 2022-07-05
// AvailableSurveySettings defines a list of our Admin Survey Settings, 
// the data the payload contains, the data to display in the table,
// the data to collect in the form to create a new setting, YUP validation
// for the input form....
import * as Yup from 'yup';

const availableSurveySettings = [
    {           
        label: 'Surveys', // label is the human readable name of the AdminSurveySetting
        sClass: 'SUR',  // value is the internal label used in the database (SClass)
        sSubClassProp: 'SID', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'SID', dataType:'text', }, // Survey ID (sSubClass)
            { id:'SType', dataType:'text', }, // Survey Type (see surveyTypes below)
            { id:'Data', dataType:'text', }, // Question IDs pipe separated
            { id:'Name', dataType:'text', }, // Survey Name (label for humans to read)
            { id:'Version', dataType:'text', }, // Survey Version
            { id:'Comment', dataType:'text', }, // Author comments re: the survey
        ],
        columns: [
            { field: 'SID', name: 'SurveyID', },
            { field: 'SType', name: 'Survey Type', width: 300, }, // Survey Type (see surveyTypes below)
            { field: 'Data', name: 'Survey Data (Questions)', width: 500, },
            { field: 'Name', name: 'Survey Name', width: 300, editable: false, },
            { field: 'Version', name: 'Version', width: 150, },
            { field: 'Comment', name: 'Comment', width: 150, },
        ],
        addForm: [
            { type:'text', field: 'SID', name: 'SurveyID', },
            { type:'stype', field: 'SType', name: 'Survey Type', }, // type:'stype' = survey type picker!
            { type:'text', field: 'Data', name: 'Survey Data (Questions)', },
            { type:'text', field: 'Name', name: 'Survey Name', },
            { type:'text', field: 'Version', name: 'Version', },
            { type:'text', field: 'Comment', name: 'Comment', },
        ],
        // you MUST supply all fields in addForm to addInitialValues, or you will 
        // get a white screen of death from formik due to object properties not existing.
        addInitialValues: {
            SID: '', SType: '', Data: '', Name: '', Version: '', Comment: '',  
        },
        addValidation: Yup.object().shape({
            SID: Yup.string().required('Survey ID is required'),
            SType: Yup.string().required('Survey Type is required').matches(/(HDB|HDC)/, 'Survey Type is not valid'),
            Name: Yup.string().required('Name is required'),
            Version: Yup.string().required('Version is required'), 
            // Test: omitting this means no validation performed, I think!?
            //Comment: Yup.string().required('This is required'), 
        }),

    },
    {
        label: 'Questions', 
        sClass: 'SQU', 
        sSubClassProp: 'QID', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'QID', dataType:'text',},
            { id:'QType', dataType:'text',},
            { id:'QVis', dataType:'text',},  // Question visibility: seis, nseis, 
            { id:'Category', dataType:'text',},
            { id:'QText', dataType:'text',},
            { id:'QInfo', dataType:'text',},
            { id:'DropDown', dataType:'text',},
        ],
        columns: [
            { field: 'QID', name: 'QID', width: 120 },
            { field: 'QType', name: 'Question Type', width: 250, editable: false, },
            { field: 'QVis', name: 'Visibility', width: 250, editable: false, },
            { field: 'Category', name: 'Category', width: 350, },
            { field: 'QText', name: 'Question Text', width: 450, },
            { field: 'QInfo', name: 'Info Text', width: 450, },
            { field: 'DropDown', name: 'Drop Down (Materials)', width: 450, },
        ],
        addForm: [
            { type:'text', field: 'QID', name: 'QID', },
            { type:'qtype', field: 'QType', name: 'Question Type', },
            { type:'qvis', field: 'QVis', name: 'Visibility', },
            { type:'text', field: 'Category', name: 'Category', },
            { type:'mtext|2', field: 'QText', name: 'Question Text', },
            { type:'mtext|5', field: 'QInfo', name: 'Info Text', },
            { type:'hddd', field: 'DropDown', name: 'Drop Down (Materials)', },
        ],
        // you MUST supply all fields in addForm to addInitialValues, or you will 
        // get a white screen of death from formik due to object properties not existing.
        addInitialValues: {
            QID: '', QType: 1, QVis: '', Category: '', QText: '', QInfo: '', DropDown: '',  
        },
        addValidation: Yup.object().shape({
            QID: Yup.string().required('Question ID is required'),
            QType: Yup.string().required('Question type is required'),
            Category: Yup.string().required('Category is required'), 
            QText: Yup.string().required('Question Text is required'), 
            // QInfo: Yup.string().required('This is required'), 
            DropDown: Yup.string().required('DropDown is required'),  
        }),
    },
    { 
        label: 'HD Articles', 
        sClass: 'HD-A',
        sSubClassProp: 'Article', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'W', dataType:'text',},
            { id:'H', dataType:'text',},
            { id:'D', dataType:'text',},
            { id:'Article', dataType:'text',},
            { id:'Description', dataType:'text',},
            { id:'Steel', dataType:'text',},
            { id:'Color', dataType:'text',},
        ],
        columns: [
            { field: 'Article', name: 'Article', width: 200, },
            { field: 'Description', name: 'Description', width: 500, },
            { field: 'W', name: 'W', width: 42, },
            { field: 'H', name: 'H', width: 42, },
            { field: 'D', name: 'D', width: 42, },
            { field: 'Steel', name: 'Steel', width: 200, },
            { field: 'Color', name: 'Color', width: 200, },
        ],
        addForm: [
            { type:'text', field: 'Article', name: 'Article', },
            { type:'text', field: 'Description', name: 'Description', },
            { type:'hdsbrand', field: 'Steel', name: 'Steel', },
            { type:'hdscolor', field: 'Color', name: 'Color', },
            { type:'hddim', field: 'W', name: 'W', },
            { type:'hddim', field: 'H', name: 'H', },
            { type:'hddim', field: 'D', name: 'D', },

        ],
        addInitialValues: {
            Article: '', Description: '', Steel: '', Color: '', W: 0,  H: 0,  D: 0, 
        },
        addValidation: Yup.object().shape({
            Article: Yup.string().required('Article (CAN#) is required'),
            Description: Yup.string().required('Description is required'),
            Steel: Yup.string().required('Steel is required'),
            Color: Yup.string().required('Color is required'),
        }),
    },
    { 
        label: 'HD Store Locations', 
        sClass: 'HD-S', 
        sSubClassProp: 'Number', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'Number', dataType:'text',},
            { id:'Name', dataType:'text',},
            { id:'City', dataType:'text',},
            { id:'SteelType', dataType:'text',},
            { id:'Seismic', dataType:'text',},
            { id:'MarketName', dataType:'text',},
        ],
        columns: [
            { field: 'Number', name: 'Store Number (ID)', width: 120 },
            { field: 'Name', name: 'Store Name', width: 150, },
            { field: 'City', name: 'City', width: 150, },
            { field: 'SteelType', name: 'Steel Brand', width: 150, },
            { field: 'Seismic', name: 'Seismic', width: 150, },
            { field: 'MarketName', name: 'Market Name', width: 150, },
        ],
        addForm: [
            { type:'text', field: 'Number', name: 'Store Number (ID)', },
            { type:'text', field: 'Name', name: 'Store Name', },
            { type:'text', field: 'City', name: 'City', },
            { type:'text', field: 'SteelType', name: 'Steel Brand', },
            { type:'text', field: 'Seismic', name: 'Seismic', },
            { type:'text', field: 'MarketName', name: 'Market Name', },
        ],
        addInitialValues: {
            Number: '', Name: '', City: '',
            SteelType: '', Seismic: '', MarketName: '',
        },
        addValidation: Yup.object().shape({
            Number: Yup.string().required('Store Number (ID) is required'),
            Name: Yup.string().required('Name is required'),
            City: Yup.string().required('City is required'),
            SteelType: Yup.string().required('Steel brand is required'),
            Seismic: Yup.string().required('Seismic is required'),
            MarketName: Yup.string().required('Market name is required'),
        }),
    },
    { 
        label: 'HD Departments', 
        sClass: 'HD-D', 
        sSubClassProp: 'DeptNo', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'DeptNo', dataType:'text',},
            { id:'DeptName', dataType:'text',},
        ],
        columns: [
            { field: 'DeptNo', name: 'Dept Num', width: 120 },
            { field: 'DeptName', name: 'Department Name', width: 300, },
        ],
        addForm: [
            { type:'text', field: 'DeptNo', name: 'Department Number', },
            { type:'text', field: 'DeptName', name: 'Department Name', },
        ],
        addInitialValues: {
            DeptNo: '', DeptName: '',
        },
        addValidation: Yup.object().shape({
            DeptNo: Yup.string().required('Department Number is required'),
            DeptName: Yup.string().required('Department Name is required'),
            //LegacyID: Yup.string().required('LegacyID is required'),
        }),
    },
    { 
        label: 'HD Bay Aisles', 
        sClass: 'HD-B', 
        sSubClassProp: 'AisleID', // the field in payload that maps to sSubClass (database column)
        payload: [
            { id:'AisleID', dataType:'text',},
            { id:'AisleName', dataType:'text',},
            { id:'LegacyID', dataType:'text',},
        ],
        columns: [
            { field: 'AisleID', name: 'AisleID', width: 120 },
            { field: 'AisleName', name: 'Aisle Name', width: 150, },
            { field: 'LegacyID', name: 'Legacy ID', width: 150, },
        ],
        addForm: [
            { type:'text', field: 'AisleID', name: 'AisleID', },
            { type:'text', field: 'AisleName', name: 'Aisle Name', },
            { type:'text', field: 'LegacyID', name: 'Legacy ID (Old App)', },
        ],
        addInitialValues: {
            AisleID: '', AisleName: '', LegacyID: '',
        },
        addValidation: Yup.object().shape({
            AisleID: Yup.string().required('Aisle Number is required'),
            AisleName: Yup.string().required('Aisle Name is required'),
            //LegacyID: Yup.string().required('LegacyID is required'),
        }),
    },
];

const surveyTypes = [
    { stid: 'HDB', label: 'Home Depot Bay Survey', shortLabel: 'HD Bay' },
    { stid: 'HDC', label: 'Home Depot Cantilever Run', shortLabel: 'HD Cantilever' },
];

const questionTypes = [
    { qtid: 1, label: 'Standard Question /w Dropdown', },
    { qtid: 4, label: 'Branching Question (Can add extra questions to survey)', },
    { qtid: 2, label: 'Rating (1-5 scale used in Saw Scope)', },
    { qtid: 3, label: 'Yes / No Question', },
  ];

export { 
    availableSurveySettings,
    surveyTypes,
    questionTypes,
};