import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { 
    loggedIn: true, 
    loggingIn: false,
    loggingOut: false,
    error: null,
    user,
} : {

};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loggedIn: false,
                loggingOut: false,
                //user: action.user, //???
                user: state.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                loggingOut: false,
                loggedIn: true,
                user: action.user,
                error: null,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,
                loggedIn: false,
                //user: action.user,
                user: null,
                error: action.error,

            };
        case userConstants.LOGOUT:
            console.log(`redux: logout!`)
            return {
                loggingIn: false,
                loggedIn: false,
                user: null,

            };
        default:
            return state
    }
}