// Following line for reactJS (web)
import Config from 'config';
// Following line for react-native (iOS)
//import Config from 'react-native-config';

//var RNFS = require('react-native-fs'); // react-native only (not web!)

export const SurveyService = {
    downMissionSurveys,
    downMissionPhotos,
    getSurvey,
    postSurvey,
//    postSurveyPhoto,
};

function authHeader(user) {
    return user?.token ? { 'Authorization': 'Bearer ' + user.token } : {};
}

function downMissionSurveys(Id, user) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(user),
    };
    return fetch(`${Config.apiUrl}/api/Survey/GetMissionSurveys/${Id}`, requestOptions).then(handleResponse);
}

function downMissionPhotos(missionId, user) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(user),
    };
    return fetch(`${Config.apiUrl}/api/SurveyPhoto/MissionPhotos/${missionId}`, requestOptions).then(handleResponse);
}

function getSurvey(Id, user) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(user),
    };
    return fetch(`${Config.apiUrl}/api/Survey/${Id}`, requestOptions).then(handleResponse);
}

function postSurvey(survey, user) {
    // We've got to do a little change from the way we store surveys in this app to the API...
    // The app has no property Data it has SurveyData, and we need to JSON Strigify / parse it as it goes in/out
    // object destructuring! { sourceProperty: targetVariable }, see https://javascript.info/destructuring-assignment
    const { Data: pstData, ...pstSurvey } = survey;
    pstSurvey.SurveyData = JSON.stringify(pstData);
    // console.log("!!: " + JSON.stringify(pstSurvey))
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(user), 'Content-Type': 'application/json',},
        body: JSON.stringify(pstSurvey),
    };
    console.log(`postSurvey: "${Config.apiUrl}/api/Survey/", ${JSON.stringify(survey)}`);
    return fetch(`${Config.apiUrl}/api/Survey/`, requestOptions).then(handleResponse);
}

/*
function postSurveyPhoto(missionId, myDir, photoName, user) 
{
    return RNFS.readFile(myDir+'/'+photoName, 'base64')
//        .then((data) => console.log(data))
        .then((data) => {
            fetch(`${Config.apiUrl}/api/SurveyPhoto`, {
                method: "POST",
                headers: {...authHeader(user), 'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                    fileName: photoName, 
                    missionId: Number(missionId),
                    contentType: "application/json",
                    base64Payload: data,
                })
            })})
        //.then(response => response.json())
        .then(response => {
            console.log("upload succes", JSON.stringify(response));
            //alert("Upload success!");
            //this.setState({ photo: null });
            return Promise.resolve(response);
        })
        .catch(error => {
            console.warn(`Photo upload error! ${JSON.stringify(error)}`, error);
            //alert("Upload failed!");
            return Promise.reject(error);
        });
}
*/

// removed automatic logout if auth fails. not sure if it should be implemented here.
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return Promise.resolve(data);
    });
}

