import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//import { userActions } from '../_actions';
import { assActions } from '../_actions';

// form stuff - this might also be what's breaking MUI styling... hmm
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { KForm } from './KForm';

// mui controls must be imported AFTER DataGrid, or the grid breaks styling i.e. buttons!!
import { DataGrid } from '@mui/x-data-grid';
// materialUI stuff (theme)
import {
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    Button,
    Typography,
    Dialog,
    Slide,      // For Transition (sliding in full page modal dialog)
    AppBar,
    Toolbar,
    IconButton,
} from '@mui/material';
// material icons!
import CloseIcon from '@mui/icons-material/Close';
// slide in full screen dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

import { availableSurveySettings } from '../_helpers/AdminSurveySettingData';


const AdminSurveySettingPage = () => {
    const users = useSelector(state => state?.users);
    const surveySetting = useSelector(state => state?.adminSurveySettings);
    const auth = useSelector(state => state?.authentication);
    const dispatch = useDispatch();

    const [viewSetting, setViewSetting] = useState('');

    // Datagrid things
    const [pageSize, setPageSize] = React.useState(50);
    const pageSizeOptions = [25, 50, 75, 100]; // cannot exceed a pageSize of 100 in DataGrid without Pro!
    const [mySelected, setMySelected] = useState(null);
    const [initialV, setInitialV] = useState(false);

    // Dialog for editing an admin survey setting 
    const [editorOpen, setEditorOpen] = React.useState(false);
    const handleEditorClose = (refreshMe = false) => {
        setEditorOpen(false);
        // auto refresh! when we close the editor, reload stuff!
        if (refreshMe) { // wait 300ms to prevent screwing up the form.
            setTimeout(() => { dispatch(assActions.getAll()); }, 300);
        }
    };

    // const handleChange = (event) => {      setViewSetting(event.target.value);    };

    const mySurveySetting = availableSurveySettings.find(ass => ass.sClass === viewSetting);

    let myColumns = [], myRows = [];
    // this is the table data, we only need to populate when not adding a new item
    if (mySurveySetting) {
        myColumns = mySurveySetting?.columns || []; // returning empty array if no columns are defined prevents white page of death
        surveySetting?.setting?.map((ff) => {
            if (ff.sClass === viewSetting) { myRows.push({ ...ff.payload, id: ff.id, [mySurveySetting?.sSubClassProp]: ff.sSubClass, }); }
        });
    }
    // console.log(myRows);

    useEffect(() => {
        dispatch(assActions.getAll());
    }, []);

    /*
    function handleDeleteUser(id) {
        dispatch(userActions.delete(id));
    }
    */

    const sSubClass = mySurveySetting?.sSubClassProp; // the property in payload that maps to sSubClass

    const editorTitle = initialV ? `Editing ${mySurveySetting?.label}: ${mySelected?.Name} (id: ${mySelected?.id})` : `Adding New: ${mySurveySetting?.label}`;
    const EditorAppBar = (props) => {
        let myPos = 'sticky', mySx = undefined;
        if (props?.bottom) { myPos = 'fixed', mySx = { top: 'auto', bottom: 0 }; }
        return (<>
            {props?.bottom && <div style={{ height: 90 }} />}
            <AppBar position={myPos} sx={mySx} /*sx={{ position: 'relative' }}*/ >
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {props?.title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleEditorClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>)
    };

    return (
        <>
            {/* Combining react-router link and materialui button */}
            <p><Button color="primary" variant="outlined" component={Link} to="/adminsurveysettingbulkdata">Bulk Data Entry</Button></p>
            {/*
            <p><Button color="primary" variant="outlined" onClick={() => { alert('This feature is coming soon.')}}>Bulk Data Entry</Button></p>
            */}
            <h3>Survey Settings</h3>

            <FormControl fullWidth variant='outlined'>
                <InputLabel id="pickerlabel-view-setting">Choose Survey Setting</InputLabel>
                <Select
                    labelId="pickerlabel-view-setting"
                    id="picker-view-setting"
                    label='Choose Survey Setting'
                    value={viewSetting}
                    onChange={e => {
                        setViewSetting(e.target.value);
                        setEditorOpen(false);  // if we change what we're viewing with additem open, close it
                    }}
                >
                    {availableSurveySettings.map((ass, i) => (
                        <MenuItem key={'a_' + i} value={ass.sClass}>{ass.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <p>Selected: [{viewSetting}][{JSON.stringify(mySurveySetting)}]</p> */}

            {(mySurveySetting &&
                <>
                    {/*<Box sx={{ fontSize: 8 }}>Selected: [{viewSetting}][{JSON.stringify(mySurveySetting)}]</Box>*/}
                    <h3>{mySurveySetting?.label}</h3>
                    <p>
                        {/* Add new admin survey setting button - flips appearance when adding or viewing table */}
                        <Button
                            variant="outlined"
                            color={'success'}
                            disabled={editorOpen}
                            onClick={() => {
                                setInitialV(false); // InitialV - use default initial values form AdminSurveySettingData!
                                setEditorOpen(true);
                            }}
                        >+ Add New</Button>
                        &nbsp;
                        {(editorOpen &&
                            <Button
                                variant="outlined"
                                color={'error'}
                                onClick={() => { setEditorOpen(!editorOpen) }}
                            >Cancel</Button>
                        )}
                        &nbsp;
                        {/* Refresh button - only visible when viewing table */}
                        {(!editorOpen &&
                            <Button
                                variant="outlined"
                                color='primary'
                                onClick={() => { dispatch(assActions.getAll()) }}
                            >Refresh</Button>
                        )}
                        &nbsp;
                        {/* Refresh indicator spinning arrow or w/e */}

                        {/* Edit button - when one table item is selected */}
                        {(mySelected &&
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={() => {
                                    setInitialV(true); // InitialV - editing values from the table!
                                    setEditorOpen(true);
                                }}
                            >Edit</Button>
                        )}
                        &nbsp;

                    </p>

                    <div style={{ height: 600, width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%', }}>
                            <div style={{ flexGrow: 1, }}>
                                <DataGrid
                                    rows={myRows}
                                    columns={myColumns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    // onStateChange={(sel) => { console.log(`Selection: ${JSON.stringify(sel)}`); }}

                                    onSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        const selectedRowData = myRows.filter((row) => selectedIDs.has(row.id));
                                        console.log('Row selection (' + ids.length + '): ' + JSON.stringify(selectedRowData));
                                        if (ids.length == 1) {
                                            setMySelected({ ...selectedRowData[0] });
                                        } else {
                                            setMySelected(null);
                                        }
                                    }}

                                    rowsPerPageOptions={pageSizeOptions}
                                    checkboxSelection
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>
                    </div>

                    <Dialog
                        fullScreen
                        open={editorOpen}
                        onClose={handleEditorClose}
                        TransitionComponent={Transition}
                    >
                        <EditorAppBar title={editorTitle} />
                        <Box sx={{ height: 'auto', width: '100%', padding: 2, }}>
                            {/*mySurveySetting?.fields?.map((f,i) => (<p>{`${i}: ${JSON.stringify(f)}`}</p>))*/}
                            <KForm
                                initialValues={initialV ? mySelected : mySurveySetting?.addInitialValues}
                                validationSchema={mySurveySetting?.addValidation}
                                formFields={mySurveySetting?.addForm}
                                onSubmit={(values) => {
                                    // object destructuring extracts our prop that maps to sSubClass,
                                    // and the rest of the object copies to myPayload.
                                    const { [sSubClass]: mySSubClass, ...myPayload } = values;
                                    // and thus our form values get mapped to myAss, shaped to our database structure.
                                    const myAss = {
                                        "sClass": viewSetting,
                                        "sSubClass": mySSubClass,
                                        "payload": myPayload,
                                    };
                                    //alert(JSON.stringify(myAss, null, 2));
                                    dispatch(assActions.create(myAss));
                                }}
                                onClose={handleEditorClose}
                            />
                            {/**<pre>{JSON.stringify(mySelected)}</pre>
                            <pre>{JSON.stringify(mySurveySetting)}</pre>*/}
                            {/**<EditorAppBar bottom />*/}
                        </Box>
                    </Dialog>
                </>)}

        </>
    );
}

export { AdminSurveySettingPage };
