import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { userActions } from '../_actions';
import { 
    TextField, Button, Box,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper,
} from '@mui/material';

import { availableSurveySettings } from '../_helpers/AdminSurveySettingData';
import { assActions } from '../_actions';

function AdminSurveySettingBulkDataPage() {
    //const users = useSelector(state => state.users);
    //const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    const [grid, setGrid] = useState('');

    // break apart our "grid" input into a table of javascript objects, for displaying and also submitting...
    const myRow = typeof grid === 'string' && grid.length > 16 ? grid.split(/\r\n|\n\r|\n|\r/) : null;  // split by:     \r\n  \n\r  \n  or  \r - splits string by newlines, all operating systems
    let myTable = { h:null, r:[], rows:0, errors:0, warnings:0, error:null };

    function addTheThings() {
        for (let i=0; i<myTable.r.length; i++) {
            const myAss = { 
                "sClass": myTable.r[i].sClass,
                "sSubClass": myTable.r[i].sSubClass,
                "payload": myTable.r[i].payload,
            };
            //alert(JSON.stringify(myAss, null, 2));
            console.log(`ADD: ${myAss}`)
            dispatch(assActions.create(myAss));
        }
    }

    function headerToCol() {
        let colList = {};
        for (let i=0; i<myTable.h.length; i++) {
            colList[ myTable.h[i] ] = i;
        }
        myTable = { ...myTable, headerCol: colList };
        //console.log(`myTable header columns: ${JSON.stringify(myTable.headerCol)}`)
        return;
    }
    function findEmpties(array) {
        let result = [];
        array.map((str, i) => {
            if (str?.length < 1) {
                result.push(i+1);
            }
        });
        return result;
    }
    function findDuplicates(array) {
        let count = {}, result = [];        
        array.map(item => {
            if (count[item]) {
                count[item] += 1;
            } else {
                count[item] = 1;
            }
        });
        for (let prop in count) {
            if (count[prop] >=2){
                result.push(prop)
            }
        }        
        //console.log(count)
        return result;        
    }
    // function to do a validation of a row - is it a valid SurveySetting?
    function validate(surveySetting) {
        // TODO: later, should probably add YUP validation!
        // Grab the value from our SClass column. Error if empty.
        const mySClass = surveySetting[myTable.headerCol['SClass']];
        if (!mySClass) {
            return {
                item: surveySetting,
                error: 'Missing SClass!',
                warn: null,
            };
        }
        // Now locate the AvailableSurveySetting from our SClass. Error if not found.
        const mySurveySetting = availableSurveySettings.find(ass => ass.sClass === mySClass);
        if (!mySurveySetting) {
            return {
                item: surveySetting,
                error: `Invalid SClass: "${mySClass}"!`,
                warn: null,
            };
        }
        // Now make sure we have all the columns required 
        let missingColumn = [];
        mySurveySetting.payload.map(p => { 
            if(!myTable.headerCol[p.id]) {
                missingColumn.push(p.id);
            }
        });
        if (missingColumn.length > 0) {
            return {
                item: surveySetting,
                error: `Column${missingColumn.length == 1 ? '' : 's'} missing: "${missingColumn.join(', ')}".`,
                warn: null,
            };
        }
        // vars to collect results of our row
        let payloadItem = {}, missingItem = [], unexpectedItem = [];
        // looping thru the header, prop="columnName", coln = (0 .. len-1)
        for (const [prop, coln] of Object.entries(myTable.headerCol)) {
            //console.log(`${key}: ${value}`);
            // skip SClass because we got that value already.
            if (prop !== 'SClass') {
                const isExpected = mySurveySetting.payload.find(p => p.id == prop);
                if (isExpected) {
                    if (surveySetting[coln]) {
                        payloadItem[prop] = surveySetting[coln];
                    } else {
                        missingItem.push(prop);
                    }
                } else {
                    if (surveySetting[coln]) {
                        unexpectedItem.push(prop);
                    }
                }
            }
        }
        // phew! now we should have our payload, missing items, and unexpected items!
        // now we can return an object with all our data... of course first we destructure our SSubClass.
        const sSubClass = mySurveySetting.sSubClassProp;
        const { [sSubClass]:mySSubClass, ...myPayload } = payloadItem;

        return {
            item: surveySetting,
            sClass: mySClass,
            sSubClass: mySSubClass,
            payload: myPayload,
            error: missingItem.length > 0 ? `Error: Missing data: "${missingItem.join(', ')}".` : null,
            warn: unexpectedItem.length > 0 ? `Warning: Ignoring unexpected data: "${unexpectedItem.join(', ')}".` : null,
        };
    }    
    // use textArea input to generate myTable
    if (myRow && typeof myRow === 'object' && myRow.length > 1)
        for (let i = 0; i < myRow.length; i++) { 
            // only process a row with content, blank rows get skipped.
            const tmp = myRow[i].replace(/\t/g, ''); // must use regex, replace('\t', '') doesn't work! IDK why!
            if (tmp.length > 0) {
                //console.log(`Row [${i}], length ${tmp.length}, "${tmp}".`)
                const VV = myRow[i].split('\t'); 
                // if we haven't read a valid header row, we expect our header! otherwise, we expect a row!
                if(!myTable.h) { 
                    const empties = findEmpties(VV);
                    if (empties.length>0) {
                        myTable.error = `Missing headers at columns: ${empties.join(', ')}`;
                        break; // bust out of map loop.
                    } 
                    const dupes = findDuplicates(VV);
                    if (dupes.length>0) {
                        myTable.error = `Duplicated columns (remove duplicates!): ${dupes.join(', ')}`;
                        break; // bust out of map loop.
                    }
                    // we must have headers SClass and SSubClass!
                    if (!VV.find(col => col == 'SClass')) {
                        myTable.error = `Missing column SClass!`; break;
                    }
                    /*
                    if (!VV.find(col => col == 'SSubClass')) {
                        myTable.error = `Missing column SSubClass!`; break;
                    }
                    */
                    // table header checks complete, looks good, initialize table!
                    myTable.h = VV;
                    // generate an object mapping header names to column numbers
                    headerToCol();
                } else { 
                    // store the row data in an array of objects which MAY include an error code
                    let vResult = validate(VV);
                    myTable.r.push(vResult); 
                    myTable.rows += 1;
                    if (vResult.error) { myTable.errors += 1; }
                    if (vResult.warn) { myTable.warnings += 1; }
                } 
            }
        };

    //console.log(myTable?.r)

    return (
        <div>
            {/* <p>{user.firstName} {user.lastName}</p> */}
            <p><Button color="primary" variant="outlined" component={Link} to="/adminsurveysetting">Back</Button></p>
            <h3>Bulk Data Entry</h3>
            {/* Combining react-router link and materialui button */}
            <p><Button color="success" variant="outlined" >Download Data (Excel)</Button></p>
            <p>Note: You cannot paste excel data if it contains cells with multiple lines! (i.e. Survey questions). 
                You must enter those one at a time for now. This message will be removed when it is fixed.</p>
            <img 
                src={`/img/cell-example.png`}
                //alt={'mutli line cell example png'}
            />

            <TextField
                id="filled-multiline-static"
                label="Bulk Data Entry - Paste From Excel - Include Headers"
                multiline
                fullWidth
                rows={8}
                variant="filled"
                value={grid}
                onChange={(e) => { setGrid(e.target.value); /*console.log(e.target.value);*/ }}
            />

            {/* TODO: Add datatype selector, validation status, and GO button here */}
            <Box sx={{ width: '100%', minHeight: 16, marginTop: 2, marginBottom: 2, 
                display: 'flex', justifyContent: 'space-between', }}>
                    <div>
                        <Button color="error" variant="outlined" 
                            onClick={() => { setGrid(''); }}
                        >Clear</Button>
                    </div><div>
                        Rows: {myTable.rows}
                    </div>
                    {myTable.warnings > 0 && 
                        <Box sx={{ color: '#990' }}>Warnings: {myTable.warnings}</Box>}
                    {myTable.errors > 0 && 
                        <Box sx={{ color: '#900' }}>Errors: {myTable.errors}</Box>}
                    <div>
                        <Button 
                            color="success" variant="outlined" 
                            disabled={!(myTable.h && myTable.rows > 0 && myTable.errors == 0)}
                            onClick={() => { addTheThings(); }}
                        >Add All</Button>
                    </div>
            </Box>
            {/* Show validation errors of table columns (no duplicates or missing headers allowed) */}
            { myTable.error &&
                <Box sx={{ width: '100%', minHeight: 5, bgcolor:'error.dark', color:'#fff', padding:1, borderRadius:1, }}
                >{myTable.error}</Box>
            }
            { myTable.h &&
                <Table sx={{ padding:1, borderCollapse: 'collapse', width: '100%', border:1, borderColor:'#aaa'}}>
                    <TableHead>
                    <TableRow>
                    <TableCell sx={{ padding:1, bgcolor: 'primary.main', color: '#fff' }}></TableCell>
                    {myTable.h.map((v,i) => (<TableCell 
                        sx={{ padding:1, bgcolor: 'primary.main', color: '#fff' }} 
                        key={`th_${i+1}`}
                    >{v}</TableCell>))}
                    </TableRow>
                    </TableHead><TableBody>
                    {myTable.r.map((v,i) => (
                        <React.Fragment key={`tFr_${i+1}`}>
                            <TableRow key={`tr_${i+1}`}>
                                <TableCell 
                                    sx={{ padding:1, borderColor:'#aaa'  }} 
                                    key={`td_n_${i+1}`}
                                    bgcolor={ 
                                        v.error ? "#fcc" :
                                        v.warn ? "#ffc" :
                                        Boolean(i%2) ? '#fff' : '#eee' 
                                    }
                                >{i+1}</TableCell>
                                {v?.item?.map((c, ii) => (<TableCell 
                                    sx={{ padding:1, borderLeft:1, borderColor:'#aaa' }} 
                                    key={`td_${ii+1}_${i+1}`}
                                    bgcolor={ 
                                        v.error ? "#fcc" :
                                        v.warn ? "#ffc" :
                                        Boolean(i%2) ? '#fff' : '#eee' 
                                    }
                                >{c}</TableCell>))}
                            </TableRow>
                            {v.warn && (
                                <TableRow key={`tWr_${i+1}`}>
                                    <TableCell 
                                        sx={{ padding:1, borderColor:'#aaa'  }} 
                                        key={`tWd_n_${i+1}`}
                                        bgcolor={"#ffc"}
                                    >{i+1}</TableCell>
                                    <TableCell 
                                        colSpan={myTable.h.length}
                                        sx={{ padding:1, borderColor:'#aaa'  }} 
                                        key={`tWd_m_${i+1}`}
                                        bgcolor={"#ffc"}
                                    >{v.warn}</TableCell>
                                </TableRow>
                            )}
                            {v.error && (
                                <TableRow key={`tEr_${i+1}`}>
                                    <TableCell 
                                        sx={{ padding:1, borderColor:'#aaa'  }} 
                                        key={`tEd_n_${i+1}`}
                                        bgcolor={"#fcc"}
                                    >{i+1}</TableCell>
                                    <TableCell 
                                        colSpan={myTable.h.length+1}
                                        sx={{ padding:1, borderColor:'#aaa'  }} 
                                        key={`tEd_m_${i+1}`}
                                        bgcolor={"#fcc"}
                                    >{v.error}</TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            }
        

        </div>
    );
}

export { AdminSurveySettingBulkDataPage };