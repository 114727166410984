import React, { useState, useEffect } from 'react';
import config from 'config';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, Field } from 'formik';

import { userActions } from '../_actions';

//import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Grid, 
    CardActions, CardContent, 
    Paper,
    LinearProgress,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Typography,
  } from '@mui/material';
import {
//    fieldToTextField,
    TextField,
    TextFieldProps,
    Select,
    Switch,
} from '@mui/material';

const LoginPage = () => {
    const isLoggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    // card styling react-materialui
    const bull = <span>•</span>;

    // reset login status
    /*    
        useEffect(() => {
            dispatch(userActions.logout());
        }, []);
    */



    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validate={(values) => {
                /* this form doesn't use Yup but instead manual validation. left in as an example */
                let errors = {};
                if (!values.username) {
                    errors.username = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
                ) {
                    errors.username = 'Invalid email address';
                }
                if (!values.password) {
                    errors.password = 'Required';
                } else if ( values.password.length < 8 ) {
                    errors.password = 'Too short, must be at least 8 characters.'
                } else if ( values.password.length > 64 ) {
                    errors.password = 'Too long, must be at most 64 characters.'
                } else {
                    let tmp = '';
                    if (!/\d/.test(values.password)) tmp += 'Needs a number (0-9). ';
                    if (!/[$-/:-?{-~!"^_`\[\]]/.test(values.password)) tmp += 'Needs a symbol (!, @, #, etc.). ';
                    if (!/[a-z]/.test(values.password)) tmp += 'Needs a lowercase letter. ';
                    if (!/[A-Z]/.test(values.password)) tmp += 'Needs an UPPERCASE letter. ';
                    if (tmp.length > 0) { errors.password = tmp; }
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                /*
                setTimeout(() => {
                    setSubmitting(false);
                    alert(JSON.stringify(values, null, 2));
                }, 500);
*/

                setSubmitting(true);
                if (values.username && values.password) {
                    // get return url from location state or default to home page
                    const { from } = location.state || { from: { pathname: "/" } };
                    if (from == '/login') { from = '/'; }
                    dispatch(userActions.login(values.username, values.password, from));
                }
                setSubmitting(false);
            }}
        >
            {({ values, touched, errors, isSubmitting,
                handleChange, handleBlur, submitForm, }) => (
                <Grid 
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '80vh' }}
                >
                    <Grid item xs={4}>

                        <Form>
                            <Paper sx={{ width: '60%', minWidth: 480, }} elevation={3}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Login
                                    </Typography>
                                </CardContent>
                                <CardContent>
                                    <Box margin={1}>
                                        <TextField
                                            name="username"
                                            type="email"
                                            label="Username"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.username && Boolean(errors.username)}
                                            helperText={touched.username && errors.username}
                                        />
                                    </Box>
                                    <br />
                                    <Box margin={1}>
                                        <TextField
                                            type="password"
                                            label="Password"
                                            name="password"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                    </Box>
                                    {/* Removing "remember me" because idk what it does, if anything at all  */}
                                    {/*
                                    <Box margin={1}>
                                        <FormControlLabel
                                            control={
                                                <Field component={Switch} type="checkbox" name="rememberMe" />
                                            }
                                            label="Remember Me"
                                        />
                                    </Box>
                                    */}
                                </CardContent>
                                <CardActions>
                                    <Box margin={1} sx={{ display: 'flex',
          justifyContent: 'flex-end',           p: 1,
          m: 1, }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isLoggingIn}
                                            onClick={submitForm}
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </CardActions>
                            </Paper>

                        </Form>
                        <p>{`API URL: "${config.apiUrl}".`}</p>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}

export { LoginPage };