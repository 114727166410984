export const MissionConstants = {
    GETALL_DOIT: 'MISSIONS_GETALL_DOIT',
    GETALL_SUCC: 'MISSIONS_GETALL_SUCC',
    GETALL_FAIL: 'MISSIONS_GETALL_FAIL',
    
    CREATE_DOIT: 'MISSIONS_CREATE_DOIT',
    CREATE_SUCC: 'MISSIONS_CREATE_SUCC',
    CREATE_FAIL: 'MISSIONS_CREATE_FAIL',

    OPEN_DOIT: 'MISSIONS_OPEN_DOIT',
    OPEN_SUCC: 'MISSIONS_OPEN_SUCC',
    OPEN_FAIL: 'MISSIONS_OPEN_FAIL',

    CLOSE_DOIT: 'MISSIONS_CLOSE_DOIT',
    CLOSE_SUCC: 'MISSIONS_CLOSE_SUCC',
    CLOSE_FAIL: 'MISSIONS_CLOSE_FAIL',
};