import { appPermissionConstants } from '../_constants';
import { appPermissionService } from '../_services';


export const appPermissionActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        appPermissionService.getAll()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: appPermissionConstants.GETALL_DOIT } }
    function success(data) { return { type: appPermissionConstants.GETALL_SUCC, data } }
    function failure(error) { return { type: appPermissionConstants.GETALL_FAIL, error } }
}
