import { appPermissionConstants } from '../_constants';

export function appPermissions(state = {}, action) {
    switch (action.type) {
        case appPermissionConstants.GETALL_DOIT:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                perm: [],
                error: null,
            };
        case appPermissionConstants.GETALL_SUCC:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                perm: action.data,
                error: null,
            };
        case appPermissionConstants.GETALL_FAIL:
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                perm: [],
                error: action.error,
            };
        default:
            return state
    }
}