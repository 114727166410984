import { SurveyConstants } from '../_constants';
import { SurveyService } from '../_services';

export const SurveyActions = {
    downMissionSurveys,
    downMissionPhotos,
    initCurrent,
    delCurrent,
    updateCurrent,
    saveCurrent,
    resetSurveysDev,
    syncSendPhoto,
    syncSendSurvey,
    sliceSurvey,
    delSurvey,
    photoAdd,
    photoDel,
    photoSet,
};

function downMissionSurveys(id, user) {
    return dispatch => {
        dispatch(request());

        SurveyService.downMissionSurveys(id, user)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: SurveyConstants.GETALL_DOIT } }
    function success(data) { return { type: SurveyConstants.GETALL_SUCC, data } }
    function failure(error) { return { type: SurveyConstants.GETALL_FAIL, error } }
}

function downMissionPhotos(id, user) {
    return dispatch => {
        dispatch(request());

        SurveyService.downMissionPhotos(id, user)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: SurveyConstants.SYNC_GET_PHOTOS_DOIT } }
    function success(data) { return { type: SurveyConstants.SYNC_GET_PHOTOS_SUCC, data, missionId: id } }
    function failure(error) { return { type: SurveyConstants.SYNC_GET_PHOTOS_FAIL, error } }
}

function initCurrent(surveySettings) {
    return dispatch => {
        // console.log("!!: " +JSON.stringify(surveySettings));
        dispatch(initCurrentSurvey(surveySettings));
    };
    
    function initCurrentSurvey(data) { return { type: SurveyConstants.CURRENT_INIT, data } }
}

function delCurrent() {
    return function (dispatch) { dispatch({ type: SurveyConstants.CURRENT_DEL }); };
}

function updateCurrent(data, comment, secondsOpen) {
    return (dispatch) => { 
        let payload = data !== null ? { Data: data } : {};
        if (comment !== null) { payload = { ...payload, Comment: comment }; }
        if (secondsOpen !== null) { payload = { ...payload, SecondsOpen: SecondsOpen }; }
        dispatch({ 
            type: SurveyConstants.CURRENT_UPDATE,
            data: payload,
/*
            data: {
                data: data,
                comment: comment,
                secondsOpen: secondsOpen,
            },
*/
        }); 
    };
}
function saveCurrent() {
    return (dispatch) => { 
        dispatch({ type: SurveyConstants.CURRENT_SAVE }); 
    };
}

function resetSurveysDev() {
    return (dispatch) => { dispatch({ type: SurveyConstants.RESET_SURVEYS_DEV }); };
}

function syncSendPhoto(missionId, myDir, photo, user) {
    // PHOTO UPLOADER!!
    return dispatch => {
        dispatch(request( myDir+'/'+photo ));

        SurveyService.postSurveyPhoto(missionId, myDir, photo, user)
            .then(
                data => {
                    console.log("!!: " + JSON.stringify(data))
                    dispatch(success(data));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request(name) { return { type: SurveyConstants.SYNC_SEND_PHOTO_DOIT, name } }  // reducer gets object with keys 'type' and 'name'
    function success(data) { return { type: SurveyConstants.SYNC_SEND_PHOTO_SUCC, data } }
    function failure(error) { return { type: SurveyConstants.SYNC_SEND_PHOTO_FAIL, error } }

}

function syncSendSurvey(survey, user) {
    // SURVEY UPLOADER!! MUST upload syncSurveys[0]. on success, we slice(1) the array (deletes suncSurveys[0])
    return dispatch => {
        dispatch(request( survey ));

        SurveyService.postSurvey(survey, user)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request(survey) { return { type: SurveyConstants.SYNC_SEND_SURV_DOIT, survey } }  // reducer gets object with keys 'type' and 'name'
    function success(data) { return { type: SurveyConstants.SYNC_SEND_SURV_SUCC, data } }
    function failure(error) { return { type: SurveyConstants.SYNC_SEND_SURV_FAIL, error } }

}

function sliceSurvey() {
    return function (dispatch) { dispatch({ type: SurveyConstants.SYNC_DEL_SURVEY_0 }); };
}

function delSurvey( id ) {
    return function (dispatch) { dispatch({ type: SurveyConstants.SYNC_DEL_SURVEY_BY_ID, id }); };
}

function photoAdd( info ) {
    return function (dispatch) { dispatch({ type: SurveyConstants.SYNC_PHOTO_ADD, info }); };
}
function photoDel( file ) {
    return function (dispatch) { dispatch({ type: SurveyConstants.SYNC_PHOTO_DEL, file }); };
}
function photoSet( data ) {
    return function (dispatch) { dispatch({ type: SurveyConstants.SYNC_PHOTO_SET, data }); };
}
