export const SurveyConstants = {
    GETALL_DOIT: 'SURVEYS_GETALL_DOIT',
    GETALL_SUCC: 'SURVEYS_GETALL_SUCC',
    GETALL_FAIL: 'SURVEYS_GETALL_FAIL',

    CREATE_DOIT: 'SURVEYS_CREATE_DOIT',
    CREATE_SUCC: 'SURVEYS_CREATE_SUCC',
    CREATE_FAIL: 'SURVEYS_CREATE_FAIL',

    CURRENT_INIT: 'SURVEYS_CURRENT_INIT',
    CURRENT_UPDATE: 'SURVEYS_CURRENT_UPDATE',
    CURRENT_SAVE: 'SURVEYS_CURRENT_SAVE',
    CURRENT_DEL: 'SURVEYS_CURRENT_DEL',
    
    RESET_SURVEYS_DEV: 'SURVEYS_RESET_SURVEYS_DEV',

    SYNC_SEND_PHOTO_RESET: 'SURVEYS_SYNC_SEND_PHOTO_RESET',
    SYNC_SEND_PHOTO_DOIT: 'SURVEYS_SYNC_SEND_PHOTO_DOIT',
    SYNC_SEND_PHOTO_SUCC: 'SURVEYS_SYNC_SEND_PHOTO_SUCC',
    SYNC_SEND_PHOTO_FAIL: 'SURVEYS_SYNC_SEND_PHOTO_FAIL',

    // get the list of photos associated with a mission, from the server
    SYNC_GET_PHOTOS_DOIT: 'SURVEYS_SYNC_GET_PHOTOS_DOIT',
    SYNC_GET_PHOTOS_SUCC: 'SURVEYS_SYNC_GET_PHOTOS_SUCC',
    SYNC_GET_PHOTOS_FAIL: 'SURVEYS_SYNC_GET_PHOTOS_FAIL',

    // fetch a photo from the server (jpeg download) and cache/save via RNFS
    SYNC_GET_PHOTO_DOIT: 'SURVEYS_SYNC_GET_PHOTO_DOIT',
    SYNC_GET_PHOTO_SUCC: 'SURVEYS_SYNC_GET_PHOTO_SUCC',
    SYNC_GET_PHOTO_FAIL: 'SURVEYS_SYNC_GET_PHOTO_FAIL',

    SYNC_SEND_SURV_RESET: 'SURVEYS_SYNC_SEND_SURV_RESET',
    SYNC_SEND_SURV_DOIT: 'SURVEYS_SYNC_SEND_SURV_DOIT',
    SYNC_SEND_SURV_SUCC: 'SURVEYS_SYNC_SEND_SURV_SUCC',
    SYNC_SEND_SURV_FAIL: 'SURVEYS_SYNC_SEND_SURV_FAIL',
    SYNC_DEL_SURVEY_0: 'SURVEYS_SYNC_DEL_SURVEY_0',
    SYNC_DEL_SURVEY_BY_ID: 'SURVEYS_SYNC_DEL_SURVEY_BY_ID',

    // in our redux we save a list of our photos taken but not synced, 
    // photos have an entry in a list in redux but also a jpg file in RNFS
    SYNC_PHOTO_ADD: 'SURVEYS_SYNC_PHOTO_ADD',
    SYNC_PHOTO_DEL: 'SURVEYS_SYNC_PHOTO_DEL',
    SYNC_PHOTO_SET: 'SURVEYS_SYNC_PHOTO_SET',
};