import React, { useState, useEffect } from "react";
//import { render } from 'react-dom';
//import { Formik, Field, Form } from 'formik';
//import * as Yup from "yup";
// materialUI stuff
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
// redux stuff
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../_actions";

import { appPermissionActions } from "../_actions";
//import { AdminResetUserPassword } from './AdminResetUserPassword';

const MissionPicker = (props) => {
  // redux: access users state (only to know if we should close this dialog)
  //const users = useSelector(state => state?.users);
  const ASurvset = useSelector((state) => state?.adminSurveySettings);
  // redux : to dispatch actions
  //const dispatch = useDispatch();
  // state for password reset subdialog
  //const [autoClose, setAutoClose] = useState(false);

  const sortedMissions =
    props?.Mission?.mission.sort((a, b) => b.id - a.id) || []; // b - a for reverse sort

  /*
    // on opening of dialog, refresh missions.
    useEffect(() => {
        if (autoClose && users?.updatingUser == 's') { setAutoClose(false); props.onClose(); }
    });
    // if we sent a request and then it succeeded, close ourself.
    useEffect(() => {
        if (autoClose && users?.updatingUser == 's') { setAutoClose(false); props.onClose(); }
    });
    */

  // display a mission in a list
  function MissionPickItem(props) {
    const [missionLoc] = useState(
      ASurvset?.HDStore?.find((mm) => mm?.Number == props?.item?.mLocationID)
    );
    return (
      <Box
        sx={{
          borderTop: props.index > 0 ? 1 : 0,
          backgroundColor: props.index % 2 ? "#fff" : "#eee",
          cursor: "pointer",
        }}
        onClick={() => {
          handleMissionPick(props?.item);
        }}
      >
        <Box sx={styles.flexContainer}>
          <span>Year: {props?.item?.yearID}</span>
          <span>id: {props?.item?.id}</span>
        </Box>
        <Box sx={styles.boxCentered}>
          <h3>
            {missionLoc?.Number} - {missionLoc?.Name}
          </h3>
        </Box>
      </Box>
    );
  }

  // handler for when we click a mission in our dialog
  function handleMissionPick(mission) {
    if (typeof props?.onPick == "function") {
      props.onPick({ ...mission });
    }
    if (typeof props?.onClose == "function") {
      props.onClose();
    }
  }

  return (
    <>
      {/*
        <Formik
            initialValues={initialValues}
            enableReinitialize // Required for initialValues to take effect  :O 
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                let permishes = [], i;
                for (i = 1; i <= myPermissions?.length; i++) {
                    let obj = {
                        // "id": i,
                        "applicationUserId": props.user.id,
                        "permissionId": i,
                        "isAuthorized": values[("perm_" + i)],
                    };
                    let tmp = myPermissions.find(b => b.permissionId == i);
                    if (tmp) {
                        obj.id = tmp.id;
                        // console.log("Found id=" + tmp.id + " for permissionId=" + i);
                    } // else console.log("!Failed to find key for permissionId " + i);
                    permishes.push(obj);
                }

                let myNewUser = {
                    "id": props.user.id,
                    "firstName": values.nufirstname,
                    "lastName": values.nulastname,
                    "email": values.nuemail,
                    "language": "en-US",
                    "timeZone": "-5",
                    "permissions": permishes,
                }
                // alert(JSON.stringify(values));

                // console.log("Attempt to update user: " + JSON.stringify(myNewUser));
                setAutoClose(true);
                dispatch(userActions.update(props.user.id, myNewUser));
                //setSubmitting(false);

            }}
        >
            {({ values, touched, errors, isSubmitting,
                setFieldValue, handleChange, handleBlur, handleReset, submitForm, }) => (
                <Form sx={{ minWidth: 640, width: '80%', flexGrow: 1 }} autoComplete="off" >   
*/}
      {/* We _CAN_ get state from formik here */}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        //classes={{ paper: {minWidth: '60%', }}}
        fullWidth
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Pick Mission
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          {/*<Typography sx={{ fontSize:8 }}>{props?.user?.id}</Typography>
                            
                            <p>Stores: {ASurvset?.myHDStores?.length}</p>
                            <p>{JSON.stringify(ASurvset?.myHDStores?.[0])}</p>
                            */}
          {sortedMissions?.map((m, i) => (
            <MissionPickItem
              key={`mp_${m.id}_${i}`}
              index={i}
              item={m}
              onClick={handleMissionPick}
            />
          ))}
          {/*
                            <Typography sx={{ fontSize:8 }}>{JSON.stringify(sortedMissions)}</Typography>
                            <Box margin={1}>
                                <TextField
                                    name="nuemail"
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    autoComplete="new-email"
                                    fullWidth
                                    value={values['nuemail']}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.nuemail && Boolean(errors.nuemail)}
                                    helperText={touched.nuemail && errors.nuemail}
                                />
                            </Box>
                            <Box margin={1}>
                                <TextField
                                    name="nufirstname"
                                    type="text"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    value={values['nufirstname']}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.nufirstname && Boolean(errors.nufirstname)}
                                    helperText={touched.nufirstname && errors.nufirstname}
                                />
                            </Box>
                            <Box margin={1}>
                                <TextField
                                    name="nulastname"
                                    type="text"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    value={values['nulastname']}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.nulastname && Boolean(errors.nulastname)}
                                    helperText={touched.nulastname && errors.nulastname}
                                />
                            </Box>
                            <Box margin={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={undefined}
                >
                  Apply
                </Button>
              </Box>
                            <Box sx={{ width: '100%', height: 2, borderTop: 1, }} />
                            <p>Sample Text</p>
              */}

          {/* Formik MaterialUI values: {values['rememberMe'] ? 'on' : 'off'} */}
          {/* JSON.stringify(values) */}
        </DialogContent>
        <DialogActions
          sx={{ flex: "flex-grow", justifyContent: "space-between" }}
        >
          <Box></Box>
          <Button variant="outlined" color="error" onClick={props.onClose}>
            Cancel
          </Button>
          {/* 
                            <Button variant='outlined' color='secondary' onClick={handleReset}>Undo Changes</Button>
                            <Button variant='outlined' color='primary' onClick={() => { setIsPasswordReset('open'); }}>Password Reset</Button>
                            <Button variant='contained' color='primary' onClick={submitForm}>Save</Button>
                            */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  boxCentered: {
    alignItems: "center",
  },
};

export { MissionPicker };
