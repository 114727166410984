import { userConstants } from '../_constants';

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                items: action.users,
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                items: state.items.filter(user => user.id !== action.id)
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        case userConstants.SETPASS_REQUEST:
            return {
                ...state,
                settingPassword: 'r', // r = requesting
            };
        case userConstants.SETPASS_SUCCESS:
            return {
                ...state,
                settingPassword: 's', // s = succeeded
            };
        case userConstants.SETPASS_FAILURE:
            return {
                ...state,
                settingPassword: 'e', // e = error
            };

        case userConstants.CREATE_DOIT:
            return {
                ...state,
                creatingUser: 'r', // r = requesting
            };
        case userConstants.CREATE_SUCC:
            return {
                ...state,
                creatingUser: 's', // s = succeeded
            };
        case userConstants.CREATE_FAIL:
            return {
                ...state,
                creatingUser: 'e', // e = error
            };
    

        case userConstants.UPDATE_DOIT:
            return {
                ...state,
                updatingUser: 'r', // r = requesting
            };
        case userConstants.UPDATE_SUCC:
            return {
                ...state,
                updatingUser: 's', // s = succeeded
            };
        case userConstants.UPDATE_FAIL:
            return {
                ...state,
                updatingUser: 'e', // e = error
            };
                        
        default:
            return state;
    }
}