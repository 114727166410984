import React, { useEffect } from 'react';
import config from 'config';
import { useNavigate } from 'react-router-dom';  // was: import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../_actions';


function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function checkExpiry(expireUTCString) {
    const loginExpires = Date.parse();

}

function dateToHMS(milliseconds)
{
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours % 24;
    minutes = minutes % 60;
    seconds = seconds % 60;

    return (days>0 ? '' + days + ' days ' : '') +
    (hours<10 ? '0' + hours : hours) + ':' + 
    (minutes<10 ? '0' + minutes : minutes) + ':' +
    (seconds<10 ? '0' + seconds : seconds);
}

function HomePage() {
    const users = useSelector(state => state?.users);
    const user = useSelector(state => state?.authentication?.user);
    const dispatch = useDispatch();

    // react router
    let navigate = useNavigate();
    // on page mount get users list one time.
    useEffect(() => {
        dispatch(userActions.getAll());
    }, []);

    /*
    function handleDeleteUser(id) {
        dispatch(userActions.delete(id));
    }
    */

    const myNow = new Date(Date.now()), 
        loginExpires = new Date(Date.parse(user?.expiration || myNow)),
        loginTimeLeft = loginExpires - myNow;
    //console.log(`loginExpires: <${typeof loginExpires}> '${loginExpires}'`);

    // if we're unauthorized, bump to /login screen!
    if (users?.error) { 
        console.log(`Dashboard: users.error: "${users.error}".`);
        if (users.error === 'Unauthorized') {
            // let goto = "/login"; console.log(`React router go to: "${goto}".`); history(goto);
            //dispatch(userActions.logout());
            //location.reload(true);
            navigate('/login');
        }
    }

    return (
        <div>
            <h3>Dashboard</h3>
            {/* 
            <h1>Hi {user.firstName}!</h1>
            Combining react-router link and materialui button 
            <p>
                <Button color="primary" variant="contained" component={Link} to="/adminusers">Admin</Button> &nbsp;
                <Button color="primary" variant="outlined" component={Link} to="/adminsurveysetting">Survey Settings</Button> &nbsp;
                <Button color="secondary" variant="contained" component={Link} to="/testfeature">Test</Button>
            </p>
            */}

            <p>Now: {new Date(Date.now()).toISOString()}, 
            Until: {new Date(loginExpires).toISOString()}, 
            { loginTimeLeft > 0 
            ? <> Time Left: { dateToHMS(loginTimeLeft) } </>
            : <> Time Overdue: { dateToHMS(-loginTimeLeft) } </> }
            </p>
            {/*<p>loginTimeLeft: { JSON.stringify(loginTimeLeft) } ms</p>*/}

            {/*<p>{`API URL: "${config.apiUrl}".`}</p>*/}
                        
            <h3>All registered users:</h3>
            {users.loading && <em>Loading users...</em>}
            {users.error && <span className="text-danger">ERROR: {users.error}</span>}
            {users.items &&
                <ul>
                    {users.items.map((user, index) =>
                        <li key={user.id}>
                            {user.firstName + ' ' + user.lastName}
                            {
                                /*
                                user.deleting ? <em> - Deleting...</em>
                                : user.deleteError ? <span className="text-danger"> - ERROR: {user.deleteError}</span>
                                : <span> - <a onClick={() => handleDeleteUser(user.id)} className="text-primary">Delete</a></span>
                                */
                            }
                        </li>
                    )}
                </ul>
            }
        </div>
    );
}

export { HomePage };