import { SurveyConstants } from '../_constants';
import {  
    HDASS, HDArticle, HDStore, HDSteelType, HDDept, HDBay, HDSeismic, HDBayAisle, HDBayType,
    hdbtFromParam, hdbtFromBayType,
  } from '../_appdata';

const initialState = {
    isLoaded: false, isLoading: false,
    isCreated: false, isCreating: false,
    surveys: [], syncSurveys: [], currentSurvey: null,
    photos: [], syncPhotos: [],  // syncPhotos = photo info for our syncSurveys
    error: null, scError: null,
    syncFileUpload: null, syncSurveyUpload: null,
    isMissionPhotosLoaded: false, isMissionPhotosLoading: false, 
};

export function Surveys(state = initialState, action) {
    switch (action.type) {
        // Retrieving the surveys from the API
        case SurveyConstants.GETALL_DOIT:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                surveys: null,
                error: null,
            };
        case SurveyConstants.GETALL_SUCC:
            let count = '??';
            if (typeof(action.data)==='object') { count=action.data.length; }
            console.log(`Sync: Downloaded Mission Surveys (${count})`);
            const mappedSurveys = action.data.map(s =>
                ({
                    CreatedBy: s.createdBy,
                    SurveyedUTC: s.surveyedUtc,
                    SecondsOpen: s.secondsOpen,       // 0,
                    Status: s.status,                 // 'DRAFT',
                    LocationType: s.locationType,     // i.e. "HD" for home depot
                    Location: s.location,             // HD Bay #, i.e. "EC-010"
                    MissionID: s.missionID,           // missionID <int>
                    SurveyType: s.surveyType,         // i.e. "HDASS"
                    SurveyBaseID: s.surveyBaseID,     // surveyBaseID <int>, i.e. 10, the "version number" of the survey 
                    
                    Department: s.param1,                       // 21, 22, 23, 24.. for home depot 
                    SteelType: hdbtFromParam(s.param2),    // i.e. "RS_O", RS_W, CANT, GALV

                    Data: JSON.parse(s.surveyData),
                    Comment: s.comment,
                }));

            //console.log(JSON.stringify(mappedSurveys));

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                surveys: mappedSurveys,
                error: null,
            };
        case SurveyConstants.GETALL_FAIL:
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                surveys: null,
                error: action.error,
            };
        // Creating & Posting a Survey to the API
        case SurveyConstants.CREATE_DOIT:
            return {
                ...state,
                isCreated: false,
                isCreating: true,
                // mySurvey: null,          // mySurvey the Survey I am currently on
                scError: null,          // Survey creation error
            };
        case SurveyConstants.CREATE_SUCC:
            return {
                ...state,
                isCreated: true,
                isCreating: false,
                mySurvey: action.data,
                scError: null,
            };
        case SurveyConstants.CREATE_FAIL:
            return {
                ...state,
                isLoaded: false,
                isCreating: false,
                // mySurvey: null,
                scError: action.error,
            };        
        case SurveyConstants.CURRENT_INIT:
        {
            console.log("survey reducer INITIALIZING current survey: \n" + JSON.stringify(action.data));
            return {
                ...state,
                currentSurvey: {
                    ...action.data,
/*
                    createdBy: action.data.createdBy,
                    surveyedUtc: action.data.surveyedUtc,
                    secondsOpen: action.data.secondsOpen, // 0,
                    status: action.data.status, // 'DRAFT',
                    locationType: action.data.locationType,  // i.e. "HD" for home depot
                    location: action.data.location,          // HD Bay #, i.e. "EC-010"
                    missionID: action.data.missionID,        // missionID <int>
                    surveyType: action.data.surveyType,      // i.e. "HDASS"
                    surveyBaseID: action.data.surveyBaseID,  // surveyBaseID <int>, i.e. 10, the "version number" of the survey 
                    department: action.data.department,
                    steelType: action.data.steelType,
*/
                    // csState: true, // when currentSurvey.csState is true, trigger navigation from survey bay screen to survey.
                },
            };
        }
        case SurveyConstants.CURRENT_UPDATE:
        {
            console.log("survey reducer UPDATING current survey!\n" + JSON.stringify(action.data));

            return {
                ...state,
                currentSurvey: {
                    ...state.currentSurvey,
                    ...action.data,
                }
            }
        }
        case SurveyConstants.CURRENT_SAVE:
        {
            //console.log("survey reducer saving a thing!\n" + JSON.stringify(action.data));

            return {
                ...state,
                syncSurveys: [ ...state.syncSurveys,
                    {
                        CreatedBy: state.currentSurvey.CreatedBy,
                        SurveyedUTC: state.currentSurvey.SurveyedUTC,
                        SecondsOpen: state.currentSurvey.SecondsOpen,       // 0,
                        Status: state.currentSurvey.Status,                 // 'DRAFT',
                        LocationType: state.currentSurvey.LocationType,     // i.e. "HD" for home depot
                        Location: state.currentSurvey.Location,             // HD Bay #, i.e. "EC-010"
                        MissionID: state.currentSurvey.MissionID,           // missionID <int>
                        SurveyType: state.currentSurvey.SurveyType,         // i.e. "HDASS"
                        SurveyBaseID: state.currentSurvey.SurveyBaseID,     // surveyBaseID <int>, i.e. 10, the "version number" of the survey 
                        Department: state.currentSurvey.Department,         // 21, 22, 23, 24.. for home depot 
                        SteelType: state.currentSurvey.SteelType,           // i.e. "RS_O", RS_W, CANT, GALV

                        Param1: state.currentSurvey.Department,                   // to save in API DB 
                        Param2: hdbtFromBayType(state.currentSurvey.SteelType),   // to save in API DB 
    
                        Data: state.currentSurvey.Data,
                        Comment: state.currentSurvey.Comment,
                    }
                ],
                currentSurvey: {
                    // persist bayid (location), type, and some stuff for the next bay...
                    LocationType: state.currentSurvey.LocationType,     // i.e. "HD" for home depot
                    Location: state.currentSurvey.Location,             // HD Bay #, i.e. "EC-010"
                    MissionID: state.currentSurvey.MissionID,           // missionID <int>
                    SurveyType: state.currentSurvey.SurveyType,         // i.e. "HDASS"
                    Department: state.currentSurvey.Department,         // 21, 22, 23, 24.. for home depot 
                    SteelType: state.currentSurvey.SteelType,           // i.e. "RS_O", RS_W, CANT, GALV

                    csState: false, // when currentSurvey.csState is true, trigger navigation from survey bay screen to survey.
                    
                },
            };        
        }        
        case SurveyConstants.CURRENT_DEL:
        {
            console.log("survey reducer: RIP current survey!!");
            return {
                ...state,
                currentSurvey: {
                    // persist bayid (location), type, and some stuff for the next bay...
                    LocationType: state.currentSurvey.LocationType,     // i.e. "HD" for home depot
                    Location: state.currentSurvey.Location,             // HD Bay #, i.e. "EC-010"
                    MissionID: state.currentSurvey.MissionID,           // missionID <int>
                    SurveyType: state.currentSurvey.SurveyType,         // i.e. "HDASS"
                    Department: state.currentSurvey.Department,         // 21, 22, 23, 24.. for home depot 
                    SteelType: state.currentSurvey.SteelType,           // i.e. "RS_O", RS_W, CANT, GALV

                    csState: false, // when currentSurvey.csState is true, trigger navigation from survey bay screen to survey.
                    
                },
            };
        }
        case SurveyConstants.RESET_SURVEYS_DEV:
        {
            //console.log("Resetting survey redux store! All surveys and syncSurveys lost!!");
            return {
                ...state,
                surveys: [], 
                syncSurveys: [], 
            };
        }
        case SurveyConstants.SYNC_SEND_PHOTO_RESET: 
        {
            //console.log("SYNC_SEND_PHOTO_RESET");
            return {
                ...state,
                syncFileUpload: undefined,
            };
        }
        case SurveyConstants.SYNC_SEND_PHOTO_DOIT: 
        {
            //console.log("SYNC_SEND_PHOTO_DOIT: "+JSON.stringify(action));
            return {
                ...state,
                syncFileUpload: {
                    name: action.name,
                    isUploading: true,
                    isUploaded: false,
                    isError: false,
                },
            };
        }
        case SurveyConstants.SYNC_SEND_PHOTO_SUCC: 
        {
            //console.log("SYNC_SEND_PHOTO_SUCC");
            return {
                ...state,
                syncFileUpload: {
                    name: state.syncFileUpload.name,
                    isUploading: false,
                    isUploaded: true,
                    isError: false,
                },
            };
        }
        case SurveyConstants.SYNC_SEND_PHOTO_FAIL: 
        {
            //console.log("SYNC_SEND_PHOTO_FAIL");
            return {
                ...state,
                syncFileUpload: {
                    name: state.syncFileUpload.name,
                    isUploading: false,
                    isUploaded: false,
                    isError: true,
                },
            };
        }
        // SENDING SURVEYS UP TO THE SERVER 
        case SurveyConstants.SYNC_SEND_SURV_RESET: 
        {
            //console.log("SYNC_SEND_SURV_RESET");
            return {
                ...state,
                syncSurveyUpload: undefined,
            };
        }
        case SurveyConstants.SYNC_SEND_SURV_DOIT:
        {
            return {
                ...state,
                syncSurveyUpload: {
                    survey: action.survey,  // for the purposes of displaying progress on the sync screen, we always send syncSurveys[0]
                    isUploading: true,
                    isUploaded: false,
                    isError: false,
                },
            };
        }
        case SurveyConstants.SYNC_SEND_SURV_SUCC:
        {
            return {
                ...state,
                syncSurveyUpload: {
                    survey: state.syncSurveyUpload.survey,
                    isUploading: false,
                    isUploaded: true,
                    isError: false,
                },
            };
        }
        case SurveyConstants.SYNC_SEND_SURV_FAIL:
        {
            return {
                ...state,
                syncSurveyUpload: {
                    survey: state.syncSurveyUpload.survey,
                    isUploading: false,
                    isUploaded: false,
                    isError: true,
                },
            };
        }
        case SurveyConstants.SYNC_DEL_SURVEY_0:
        {
            return {
                ...state,
                // slice 1 returns a shallow copy of an array starting at element 1 (element 0 is removed)
                syncSurveys: state.syncSurveys.slice(1),
            };
        }
        case SurveyConstants.SYNC_DEL_SURVEY_BY_ID:
        {
            //console.log(JSON.stringify(action));
            return {
                ...state,
                // slice 1 returns a shallow copy of an array starting at element 1 (element 0 is removed)
                syncSurveys: state.syncSurveys.filter((s, i) => { 
                    //console.log(`filter: s: ${s}, i: ${i}, val: ${i !== action.id}`);
                    return i !== action.id; 
                }),
            };
        }

        case SurveyConstants.SYNC_PHOTO_ADD:
        {
            // fix when syncPhotos is undefined - redux-persist causes this cause this is a new thing lol
            let tmp = (typeof(state.syncPhotos) == 'object') ? state.syncPhotos : [];
            //console.log(JSON.stringify(action));
            return {
                ...state,
                syncPhotos: [ ...tmp.slice(0), { ...action.info } ],
            };
        }
        case SurveyConstants.SYNC_PHOTO_DEL:
        {
            //console.warn(JSON.stringify(action));
            return {
                ...state,
                // slice 1 returns a shallow copy of an array starting at element 1 (element 0 is removed)
                syncPhotos: state.syncPhotos.filter((s, i) => { 
                    //console.log(`filter: s: ${s}, i: ${i}, val: ${i !== action.id}`);
                    return s.File !== action.file; 
                }),
            };
        }
        case SurveyConstants.SYNC_PHOTO_SET:
        {
            return {
                ...state,
                syncPhotos: action.data,
            };
        }

        // fetching photos for a mission from the server
        case SurveyConstants.SYNC_GET_PHOTOS_DOIT:
        {
            return {
                ...state,
                isMissionPhotosLoaded: false, isMissionPhotosLoading: true,                 
            };
        }
        case SurveyConstants.SYNC_GET_PHOTOS_SUCC:
        {
            return {
                ...state,
                prevPhotos: [...state.photos],  // spread operator clone the array. prevPhotos can be compared against photos to tell us what we need to load into RNFS (mobile app)
                photos: action.data.map(p => { return { ...p, missionId: action.missionId }; }), 
                isMissionPhotosLoaded: true, isMissionPhotosLoading: false,
            };
        }
        case SurveyConstants.SYNC_GET_PHOTOS_FAIL:
        {
            return {
                ...state,                
                isMissionPhotosLoaded: false, isMissionPhotosLoading: false,
                errorMissionPhotos: action.error,
            };
        }
                        

        default:
            return state;
    }
}