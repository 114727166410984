import { adminSurvSetConstants } from '../_constants';
import { assService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const assActions = {
    create,
    update,
    getAll,
    // getById,
    delete: _delete,
};

function create(surveySetting) {
    return dispatch => {
        dispatch(request(surveySetting));

        assService.create(surveySetting)
            .then(
                surveySetting => { 
                    dispatch(success(surveySetting));
                    // history('/login');
                    dispatch(alertActions.success('SurveySetting created.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(surveySetting) { return { type: adminSurvSetConstants.ADD_DOIT, data: surveySetting } }
    function success(surveySetting) { return { type: adminSurvSetConstants.ADD_SUCC, data: surveySetting } }
    function failure(error) { return { type: adminSurvSetConstants.ADD_FAIL, error } }
}

function update(ID, surveySetting) {
    return dispatch => {
        dispatch(request(surveySetting));

        assService.update(ID, surveySetting)
            .then(
                surveySetting => { 
                    dispatch(success(surveySetting));
                    // history('/login');
                    dispatch(alertActions.success('SurveySetting updated.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(surveySetting) { return { type: adminSurvSetConstants.UPDATE_DOIT, data: surveySetting } }
    function success(surveySetting) { return { type: adminSurvSetConstants.UPDATE_SUCC, data: surveySetting } }
    function failure(error) { return { type: adminSurvSetConstants.UPDATE_FAIL, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        assService.getAll()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adminSurvSetConstants.GETALL_DOIT } }
    function success(data) { return { type: adminSurvSetConstants.GETALL_SUCC, data } }
    function failure(error) { return { type: adminSurvSetConstants.GETALL_FAIL, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        assService.delete(id)
            .then(
                () => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: adminSurvSetConstants.DELETE_DOIT, id } }
    function success(id) { return { type: adminSurvSetConstants.DELETE_SUCC, id } }
    function failure(id, error) { return { type: adminSurvSetConstants.DELETE_FAIL, id, error } }
}