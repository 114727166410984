import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../_actions';


//import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Menu as MenuIcon } from '@mui/icons-material';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
/*
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textDecoration: "none",
      boxShadow: "none",
    },
    // This group of buttons will be aligned to the right
    rightToolbar: {
      marginLeft: "auto",
      marginRight: -12
    },
  }));
  */

const NavBar = () => {
  // redux things
  const auth = useSelector(state => state?.authentication);
  const dispatch = useDispatch();

  let history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleChange = event => {
    setAuth(event.target.checked);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (goto = null) => {
    setAnchorEl(null);
    if (typeof(goto) == 'string') { // cause a navigation event with react router
      history(goto);
    } 
  };

  let appName = '?', appColor = 'primary';
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    appName = 'Mammoth (Dev)';
    appColor = 'secondary';
} else {
    // production code
    appName = 'Mammoth';
}

  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" color={appColor}>
      <Toolbar>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
                // className={classes.title}
                variant="h6"
                noWrap
                component={Link}
                to="/"
                color="inherit"
                underline="none"
                sx={{ textDecoration: 'none' }}
              >
              {appName}
            </Typography>
          </Box>

        <Menu
              id="menu-appbar-main"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleClose('/adminusers')}>Users</MenuItem>
              <MenuItem onClick={() => handleClose('/adminsurveysetting')}>Survey Settings</MenuItem>
              <MenuItem onClick={() => handleClose('/surveybuilder')}>Survey Builder</MenuItem>
              <MenuItem onClick={() => handleClose('/missionviewer')}>Mission Browser</MenuItem>
              <MenuItem onClick={() => handleClose('/missionphotos')}>Mission Photos</MenuItem>
              <MenuItem onClick={() => handleClose('/testfeature')}>Test Feature (in development)</MenuItem>
              <MenuItem onClick={() => handleClose('/testfeature2')}>Test Feature 2 (in development)</MenuItem>
            </Menu>

        {auth?.loggedIn ? (
          <Fragment>
            <section >

            {/*<Button color="inherit" component={Link} to="/login">Logout</Button>*/}
            <Button color="inherit" onClick={() => { dispatch(userActions.logout()); /*history("/login");*/ }}>Logout</Button>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleMenu}
              onClick={() => { dispatch(userActions.logout()); }}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            </section>
          </Fragment>
        ) : (
          <Fragment>
            <section >

            {/*<Button color="inherit" component={Link} to="/login">Logout</Button>*/}
            <Button color="inherit" onClick={() => { history("/login"); }}>Login</Button>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleMenu}
              //onClick={() => userActions.logout()}
              disabled
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            </section>
          </Fragment>
        )}

        </Toolbar>
    </AppBar>
    </Box>
  )
}

export default NavBar;
