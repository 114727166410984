import React, { useState, useEffect } from 'react';
import {render} from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import {Formik, Form, Field} from 'formik';
import {
  Box,
  Button,
  fieldToTextField,
  TextField,
  FormControlLabel, Switch, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
  CircularProgress, Grid, Container, Typography, LinearProgress, InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {top100Films} from './data';
// redux
import { userActions } from '../_actions';

/*interface Values {
  email: string;
}*/

function CreateUser(butts) {
  const myNewUser = { 
    "firstName": butts.nufirstname,
    "lastName": butts.nulastname,
    "email": butts.nuemail,
    "password": butts.nupassword,
    "language": "en-US",
    "timeZone": "-5"
  }
  const dispatch = useDispatch();
  dispatch(userActions.create(myNewUser));
}

/*
function UpperCasingTextField(props) {
  const {
    form: {setFieldValue},
    field: {name},
  } = props;
  const onChange = React.useCallback(
    event => {
      const {value} = event.target;
      setFieldValue(name, value ? value.toUpperCase() : '');
    },
    [setFieldValue, name]
  );
  return <TextField {...props} onChange={onChange} />;
}

function LowerCasingTextField(props) {
    const {
      form: {setFieldValue},
      field: {name},
    } = props;
    const onChange = React.useCallback(
      event => {
        const {value} = event.target;
        setFieldValue(name, value ? value.toLowerCase() : '');
      },
      [setFieldValue, name]
    );
    return <TextField {...props} onChange={onChange} />;
}
*/

const AdminCreateUser = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state?.users);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [doReload, setDoReload] = useState(false);

  // if (users?.creatingUser == 's' || users?.creatingUser == 'e')
  useEffect(() => {
    if (doReload && users?.creatingUser == 's') { setDoReload(false); props.onReload(); }
  });
  
  return (
  <Formik
    initialValues={{
      nuemail: '',
      nupassword: '',
      nufirstname: '',
      nulastname: '',     
    }}
    validate={values => {
      const errors = {};
      if (!values.nuemail) {
        errors.nuemail = 'Required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.nuemail)
      ) {
        errors.nuemail = 'Invalid email address';
      }
      if (!values.nufirstname) { errors.nufirstname = 'Required'; } 
      if (!values.nulastname) { errors.nulastname = 'Required'; }
      if (!values.nupassword) {
        errors.nupassword = 'Required';
      } else if ( values.nupassword.length < 8 ) {
        errors.nupassword = 'Too short, minimum 8 characters.'
      } else if ( values.nupassword.length > 64 ) {
        errors.nupassword = 'Too long, maximum 64 characters.'
      } else {
        let tmp = '';
        if (!/\d/.test(values.nupassword)) tmp += 'Needs a number (0-9). ';
        if (!/[$-/:-?{-~!"^_`\[\]@#]/.test(values.nupassword)) tmp += 'Needs a symbol (!, @, #, etc.). ';
        if (!/[a-z]/.test(values.nupassword)) tmp += 'Needs a lowercase letter. ';
        if (!/[A-Z]/.test(values.nupassword)) tmp += 'Needs an UPPERCASE letter. ';
        if (tmp.length > 0) { errors.nupassword = tmp; }
      } 
      return errors;
    }}
    onSubmit={(values) => {
      // setSubmitting(true);
      // setTimeout(() => {
        const myNewUser = { 
          "firstName": values.nufirstname,
          "lastName": values.nulastname,
          "email": values.nuemail,
          "password": values.nupassword,
          "language": "en-US",
          "timeZone": "-5"
        }
        setDoReload(true);  // set our state that on the success of our user creation in the DB, we want to reload our user list
        dispatch(userActions.create(myNewUser));

        //setSubmitting(false);
        //alert(JSON.stringify(values, null, 2));
      // }, 500); /* REMOVE THIS SETTIMEOUT IT ADDS A HALF SECOND DELAY FOR NO REASON LOL */
    }} >
    { /* render={({submitForm, isSubmitting, touched, errors}) => ( */ }
      {({ submitForm, handleChange, handleBlur, resetForm, values, touched, errors }) => (
      <Form style={{ "flexGrow" : "1" }} autoComplete="off" >   { /* 100% - Without this, the form is like 30% width  */ }
          <Box margin={1}>
            <TextField
              name="nuemail"
              type="email"
              label="Email"
              variant="outlined"
              autoComplete="new-email"
              disabled={users?.creatingUser == 'r'}
              fullWidth
              value={values.nuemail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.nuemail && Boolean(errors.nuemail)}
              helperText={touched.nuemail && errors.nuemail}
            />
          </Box>
          <Box margin={1}>
            <TextField
              name="nupassword"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              label="Password"
              variant="outlined"
              autoComplete="new-password"
              disabled={users?.creatingUser == 'r'}
              fullWidth
              value={values.nupassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.nupassword && Boolean(errors.nupassword)}
              helperText={touched.nupassword && errors.nupassword}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box margin={1}>
            <TextField
              name="nufirstname"
              type="text"
              label="First Name"
              variant="outlined"
              value={values.nufirstname}
              disabled={users?.creatingUser == 'r'}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.nufirstname && Boolean(errors.nufirstname)}
              helperText={touched.nufirstname && errors.nufirstname}
            />
          </Box>
          <Box margin={1}>
            <TextField
              name="nulastname"
              type="text"
              label="Last Name"
              variant="outlined"
              value={values.nulastname}
              disabled={users?.creatingUser == 'r'}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.nulastname && Boolean(errors.nulastname)}
              helperText={touched.nulastname && errors.nulastname}
            />
          </Box>
          <Box margin={1} >
            {users?.creatingUser == 'r' && <LinearProgress />}
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', }}>
              <Button
                variant="contained"
                color="primary"
                disabled={users?.creatingUser == 'r'}
                onClick={submitForm}
              >
                Add User
              </Button>            
              <Button
                variant="outlined"
                color="error"
                disabled={users?.creatingUser == 'r'}
                onClick={resetForm}
              >
                Clear Form
              </Button>
            </Box>
          </Box>
        </Form>
    )}
  </Formik>
);
}

export { AdminCreateUser };
