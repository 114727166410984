import React from 'react';

// This function is asynchronous since it returns a promise
// It converts a binary blob into a base64 string representation
function blobToBase64(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
}

function fetchImageAsBase64(url, token) {
    return new Promise((resolve) => {
        //const jwt = "pretend this is a jwt";

        // Make a headers object that we can add to the request
        const headers = new Headers({
            authorization: "Bearer " + token,
        });

        // Make the request and wait for the response
        window
            .fetch(url, { headers })
            .then((response) => response.blob())
            .then((blob) => blobToBase64(blob))
            .then((base64) => resolve(base64));
    });
}

// This is our custom image component that puts this together
export class ProtectedImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageSrc: null,
            style: {
                objectFit: this.props?.objectFit ? this.props.objectFit : 'contain',
            },
        };
    }

    componentDidMount() {
        if (!this.props?.wait) {
            // fetch our image with secure http header.
            fetchImageAsBase64(this.props.src, this.props.token).then((base64String) => {
                this.setState({
                    // assign our image 
                    imageSrc: base64String,
                });
                // if we were supplied a callback to run after loading the image, do it.
                if (this.props?.onLoad) {
                    this.props.onLoad();
                }
            });
        }
    }

    render() {
        if (!this.state.imageSrc) {
            // If you want you can return a loading component here
        }

        return <img 
            style={this.state.style}
            src={this.state.imageSrc} 
            alt={this.props?.alt} 
            width={this.props?.width || '100%'} 
            height={this.props?.height || '100%'} 
        />;
    }
}
