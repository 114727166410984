import { MissionConstants } from '../_constants';
import { MissionService } from '../_services';

export const MissionActions = {
    getAll,
    create,
    open,
    doclose,
};

function getAll(user) {
    return dispatch => {
        dispatch(request());

        MissionService.getAllMissions(user)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: MissionConstants.GETALL_DOIT } }
    function success(data) { 
        //console.log("Success!!! " + JSON.stringify(data))
        return { type: MissionConstants.GETALL_SUCC, data } }
    function failure(error) { return { type: MissionConstants.GETALL_FAIL, error } }
}

function create(missionInfo, user) {
    return dispatch => {
        dispatch(request());

        MissionService.create(missionInfo, user)
            .then(
                data => 
                    dispatch(success(data))
                    // MyMission.open(missionInfo); // hope this works!!
                ,
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: MissionConstants.CREATE_DOIT } }
    function success(data) { return { type: MissionConstants.CREATE_SUCC, data } }
    function failure(error) { return { type: MissionConstants.CREATE_FAIL, error } }
}

function open(Id, user) {
    return dispatch => {
        dispatch(request());

        MissionService.open(Id, user)
            .then(
                data => 
                    dispatch(success(data))
                ,
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: MissionConstants.OPEN_DOIT } }
    function success(data) { return { type: MissionConstants.OPEN_SUCC, data } }
    function failure(error) { return { type: MissionConstants.OPEN_FAIL, error } }
}

function doclose(missionInfo, user) {
    return dispatch => {
        dispatch(request());

        MissionService.doclose(missionInfo, user)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            ).catch(
                () => { 
                    console.log("[MISSION] Close FAILED!\n" + error.toString()); 
                    error => dispatch(failure(error));

                }
    
            );
    };

    function request() { return { type: MissionConstants.CLOSE_DOIT } }
    function success(data) { return { type: MissionConstants.CLOSE_SUCC, data } }
    function failure(error) { console.log("FUK" + error); return { type: MissionConstants.CLOSE_FAIL, error } }
}
