import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
// materialUI stuff
import {
  Box, Button,
  TextField, FormControlLabel, Switch, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
  CircularProgress, Grid, Container, Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';

import { appPermissionActions } from '../_actions';
import { AdminResetUserPassword } from './AdminResetUserPassword';

const AdminEditUser = (props) => {
  // redux: access users state (only to know if we should close this dialog)
  const users = useSelector(state => state?.users);
  // redux : to dispatch actions
  const dispatch = useDispatch();
  // state for password reset subdialog
  const [isPasswordReset, setIsPasswordReset] = React.useState('closed');
  const [autoClose, setAutoClose] = useState(false);

  // generate dynamic form elements for App Permissions
  let myPermissions = [];
  // step 1: populate myPermissions will all the app's permissions
  props?.appPermissions?.perm?.map(a => myPermissions.push({
    "permissionId":a.id, 
    "name":a.name,
    "applicationUserId":props?.user?.id,
    "isAuthorized":false,    // permissions are off by default. 
  }));  // copy the array of all permissions from our redux store into allPerms
  // step 2: merge in the permissions the user currently has
  props?.user?.permissions?.map(a => {
    let tmp = myPermissions.find(b => b.permissionId == a.permissionId);
    if (tmp) { 
      tmp.isAuthorized = a.isAuthorized; 
      tmp.id = a.id;
      // console.log("matched "+a.permissionId+", isAuthorized:"+a.isAuthorized );
    } else console.log("failed to match "+a.permissionId );
  });

  let initialPerms = {};
  myPermissions.map(p => initialPerms['perm_'+p.permissionId] = p.isAuthorized);

  //console.log(`myPermissions length: ${myPermissions?.length}`);
  const handleClosePassword = () => {
    setIsPasswordReset('closing');
    setTimeout(() => { setIsPasswordReset('closed'); }, 1000); // This allows dialog closing animation to happen.
  };

  // formik + yup validation
  const validationSchema = Yup.object({
    nuemail: Yup.string().email("Must be a valid e-mail").required("Required"),
    nufirstname: Yup.string().required("Required"),
    nulastname: Yup.string().required("Required"),
  });
  
  // initial form values (also what we reset back to if reset is clicked)
  const initialValues = {
    nuemail: props?.user?.email || '',
    nufirstname: props?.user?.firstName || '',
    nulastname: props?.user?.lastName || '',
    ...initialPerms,
  }

  // if we sent a request and then it succeeded, close ourself.
  useEffect(() => {
    if (autoClose && users?.updatingUser == 's') { setAutoClose(false); props.onClose(); }
  });  

  return ( 
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize // Required for initialValues to take effect  :O 
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true);
    
          let permishes = [], i;
          for (i=1; i <= myPermissions?.length; i++) {
            let obj = {
              // "id": i,
              "applicationUserId": props.user.id,
              "permissionId": i,
              "isAuthorized": values[("perm_"+i)],
            };
            let tmp = myPermissions.find(b => b.permissionId == i);
            if (tmp) { 
              obj.id = tmp.id; 
              // console.log("Found id=" + tmp.id + " for permissionId=" + i);
            } // else console.log("!Failed to find key for permissionId " + i);
            permishes.push(obj);
          }
    
          let myNewUser = { 
            "id": props.user.id,
            "firstName": values.nufirstname,
            "lastName": values.nulastname,
            "email": values.nuemail,
            "language": "en-US",
            "timeZone": "-5",
           "permissions": permishes,
          }

          // alert(JSON.stringify(values));
    
          // console.log("Attempt to update user: " + JSON.stringify(myNewUser));
          setAutoClose(true);
          dispatch(userActions.update(props.user.id, myNewUser));
          //setSubmitting(false);
          
        }}
      >
        {({ values, touched, errors, isSubmitting, 
          setFieldValue, handleChange, handleBlur, handleReset, submitForm, }) => (
          <Form sx={{ minWidth: 640, width: '80%', flexGrow: 1 }} autoComplete="off" >   { /* 100% - Without this, the form is like 30% width  */}
            {/* We _CAN_ get state from formik here */}
            <Dialog 
              open={props.open} 
              onClose={props.handleClose} 
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >

            <DialogTitle sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              Edit User: {props?.user?.firstName} {props?.user?.lastName} 
              <IconButton onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
              {/*<Typography sx={{ fontSize:8 }}>{props?.user?.id}</Typography>*/}              
              <Box margin={1}>
                <TextField
                  name="nuemail"
                  type="email"
                  label="Email"
                  variant="outlined"
                  autoComplete="new-email"
                  fullWidth
                  value={values['nuemail']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nuemail && Boolean(errors.nuemail)}
                  helperText={touched.nuemail && errors.nuemail}
                />
              </Box>
              <Box margin={1}>
                <TextField
                  name="nufirstname"
                  type="text"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={values['nufirstname']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nufirstname && Boolean(errors.nufirstname)}
                  helperText={touched.nufirstname && errors.nufirstname}
                />
              </Box>
              <Box margin={1}>
                <TextField
                  name="nulastname"
                  type="text"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={values['nulastname']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nulastname && Boolean(errors.nulastname)}
                  helperText={touched.nulastname && errors.nulastname}
                />
              </Box>
              {/*<Box margin={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={undefined}
                >
                  Apply
                </Button>
              </Box>*/}
              <Box sx={{ width:'100%', height:2, borderTop: 1, }} />
              <h3>Permissions</h3>
              {myPermissions.map(p =>
                (
                  <FormControlLabel
                    control={
                      <Switch
                        name={'perm_' + p.permissionId}
                        onChange={handleChange}
                        checked={values['perm_' + p.permissionId]} 
                      />
                    }
                    label={p?.name}
                    key={'t_perm_' + p.permissionId}
                  />))}
              {/* Formik MaterialUI values: {values['rememberMe'] ? 'on' : 'off'} */}
              {/* JSON.stringify(values) */}
            </DialogContent>
            <DialogActions sx={{ flex: 'flex-grow', justifyContent: 'space-between' }}>
              <Button variant='outlined' color='error' onClick={props.onClose}>Cancel</Button>
              <Button variant='outlined' color='secondary' onClick={handleReset}>Undo Changes</Button>
              <Button variant='outlined' color='primary' onClick={() => { setIsPasswordReset('open'); }}>Password Reset</Button>
              <Button variant='contained' color='primary' onClick={submitForm}>Save</Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>

    
{(isPasswordReset === 'open' || isPasswordReset === 'closing') && (
  <AdminResetUserPassword 
    open={(isPasswordReset === 'open')} 
    // userID={userEditorId} 
    user={props.user}
    onClose={handleClosePassword} 
    ariaLabelledBy="form-dialog-title" 
  />
)}
    </>
  );
};

export { AdminEditUser };