import React, { useState } from "react";
import { Stack, Box, Paper, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InboxIcon from "@mui/icons-material/Inbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { DraggableList, DraggableListItem } from '../_components';

// images
//import dash1 from '../../public/img/Dash01.png';

function TestFeature2PageNo(props) {

  const [item, setItem] = useState([
    { id: 1, label: "Apple" },
    { id: 2, label: "Orange" },
    { id: 3, label: "Banana" },
    { id: 4, label: "Speedboat" },
    { id: 5, label: "Eraser" },
    { id: 6, label: "Pencil" },
  ]);

  return (
    <Box sx={{ py: 2 }}>
      <DraggableList
        listId={"chars"}
        items={item}
        //itemIdProp={"id"}
        itemKey={(p) => (`${p.listId}_${p.item.id}`)}
        itemComponent={DraggableListItem}
        onChange={(newItem) => setItem(newItem)}
        allowDelete={true}
      />

      {/*<pre>{JSON.stringify(item, null, ' ')}</pre>*/}
    </Box>
  );
}

function TestFeature2Page(props) {

  return (
    <div>
      <h2>Dashboard</h2>
      <Paper elevation={2} style={{ border: '1px solid #888', overflow: 'clip', }} >
        <Box style={{ padding: 8, margin: 0, backgroundColor: '#1976D2', borderBottom: '1px solid #888', }}>
          <p style={{ padding: 0, margin: 0, color: '#fff', textShadow: '1px 1px 1px #000a', }}>Recent Mission Updates</p>
        </Box>
        <Box style={{ padding: 8, margin: 0, }}>

          <table style={{ width: '90%', }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Store</th>
                <th>Status</th>
                <th>Bays</th>
                <th>Photos</th>
              </tr>
            </thead>
            {/*
<tfoot>
<tr>
<td>foot1</td>
<td>foot2</td>
<td>foot3</td>
<td>foot4</td>
</tr>
</tfoot>
 */}
            <tbody>
              <tr>
                <td>2022-11-05</td><td>7089 - Sherbrooke</td><td>Complete</td><td>930</td><td>1002</td></tr>
              <tr>
                <td>2022-09-26</td><td>7169 - Trois Rivieres</td><td>Open</td><td>909</td><td>983</td></tr>
              <tr>
                <td>2022-09-21</td><td>7239 - Brampton East</td><td>Complete</td><td>935</td><td>1100</td></tr>
              <tr>
                <td>2022-09-16</td><td>7163 - Ste Foy</td><td>Complete</td><td>902</td><td>940</td></tr>
              <tr>
                <td>2022-09-12</td><td>7080 - Gerrard Square</td><td>Complete</td><td>705</td><td>816</td></tr>
            </tbody>
          </table>

        </Box>
      </Paper>

      <Paper elevation={2} style={{ border: '1px solid #888', overflow: 'clip', marginTop: 16, marginBottom: 16, }} >
        <Box style={{ padding: 8, margin: 0, backgroundColor: '#02d', borderBottom: '1px solid #888', }}>
          <p style={{ padding: 0, margin: 0, color: '#fff', textShadow: '1px 1px 1px #000a', }}>Charts</p>
        </Box>
        <Box style={{ padding: 8, margin: 0, }}>
          <img style={{ maxWidth: '100%', }} src={`/img/dash03.png`} alt="Sample" />
          <img style={{ maxWidth: '100%', }} src={`/img/dash02.png`} alt="Sample" />
        </Box>
      </Paper>

    </div>
  );
}

const styles = {
  "table_paleBlueRows": {
    "width": "350px",
    "height": "200px",
    "textAlign": "center",
    "borderCollapse": "collapse"
  },
  "table_paleBlueRows.td": {
    "border": "1px solid #FFFFFF"
  },
  "table_paleBlueRows.th": {
    "border": "1px solid #FFFFFF"
  },
  "table_paleBlueRows.tbody.td": {
    "fontSize": "13px"
  },
  "table_paleBlueRows.tr.nth.child.even": {
    "background": "#EEEEEE"
  },
  "table_paleBlueRows_thead": {
    "background": "#999999",
    "borderBottom": "5px solid #FFFFFF"
  },
  "table_paleBlueRows_thead_th": {
    "fontSize": "17px",
    "fontWeight": "bold",
    "color": "#FFFFFF",
    "textAlign": "center"
  },
  "table_paleBlueRows_tfoot": {
    "fontSize": "14px",
    "fontWeight": "bold",
    "color": "#333333",
    "background": "#888888",
    "borderTop": "2px solid #444444"
  },
  "table_paleBlueRows_tfoot_td": {
    "fontSize": "14px"
  }
}

export { TestFeature2Page };
