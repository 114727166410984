import { wmvConstants } from "../_constants";

const initialState = {
  view: [], // an array of objects of viewData, the views [0-4] are displayed as columns splitting the screen...
};

const VIEW_MAX = 4; // the max number of views (columns) we allow.

export function webMissionView(state = initialState, action) {
  let newView = [];

  switch (action.type) {
    case wmvConstants.ADD_VIEW:
      //newView = [...state.view];
      // if we have VIEW_MAX views, forget it.
      const viewLen = state.view.length;
      if (viewLen + 1 >= VIEW_MAX) {
        console.log(
          `wmvConstants.ADD_VIEW: denied: at VIEW_MAX (${VIEW_MAX}), request index: [${action.viewIndex}] `,
          action.viewData
        );
        return state;
      }
      // typechecks
      if (
        typeof action.viewIndex != "number" ||
        typeof action.viewData != "object"
      ) {
        console.log(
          `wmvConstants.ADD_VIEW: invalid type: request index: [${action.viewIndex}] `,
          action.viewData
        );
        return state;
      }
      // insert within bounds of the existing views? otherwise, tack on the end.
      if (action.viewIndex >= 0 && action.viewIndex < viewLen) {
        newView = [...state.view];
        newView.splice(action.viewIndex, 0, action.viewData); // splice inserts into an array at index, modifying og array, which is why we copied with spread operator
      } else {
        newView = [...state.view, action.viewData]; // simply spread and append  :)
      }
      // if we've reached this far, we're good to update the redux store
      return {
        ...state,
        view: newView,
      };
    case wmvConstants.SET_VIEW:
      newView = [...state.view];

      return {
        ...state,
        view: newView,
      };
    case wmvConstants.DEL_VIEW:
      return {
        ...initialState,
      };
    case wmvConstants.RESET_VIEW:
      return {};
    default:
      return state;
  }
}
